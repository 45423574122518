import React, { useState } from 'react'
import { Input, Button } from 'antd'
import { useDebouncedCallback } from 'use-debounce'
import searchIcon from 'static/img/ic-search.svg'
import closeIcon from 'static/img/ic-close.svg'
import checkIcon from 'static/img/ic-gradient-check.svg'
import ContentHeader from './ContentHeader'
import { useI18n } from 'contexts/AuthContext'
import { fetchPhysicianPromise, updateUserPromise } from 'apis/FitOnCareAPI'
import _ from 'lodash'
import { usePrimaryPhysician, useUpdateClient } from 'hooks/careHooks'
import { getHealthieUserId } from 'utils/authLocalStorage'
import { ScreenKey } from './util'
import { setBrazeProfile } from 'utils/brazeInit'
import { logAmplitudeEvent, setAmplitudeProfile } from 'utils/amplitudeInit'
import { isMobile } from 'utils/basic'

const PrimaryCarePhysician = (props) => {
  const { i18n } = useI18n()
  const { saveLocalPrimaryPhysician, primaryPhysician, createReferring } =
    usePrimaryPhysician()
  const [searchText, setSearchText] = useState('')
  const [physicianList, setPhysicianList] = useState([])
  const [resultCount, setResultCount] = useState(0)
  const [selectedPhysician, setSelectedPhysician] = useState(
    primaryPhysician || []
  )
  const { updateClient } = useUpdateClient()

  const fetchPhysicianList = useDebouncedCallback(async (text) => {
    if (text) {
      const list = await fetchPhysicianPromise(encodeURIComponent(text))
      //0 - NPI,
      //1 - name.first,
      //2 - name.last,
      //3 - name.full,
      //4 - provider_type,
      //5 - addr_practice.line1,
      //6 - addr_practice.line2,
      //7 - addr_practice.city,
      //8 - addr_practice.state,
      //9 - addr_practice.zip,
      //10 - addr_practice.country,
      //11 - addr_practice.full,
      //12 - addr_practice.phone,
      //13 - addr_practice.fax
      if (!_.isEmpty(list)) {
        setResultCount(list[0])
        setPhysicianList(list[3])
      }
    } else {
      clearSearchResults()
    }
  }, 500)

  const clearSearchResults = () => {
    setResultCount(0)
    setPhysicianList([])
  }

  const onBack = (e) => {
    if (!_.isEmpty(selectedPhysician)) {
      clearSearchResults()
      setSelectedPhysician({})
      setSearchText('')
    } else {
      props.onBack(e)
    }
  }

  const updateClientInfo = () => {
    //0 - NPI,
    //1 - name.first,
    //2 - name.last,
    //3 - name.full,
    //4 - provider_type,
    //5 - addr_practice.line1,
    //6 - addr_practice.line2,
    //7 - addr_practice.city,
    //8 - addr_practice.state,
    //9 - addr_practice.zip,
    //10 - addr_practice.country,
    //11 - addr_practice.full,
    //12 - addr_practice.phone,
    //13 - addr_practice.fax
    saveLocalPrimaryPhysician(selectedPhysician)

    const names = selectedPhysician[3].split(' ')
    const firstName = selectedPhysician[1]
      ? selectedPhysician[1]
      : names.length > 0
      ? names[0]
      : selectedPhysician[3]
    const lastName = selectedPhysician[2]
      ? selectedPhysician[2]
      : names.length > 1
      ? selectedPhysician[3].replace(firstName, '')
      : selectedPhysician[3]
    createReferring({
      variables: {
        npi: selectedPhysician[0],
        legalname: selectedPhysician[3],
        firstName: firstName,
        lastName: lastName,
        phoneNumber: selectedPhysician[12],
        faxNumber: selectedPhysician[13],
        location: {
          line1: selectedPhysician[5],
          line2: selectedPhysician[6],
          city: selectedPhysician[7],
          state: selectedPhysician[8],
          zip: selectedPhysician[9],
          country: selectedPhysician[10],
        },
        acceptsInsurance: true,
        referrals: [],
      },
      onSuccess: (id) => {
        updateClient({
          variables: {
            id: getHealthieUserId(),
            referrals: [
              {
                referring_physician_id: id,
              },
            ],
          },
          onSuccess: () => {
            props.setPCPDetail('PCP Found')
            props.setCurrentKey(ScreenKey.PHYSICIAN_CONFIRMATION)
          },
        })
      },
    })

    setBrazeProfile({
      pcpNpi: selectedPhysician[0],
      pcpName: selectedPhysician[3],
      pcpFax: selectedPhysician[13],
    })
    setAmplitudeProfile({
      pcpNpi: selectedPhysician[0],
    })
    logAmplitudeEvent('Screen View: Onboarding - Insurance - PCP Search', {
      NPI: selectedPhysician[0],
      Name: selectedPhysician[3],
      Address: selectedPhysician[11],
      Phone: selectedPhysician[12],
      Fax: selectedPhysician[13],
    })
  }

  return (
    <div className='info-sec'>
      <ContentHeader {...props} onBack={onBack} />
      <div
        className='info-content'
        style={isMobile() ? {} : { height: '750px' }}
      >
        <div className='title' style={isMobile() ? {} : { marginTop: '72px' }}>
          {i18n('Who is your primary care physician?')}
        </div>
        <div className='subtitle' style={{ marginBottom: '32px' }}>
          {i18n('You can choose a specific physician for your first session')}
        </div>
        <div className='search-sec'>
          <Input
            prefix={<img alt='' src={searchIcon} />}
            rootClassName='search-input'
            placeholder={
              isMobile()
                ? i18n('Looking for physician name')
                : i18n('Looking for physician or clinical name')
            }
            suffix={
              searchText ? (
                <img
                  alt=''
                  src={closeIcon}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    clearSearchResults()
                    setSelectedPhysician({})
                    setSearchText('')
                  }}
                />
              ) : null
            }
            onChange={(e) => {
              setSearchText(e.target.value)
              fetchPhysicianList(e.target.value)
            }}
            value={searchText}
            bordered={false}
          />
          <div className='physician-results' hidden={_.isEmpty(physicianList)}>
            <div className='physician-results-count'>
              {i18n(`About ${resultCount} results`)}
            </div>
            <div className='physician-results-list'>
              {(physicianList || []).map((physician, index) => (
                <div
                  className='physician-result-item'
                  key={index}
                  onClick={() => {
                    setSearchText(physician[3])
                    setSelectedPhysician(physician)
                    clearSearchResults()
                  }}
                >
                  <div className='physician-result-card-name'>
                    {physician[3]}
                  </div>
                  <div className='physician-result-card-info'>
                    {physician[4]}
                  </div>
                  <div className='physician-result-card-info'>
                    {physician[11]}
                  </div>
                </div>
              ))}
            </div>
            <div
              className='gradient-font-1 physician-not-here'
              onClick={() => props.setCurrentKey(ScreenKey.PHYSICIAN_DETAIL)}
            >
              {i18n('My physician is not listed here')}
            </div>
          </div>
        </div>
        {!_.isEmpty(selectedPhysician) && (
          <div className='physician-result-sec'>
            <div className='physician-result-card'>
              <div className='physician-result-card-name'>
                {selectedPhysician[3]}
              </div>
              <div className='physician-result-card-info'>
                {selectedPhysician[4]}
              </div>
              <div className='physician-result-card-info'>
                {selectedPhysician[11]}
              </div>
              <img alt='' src={checkIcon} className='check-icon' />
            </div>
            <Button
              className='btn-primary full-width-btn m-t-30'
              onClick={() => {
                updateClientInfo()
              }}
            >
              {i18n('Next')}
            </Button>
          </div>
        )}
        <div
          className='do-not-have-one'
          onClick={() => {
            props.setUserStatus(7)
            props.setCurrentKey(ScreenKey.INSURANCE_NOT_ACCEPTED)
          }}
        >
          {i18n("I don't currently have one")}
        </div>
      </div>
    </div>
  )
}

export default PrimaryCarePhysician
