import React, { useEffect, useState } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import {
  useStripe,
  useElements,
  Elements,
  CardElement,
} from '@stripe/react-stripe-js'
import { Button } from 'antd'
import { __striprKey } from 'config'
import ContentHeader from './ContentHeader'
import { useI18n } from 'contexts/AuthContext'
import { useCardPay } from 'hooks/careHooks'
import { ScreenKey } from './util'
import { logAmplitudeEvent } from 'utils/amplitudeInit'
import { setBrazeUserAttribute } from 'utils/brazeInit'

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// const stripePromise = loadStripe(__striprKey, { apiVersion: '2020-08-27' })

const Payment = (props) => {
  const { i18n } = useI18n()
  const [loading, setLoading] = useState(false)

  const stripe = useStripe()
  const elements = useElements()

  const {
    cardStyle,
    handleCardChange,
    handleCardSubmitWithoutProduct,
    isComplete,
  } = useCardPay({
    setLoading,
    stripe,
    elements,
    product: {},
    onPurchaseFailure: () => {},
    onPurchaseSuccess: () => {
      logAmplitudeEvent('Payment: Add Card - Success', {
        Type: 'Insurance',
      })
      props.setCurrentKey(ScreenKey.SCHEDULED_TIME)
    },
  })

  useEffect(() => {
    logAmplitudeEvent('Screen View: Onboarding - Payment - Add Card', {
      Type: 'Insurance',
    })
  }, [])

  return (
    <>
      <div className='card-info-sec'>
        <CardElement
          id='card-element'
          options={cardStyle}
          onChange={handleCardChange}
          onReady={(e) => {}}
        />
      </div>
      <Button
        className='btn-primary full-width-btn font-dark m-t-45'
        style={{ width: '100%' }}
        onClick={(e) => {
          handleCardSubmitWithoutProduct(e)
          setBrazeUserAttribute('Credit Card', true)
        }}
        disabled={!isComplete || loading}
      >
        {i18n('Save')}
      </Button>
    </>
  )
}

const CardOnFile = (props) => {
  const { i18n } = useI18n()

  return (
    <div className='info-sec'>
      <ContentHeader {...props} />
      <div className='info-content'>
        <div className='title'>
          {i18n('A credit card is required to hold your session.')}
        </div>
        <div className='subtitle' style={{ marginBottom: '28px' }}>
          {i18n(
            `Your card won't be charged until you select & confirm your appointment.`
          )}
        </div>
        <Elements
          stripe={loadStripe(__striprKey, { apiVersion: '2020-08-27' })}
        >
          <Payment {...props} />
        </Elements>
      </div>
    </div>
  )
}

export default CardOnFile
