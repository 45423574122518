import React from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

const ButtonMailTo = ({ email, label, className }) => {
  return (
    <Link
      to='#'
      onClick={(e) => {
        window.location.href = `mailto:${email}`
        e.preventDefault()
      }}
      className={classnames('hover-link', className)}
    >
      {label}
    </Link>
  )
}

export default ButtonMailTo
