import React, { useEffect, useRef } from 'react'
import { Input } from 'antd'
import classnames from 'classnames'
import classes from './index.module.scss'
const { style } = classes

const CareInput = (props) => {
  const {
    inputValue,
    setInputValue,
    placeholder,
    prefix,
    isValid = true,
    errorMessage,
    className,
    focus,
    onEnter,
    type,
    defaultValue,
    onFocus,
    onBlur,
    children,
    disabled,
    maxLength,
    pattern,
  } = props
  const inputRef = useRef()

  useEffect(() => {
    if (focus) {
      inputRef.current.focus()
    }
  }, [focus])
  return (
    <div className={classnames(style, className)}>
      <Input
        rootClassName={classnames('personal-input')}
        value={inputValue}
        onChange={(e) => {
          setInputValue(e.target.value)
        }}
        placeholder={placeholder}
        prefix={prefix}
        status={isValid ? '' : 'error'}
        type={type || 'text'}
        controls={false}
        ref={(input) => {
          inputRef.current = input
        }}
        defaultValue={defaultValue}
        onKeyDown={(e) => {
          if (onEnter && e.keyCode === 13) {
            onEnter?.()
          }
        }}
        onFocus={onFocus}
        onBlur={onBlur}
        maxLength={maxLength}
        disabled={disabled}
        pattern={pattern}
      />
      {!isValid && <div className='error-message'>{errorMessage}</div>}
      {children}
    </div>
  )
}

export default CareInput
