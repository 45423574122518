import React, { useState } from 'react'
import ContentHeader from './ContentHeader'
import { useI18n } from 'contexts/AuthContext'
import { Button } from 'antd'
import moment from 'moment-timezone'
import { ScreenKey } from './util'
import { useCareProfile } from 'hooks/careHooks'
import { ThreeOptionSelect } from 'components/SwitchSelect/index'
import HeightInputNumber from 'components/HeightInput/index'
import CareInput from 'components/CareInput/index'
import { logAmplitudeEvent, setAmplitudeProfile } from 'utils/amplitudeInit'
import { getBMI, isMobile } from 'utils/basic'
import { setBrazeProfile } from 'utils/brazeInit'

export const GenderOptions = [
  { icon: 'female', title: 'Female', value: 'Female' },
  { icon: 'male', title: 'Male', value: 'Male' },
  { icon: 'other', title: 'Other', value: 'Other' },
]

const CompleteProfile = (props) => {
  const { i18n } = useI18n()

  const {
    birthday,
    setBirthday,
    gender,
    setGender,
    heightFt,
    setHeightFt,
    heightIn,
    setHeightIn,
    weightLbs,
    setWeightLbs,
    isNextButtonEnable,
    uploadProfile,
  } = useCareProfile()

  const [birthdayOnFocus, setBirthdayOnFocus] = useState()

  return (
    <div className='info-sec'>
      <ContentHeader {...props} />
      <div className='info-content'>
        <div className='title'>
          {i18n('Please complete your health profile')}
        </div>
        <div
          className='profile-item'
          style={{
            marginTop: '32px',
            height: 'auto',
            minHeight: '56px',
            alignItems: 'flex-start',
          }}
        >
          <div
            className='item-left'
            style={{
              height: '56px',
              lineHeight: '56px',
            }}
          >
            {i18n('Date of birth')}
          </div>
          <CareInput
            {...{
              inputValue: birthday,
              setInputValue: (value) => {
                let inputValue = value.replace(/[^0-9]/g, '')
                const isDelete = (birthday || '').length > value.length
                if (inputValue.length === 2) {
                  if (isDelete) {
                    inputValue = `${inputValue.slice(0, 2)}`
                  } else {
                    inputValue = `${inputValue.slice(0, 2)}/`
                  }
                } else if (inputValue.length > 2 && inputValue.length < 4) {
                  inputValue = `${inputValue.slice(0, 2)}/${inputValue.slice(
                    2,
                    4
                  )}`
                } else if (inputValue.length === 4) {
                  if (isDelete) {
                    inputValue = `${inputValue.slice(0, 2)}/${inputValue.slice(
                      2,
                      4
                    )}`
                  } else {
                    inputValue = `${inputValue.slice(0, 2)}/${inputValue.slice(
                      2,
                      4
                    )}/`
                  }
                } else if (inputValue.length > 4) {
                  inputValue = `${inputValue.slice(0, 2)}/${inputValue.slice(
                    2,
                    4
                  )}/${inputValue.slice(4, 8)}`
                }
                setBirthday(inputValue)
              },
              placeholder: 'mm/dd/yyyy',
              focus: true,
              className: 'item-right',
              isValid:
                (birthday && moment(birthday, 'MM/DD/YYYY', true).isValid()) ||
                birthdayOnFocus,
              errorMessage: i18n('Format: MM/DD/YYYY'),
              type: 'text',
              pattern: '\\d*',
              onFocus: () => {
                setBirthdayOnFocus(true)
              },
              onBlur: () => {
                setBirthdayOnFocus(false)
              },
            }}
          />
        </div>
        <div className='profile-item'>
          <div className='item-left'>{i18n('Gender')}</div>
          <ThreeOptionSelect
            className='item-right'
            options={GenderOptions}
            value={gender}
            onChange={(value) => {
              setGender(value)
            }}
          />
        </div>
        <div className='profile-item'>
          <div className='item-left'>{i18n('Height')}</div>
          <div className='height-sec'>
            <HeightInputNumber
              value={heightFt}
              setValue={setHeightFt}
              placeholder='0'
              unit={'ft'}
              max={7}
              min={4}
            />
            <HeightInputNumber
              value={heightIn}
              setValue={setHeightIn}
              placeholder='0'
              unit={'in'}
              max={11}
              min={0}
            />
          </div>
        </div>
        <div className='profile-item'>
          <div className='item-left'>{i18n('Weight')}</div>
          <div className='height-sec'>
            <HeightInputNumber
              value={weightLbs}
              setValue={setWeightLbs}
              placeholder='0'
              unit={'lbs'}
              max={500}
              min={80}
            />
          </div>
        </div>
        <Button
          className='btn-primary full-width-btn m-t-24'
          style={isMobile() ? { width: '100%' } : {}}
          onClick={() => {
            uploadProfile(() => {
              props.setCurrentKey(ScreenKey.HEAR_ABOUT)
            })
            const bmi = getBMI(
              Number(weightLbs),
              Number(heightFt) * 12 + Number(heightIn)
            )
            const height = Number(heightFt) * 12 + Number(heightIn)
            const _birthday = moment(birthday, 'MM/D/YYYY').format('YYYY-MM-DD')
            const age = moment().diff(birthday, 'years')

            logAmplitudeEvent('Screen View: Onboarding - Health Profile', {
              Age: age,
              Gender: gender.substring(0, 1),
              BMI: bmi,
            })
            setAmplitudeProfile({
              age: age,
              birthday: _birthday,
              gender,
              bmi,
              height,
              weight: weightLbs,
            })
            setBrazeProfile({
              dob: _birthday,
              gender: gender.substring(0, 1),
              bmi,
              height,
              weight: weightLbs,
            })
          }}
          disabled={!isNextButtonEnable}
        >
          {i18n('Next')}
        </Button>
      </div>
    </div>
  )
}

export default CompleteProfile
