import React, { useEffect } from 'react'
import ContentHeader from './ContentHeader'
import {
  useI18n,
  usePersonalInfo,
  useTalkAboutCoverage,
} from 'contexts/AuthContext'
import { Button } from 'antd'
import speakerIcon from './img/ic-speaker.svg'
import { logAmplitudeEvent } from 'utils/amplitudeInit'
import { updateUserPromise } from 'apis/FitOnCareAPI'

const InsuranceNotAcceptedNoCashPay = (props) => {
  const { i18n } = useI18n()
  const { userStatus } = props
  const { talkAboutCoverage } = useTalkAboutCoverage()
  const { personalInfo } = usePersonalInfo()

  useEffect(() => {
    updateUserPromise({ activate: 1 })
    props.setRemoveEventListener(true)
  }, [])

  useEffect(() => {
    const { state } = personalInfo
    logAmplitudeEvent('Screen View: Onboarding - Not Available', {
      Reason: 'Insurance - Cash Pay Declined',
      Detail: `${talkAboutCoverage.name}${
        talkAboutCoverage.name === 'Other'
          ? ' - ' + talkAboutCoverage.otherText
          : ''
      }${userStatus === 7 ? ' - No PCP' : ''}`,
      State: state?.name,
    })
    if (userStatus) {
      updateUserPromise({ userStatus })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [talkAboutCoverage, personalInfo])

  const onNext = () => {
    window.location.href = 'https://www.fitoncare.com'
  }

  const onBack = (e) => {
    updateUserPromise({ activate: 0, userStatus: 1 })
    props.setRemoveEventListener(false)
    props.onBack(e)
  }

  return (
    <div className='info-sec'>
      <ContentHeader {...props} onBack={onBack} />
      <div className='info-content'>
        <img src={speakerIcon} alt='' className='top-icon' />
        <div className='title'>{i18n('No worries!')}</div>
        <div
          className='subtitle'
          style={{ marginBottom: '10px', marginTop: '17px' }}
        >
          {i18n(
            'We will keep your information on file and email you as soon as we start taking your insurance carrier.'
          )}
        </div>
        <Button className='btn-primary full-width-btn' onClick={onNext}>
          {i18n('Great, Thanks!')}
        </Button>
      </div>
    </div>
  )
}

export default InsuranceNotAcceptedNoCashPay
