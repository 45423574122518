import { useState, useEffect } from 'react'
import UAParser from 'ua-parser-js'

const UA = new UAParser().getResult()
const isMobile = UA.device.type === 'mobile'

const mobileEnableList = [
  'login',
  'signup',
  'forget',
  'splash',
  'onboarding',
  'congratulations',
  '',
  'page',
  'activate',
  'join1',
  'join2',
  'join3',
  'join4',
  'join6',
  'unsubscribe',
  'emailpreferences',
]
export const formatPathName = (pathname) => pathname.split('/')[1].toLowerCase()

const fullPageList = [
  'login',
  'signup',
  'forget',
  'splash',
  'onboarding',
  'congratulations',
  'subscription',
  'inprogress',
  'waitingroom',
  'rating',
  'workoutreview',
  'page',
  'magiclink',
  'dailyfix',
  'video',
  'friends',
  'partypreview',
  'activate',
  'join1',
  'join2',
  'join3',
  'join4',
  'join6',
  'unsubscribe',
  'emailpreferences',
]
const noHeaderUrls = ['/meal/getstarted', '/meal/onboarding']

const fullWidthList = ['inprogress']

const noHeaderList = [...fullPageList]

const noFooterList = [
  ...fullPageList,
  // 'plan'
]

export function useMobileEnablePath(pathname) {
  const [mobileEnable, setMobileEnable] = useState(true)

  useEffect(() => {
    flow()
  }, [pathname])

  const flow = () => {
    const _path = formatPathName(pathname)
    setMobileEnable(!(!mobileEnableList.includes(_path) && isMobile))
  }
  return mobileEnable
}

export default function usePagePathType(pathname) {
  const [pathType, setPathType] = useState({ header: true, footer: true })

  useEffect(() => {
    flow()
  }, [pathname])

  const flow = () => {
    const _path = formatPathName(pathname)

    setPathType({
      header:
        noHeaderUrls.indexOf(pathname) > -1 || noHeaderList.indexOf(_path) > -1
          ? false
          : true,
      footer: noFooterList.indexOf(_path) > -1 ? false : true,
      fullWidth: fullWidthList.indexOf(_path) > -1 ? false : true,
    })
  }
  return pathType
}

export const allowFitOnLanguage = () => {
  return true
}
