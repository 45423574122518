import React, { useEffect, useState } from 'react'
import { Checkbox } from 'antd'
import classnames from 'classnames'
import _ from 'lodash'
import classes from './index.module.scss'
import CareInput from 'components/CareInput/index'
const { style } = classes

const CareSelectButton = (props) => {
  const {
    selected = false,
    setSelected,
    text,
    showInput,
    inputText,
    setInputText,
    placeholder,
    onSubmit,
    className,
    maxLength,
  } = props

  const [activeIcon, setActiveIcon] = useState(false)

  useEffect(() => {
    if (!selected) {
      setActiveIcon(false)
    }
  }, [selected])

  return (
    <div
      className={classnames(style, className)}
      key={text}
      onClick={() => setSelected(text)}
    >
      {showInput ? (
        <CareInput
          {...{
            inputValue: inputText,
            setInputValue: (value) => {
              setInputText(value)
            },
            placeholder: placeholder,
            focus: true,
            maxLength: maxLength,
            className: 'input-sec',
            onEnter: () => {
              setActiveIcon(true)
              onSubmit?.()
            },
          }}
        />
      ) : (
        <button className={classnames('select-button', { active: selected })}>
          <div style={selected ? { paddingRight: '30px' } : {}}>{text}</div>
        </button>
      )}

      {selected && (
        <Checkbox
          className={classnames(
            'check-icon',
            !showInput || activeIcon ? 'active-icon' : ''
          )}
          checked={selected}
          onClick={() => {
            if (!_.isEmpty(inputText)) {
              setActiveIcon(true)
              setSelected(true)
              onSubmit?.()
            }
          }}
        />
      )}
    </div>
  )
}

export default CareSelectButton
