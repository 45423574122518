import moment from 'moment-timezone'

const localStorageKey = 'fiton:local:timer'
const localDateStorageKey = 'ftion:local:date'
const initialState = {
  date: moment(new Date()).format('YYYY-MM-DD'),
  count: 0,
}

export function getTimer() {
  return localStorage.getItem(localStorageKey)
}
export function setTimer(timer) {
  return localStorage.setItem(localStorageKey, timer)
}

export function getDateStorage() {
  let output = localStorage.getItem(localDateStorageKey)
  try {
    output = JSON.parse(output || {})
  } catch (e) {
    output = initialState
  }
  return output
}

export function setDateStorage(date, count) {
  return localStorage.setItem(
    localDateStorageKey,
    JSON.stringify({ date: date, count: count })
  )
}
