import React from 'react'
import { Select } from 'antd'
import classnames from 'classnames'
import downIcon from 'static/img/ic-arrow-down.svg'
import classes from './index.module.scss'
const { style, dropdownStyle } = classes
const { Option } = Select

const DropdownSelect = (props) => {
  const {
    placeholder,
    options,
    value,
    setValue,
    className,
    render,
    isValid = true,
    errorMessage,
  } = props
  return (
    <div className={style}>
      <Select
        placeholder={placeholder}
        rootClassName={classnames('personal-input', className)}
        suffixIcon={<img alt='' src={downIcon} className='m-r-5' />}
        popupClassName={dropdownStyle}
        value={value}
        status={isValid ? '' : 'error'}
        onChange={(value) => {
          setValue(value)
        }}
      >
        {render ? (
          render
        ) : (
          <>
            {options.map((option, index) => (
              <Option className='state-item' value={option.value} key={index}>
                {option.label}
              </Option>
            ))}
          </>
        )}
      </Select>
      {!isValid && <div className='error-message'>{errorMessage}</div>}
    </div>
  )
}

export const DropdownSelectWithOpen = (props) => {
  const {
    placeholder,
    options,
    value,
    setValue,
    className,
    isValid = true,
    errorMessage,
    open,
    onDropdownVisibleChange,
    dropdownRender,
  } = props
  return (
    <div className={style}>
      <Select
        placeholder={placeholder}
        rootClassName={classnames('personal-input', className)}
        suffixIcon={<img alt='' src={downIcon} className='m-r-5' />}
        popupClassName={dropdownStyle}
        value={value}
        status={isValid ? '' : 'error'}
        onSelect={(value) => {
          setValue(value)
        }}
        options={options}
        open={open}
        dropdownRender={dropdownRender}
        onDropdownVisibleChange={onDropdownVisibleChange}
      />
      {!isValid && <div className='error-message'>{errorMessage}</div>}
    </div>
  )
}

export default DropdownSelect
