import React, { useState, useEffect, useCallback } from 'react'

import queryString from 'query-string'
import {
  getSessionGeoCountry,
  setSessionGeoCountry,
  setLocalLanguage,
  getLocalLanguage,
} from 'utils/authLocalStorage'

import { languageCountryMapping } from './languageCountryMapping'
import { allowFitOnLanguage } from 'hooks/usePagePathType'

const langMap = ['en_US', 'de_DE', 'es_ES', 'fr_FR', 'pt_BR']
const lang2Map = {
  en: 'en_US',
  de: 'de_DE',
  es: 'es_ES',
  fr: 'fr_FR',
  pt: 'pt_BR',
}
// const defaultLang = window.isDev ? 'fr_FR' : 'en_US'
const defaultLang = 'en_US'

console.log('window.isDev ', window.isDev)

const validLang = (lang) => {
  return !!langMap?.find((item) => item.toLowerCase() === lang?.toLowerCase())
}

export const getGeoInfo = async () => {
  const { language_code = defaultLang } = queryString.parse(
    window.location.search
  )
  const getGeoInfo = async () => {
    if (validLang(language_code)) {
      return Promise.resolve()
    } else {
      // return getIPInfoPromise()
      //   .then((data) => {
      //     return Promise.resolve(data.country?.toLowerCase())
      //   })
      //   .catch((error) => {
      return Promise.resolve()
      // })
    }
  }

  const country = await getGeoInfo()
  const mappedShortLang =
    languageCountryMapping[country?.toUpperCase()]?.languages?.[0]
  const mappedLang = lang2Map[mappedShortLang]
  let lang
  if (validLang(language_code)) {
    lang = language_code
  } else if (mappedLang) {
    lang = mappedLang
  } else {
    lang = defaultLang
  }
  const localCode = lang?.split('_')[0]
  setSessionGeoCountry(localCode)
  setLocalLanguage(lang)
  return {
    lang,
    localCode,
  }
}

const useLokalise = () => {
  const [i18nMap, setI18nMap] = useState({})
  const [i18nMapInit, setI18nMapInit] = useState(false)

  const lang = getLocalLanguage()
  const localCode = getSessionGeoCountry()

  useEffect(() => {
    if (lang === 'en_US') return setI18nMapInit(true)
    setI18nMapInit(false)

    fetch(
      `https://s3.us-east-2.amazonaws.com/lockalise.web/locale/${lang}.json`
    )
      .then((response) => response.json())
      .then((responseJson) => {
        setI18nMap(responseJson ?? {})
        setI18nMapInit(true)
      })
      .catch((error) => {
        console.error(error)
        setI18nMapInit(true)
      })
  }, [lang])

  const lokalise = useCallback(
    (baseString, ...args) => {
      let map = i18nMap
      if (!allowFitOnLanguage()) map = undefined
      let output = map?.[baseString] ?? baseString
      const _args = args
      if (_args?.length) {
        const _list = output.split(/({\d})/g)
        output = (
          <>
            {_list.map((item, index) => {
              if (item.match(/{\d}/))
                return (
                  <React.Fragment key={index}>{_args.shift()}</React.Fragment>
                )
              return item
            })}
          </>
        )
      }
      return output
    },
    [i18nMap]
  )
  return {
    lokalise,
    localCode: allowFitOnLanguage() ? localCode : 'en',
    localLang: allowFitOnLanguage() ? lang : 'en_US',
    langLoading: !i18nMapInit, // || countryPending,
  }
}

export default useLokalise
