import React, { useEffect, useMemo, useRef, useState } from 'react'
import ContentHeader from './ContentHeader'
import { useI18n, usePersonalInfo } from 'contexts/AuthContext'
import { Button } from 'antd'
import moment from 'moment-timezone'
import CareInput from 'components/CareInput/index'
import { useCareInsurancePay } from 'hooks/careHooks'
import { ScreenKey } from './util'
import { logAmplitudeEvent } from 'utils/amplitudeInit'
import Address from './Address'
import { ThreeOptionSelect } from 'components/SwitchSelect/index'
import { GenderOptions } from './CompleteProfile'
import { isMobile } from 'utils/basic'
import { setBrazeUserAttribute } from 'utils/brazeInit'
import _ from 'lodash'

const InsuranceHomeAddress = (props) => {
  const { i18n } = useI18n()

  const {
    coverage,
    stateOptions,
    addressInfo,
    policyHolderInfo,
    setPolicyHolderInfo,
    setAddressInfo,
    isAddressNextButtonEnable,
    updateClientInfo,
    relationship,
  } = useCareInsurancePay()
  const { personalInfo } = usePersonalInfo()

  const [policyHolderFirstName, setPolicyHolderFirstName] = useState(
    policyHolderInfo.firstName
  )
  const [policyHolderLastName, setPolicyHolderLastName] = useState(
    policyHolderInfo.lastName
  )
  const [policyHolderDOB, setPolicyHolderDOB] = useState(policyHolderInfo.dob)
  const [birthdayOnFocus, setBirthdayOnFocus] = useState()
  const [gender, setGender] = useState(policyHolderInfo.gender || 'Female')
  const addressRef = useRef()
  const [isShowManualAddress, setIsShowManualAddress] = useState(false)

  useEffect(() => {
    setPolicyHolderInfo({
      firstName: policyHolderFirstName,
      lastName: policyHolderLastName,
      dob: policyHolderDOB,
      gender: gender,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policyHolderFirstName, policyHolderLastName, policyHolderDOB, gender])

  const isSelf = relationship === 'Self'
  const isValidAddressInfo = useMemo(() => {
    return (
      !_.isEmpty(addressInfo.city) &&
      !_.isEmpty(addressInfo.state) &&
      !_.isEmpty(addressInfo.postcode) &&
      !_.isEmpty(addressInfo.line1)
    )
  }, [addressInfo])

  return (
    <div className='info-sec'>
      <ContentHeader {...props} />
      <div className='info-content'>
        <div className='title'>
          {i18n(
            isSelf
              ? 'What is your home address?'
              : 'Please fill in the policy holder information'
          )}
        </div>
        {!isSelf && (
          <>
            <CareInput
              placeholder={i18n('Policy Holder First Name')}
              inputValue={policyHolderFirstName}
              setInputValue={setPolicyHolderFirstName}
              className='m-t-12'
              type='text'
            />
            <CareInput
              placeholder={i18n('Policy Holder Last Name')}
              inputValue={policyHolderLastName}
              setInputValue={setPolicyHolderLastName}
              className='m-t-12'
              type='text'
            />
            <CareInput
              {...{
                inputValue: policyHolderDOB,
                setInputValue: (value) => {
                  let inputValue = value.replace(/[^0-9]/g, '')
                  const isDelete = (policyHolderDOB || '').length > value.length
                  if (inputValue.length === 2) {
                    if (isDelete) {
                      inputValue = `${inputValue.slice(0, 2)}`
                    } else {
                      inputValue = `${inputValue.slice(0, 2)}/`
                    }
                  } else if (inputValue.length > 2 && inputValue.length < 4) {
                    inputValue = `${inputValue.slice(0, 2)}/${inputValue.slice(
                      2,
                      4
                    )}`
                  } else if (inputValue.length === 4) {
                    if (isDelete) {
                      inputValue = `${inputValue.slice(
                        0,
                        2
                      )}/${inputValue.slice(2, 4)}`
                    } else {
                      inputValue = `${inputValue.slice(
                        0,
                        2
                      )}/${inputValue.slice(2, 4)}/`
                    }
                  } else if (inputValue.length > 4) {
                    inputValue = `${inputValue.slice(0, 2)}/${inputValue.slice(
                      2,
                      4
                    )}/${inputValue.slice(4, 8)}`
                  }
                  setPolicyHolderDOB(inputValue)
                },
                placeholder: 'Policy Holder Date of Birth (MM/DD/YYYY)',
                focus: false,
                className: 'm-t-12',
                type: 'text',
                pattern: '\\d*',
                isValid:
                  (policyHolderDOB &&
                    moment(policyHolderDOB, 'MM/DD/YYYY', true).isValid()) ||
                  birthdayOnFocus,
                errorMessage: i18n('Format: MM/DD/YYYY'),
                onFocus: () => {
                  setBirthdayOnFocus(true)
                },
                onBlur: () => {
                  setBirthdayOnFocus(false)
                },
              }}
            />
            <div className='profile-item m-t-12 m-b-0'>
              <div className='item-left'>{i18n('Gender')}</div>
              <ThreeOptionSelect
                className='item-right'
                options={GenderOptions}
                value={gender}
                onChange={(value) => {
                  setGender(value)
                }}
              />
            </div>
          </>
        )}
        <Address
          address={addressInfo}
          setAddress={setAddressInfo}
          ref={addressRef}
          {...{
            isSelf,
            stateOptions,
            setIsShowManualAddress,
          }}
        />
        <Button
          className='btn-primary full-width-btn'
          style={isMobile() ? { width: '100%' } : {}}
          onClick={() => {
            if (!isValidAddressInfo) {
              if (isShowManualAddress) {
                addressRef.current.showErrorInput()
              } else {
                addressRef.current.showManualAddress()
              }
            } else {
              logAmplitudeEvent(
                'Screen View: Onboarding - Insurance - Policy Holder',
                {
                  State: addressInfo.state.name,
                  'State Match':
                    addressInfo.state?.code === personalInfo.state?.code,
                }
              )
              updateClientInfo({
                onSuccess: () => {
                  if (coverage === 'PPO') {
                    setBrazeUserAttribute('Credit Card', false)
                  }
                  props.setCurrentKey(
                    coverage === 'PPO'
                      ? ScreenKey.SCHEDULED_TIME
                      : ScreenKey.SCHEDULED_TIME
                  )
                },
              })
            }
          }}
          disabled={!isAddressNextButtonEnable}
        >
          {i18n('Next')}
        </Button>
      </div>
      <div id='google-map'></div>
    </div>
  )
}

export default InsuranceHomeAddress
