import React from 'react'
import logoIcon from 'static/img/fiton-care-logo.png'
import welcomeImg from './img/care-welcome.png'
import { useI18n } from 'contexts/AuthContext'
import { Button } from 'antd'
import { ScreenKey } from './util'

const Welcome = (props) => {
  const { i18n } = useI18n()

  return (
    <div className='welcome-sec'>
      <img src={logoIcon} alt='' className='logo-icon' />
      <img src={welcomeImg} alt='' className='welcome-img' />
      <div className='title' style={{ marginTop: '39px' }}>
        {i18n('Welcome to FitOn Care!')}
      </div>
      <div
        className='subtitle'
        style={{ marginTop: '16px', marginBottom: '48px' }}
      >
        {i18n(
          "We're here to help you reach your health & wellness goals by pairing you with an experienced Registered Dietician for personalized nutrition counseling."
        )}
      </div>
      <Button
        className='btn-primary large-btn'
        onClick={() => {
          props.setCurrentKey(ScreenKey.PERSONAL_INFO)
        }}
      >
        {i18n('get started')}
      </Button>
    </div>
  )
}

export default Welcome
