export const api_version = process.env.REACT_APP_VERSION

export const BRAZE_API_KEY =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_ENV === 'staging'
      ? '8335a7fc-4cae-4ab8-8fe7-bb74ab9423a2'
      : '8d286803-be80-478d-98bf-f1d5198b2f4b'
    : '8335a7fc-4cae-4ab8-8fe7-bb74ab9423a2'

export const __striprKey =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_ENV === 'staging'
      ? 'pk_test_51NbGmwAyvlUE4W6QgxNO41Lv2mX44oldAtxKgWbXGrYhVKrKKbuqRYaMQ6fwq6y4g5R3bvpYnitShcxZrBHoARXS00MX5hcgYG'
      : 'pk_live_51NbGmwAyvlUE4W6QgwIeQYXFIEobIG15vNmLBISqgObGOeMBOpl4Z0TLyBpOSe69wlkf1kQYAgTRbQ8hmGEtcE9J00xTVj2f7m'
    : 'pk_test_51NbGmwAyvlUE4W6QgxNO41Lv2mX44oldAtxKgWbXGrYhVKrKKbuqRYaMQ6fwq6y4g5R3bvpYnitShcxZrBHoARXS00MX5hcgYG'

export const AMPLITUDE_KEY =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_ENV === 'staging'
      ? '736add9c22f93ca429b925e1d2bfb080'
      : '7d041e865e9591e4ddfaf9f4122e8039'
    : '736add9c22f93ca429b925e1d2bfb080'

export const HEALTHIE_API_KEY =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_ENV === 'staging'
      ? 'gh_sbox_kCo19cm9DMs3P3I0vUiqi2eDOTKJfnvJ4G9unfy5YRyk0oHrlvD4bk8WK6qNRsBu'
      : ''
    : 'gh_sbox_kCo19cm9DMs3P3I0vUiqi2eDOTKJfnvJ4G9unfy5YRyk0oHrlvD4bk8WK6qNRsBu'

export const REACT_APP_GOOGLE_MAPS_API_KEY =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_ENV === 'staging'
      ? 'AIzaSyBqe0A8w0WwlYbcsLOu9v_ainK5-Mb6sPs'
      : 'AIzaSyBqe0A8w0WwlYbcsLOu9v_ainK5-Mb6sPs'
    : 'AIzaSyBqe0A8w0WwlYbcsLOu9v_ainK5-Mb6sPs'

export const MICROSOFT_PROMO_CODE = 'SURFACEPRO21'
