import React from 'react'
import classnames from 'classnames'
import './index.module.scss'

export const BorderGradient = ({
  className,
  children,
  showGradient = true,
  radius,
  ...props
}) => {
  return (
    <div
      className={classnames('btn-gradient', className)}
      {...props}
      style={{
        background: showGradient ? '' : '#fff',
        borderRadius: radius ? `${radius}px` : '',
      }}
    >
      <div className='button-inner'>{children}</div>
    </div>
  )
}
