import * as braze from '@braze/web-sdk'
import { getUserId } from 'utils/authLocalStorage'
import { BRAZE_API_KEY } from 'config'

export const brazeInit = () => {
  //braze
  braze.initialize(BRAZE_API_KEY, {
    // enableLogging: true,
    baseUrl: 'https://sdk.iad-03.braze.com/api/v3',
    minimumIntervalBetweenTriggerActionsInSeconds: 5,
  })
  braze.openSession()
  window.appboy = braze
  logBrazeEvent('Session Start')
  brazeLogin()
}

export const brazeLogin = () => {
  if (getUserId()) {
    braze.changeUser(String(getUserId()))
  } else {
  }
}

export const setBrazeUserAttribute = (name, value) => {
  braze.getUser().setCustomUserAttribute(name, value)
  brazeRequestImmediateDataFlush()
}

export const logBrazeEvent = (event, data) => {
  if (braze) {
    braze.logCustomEvent(event, data)
    brazeRequestImmediateDataFlush()
  }
}

export const addBrazeAlias = ({ label, name }) => {
  braze.getUser().addAlias(name, label)
}

export const brazeRequestImmediateDataFlush = () => {
  if (braze) {
    braze.requestImmediateDataFlush()
  }
}

export const setBrazeProfile = (options = {}) => {
  const id = braze.getUser().getUserId()
  const __userId = getUserId() ?? options.id ?? options.userId
  if (!id && __userId) {
    braze.changeUser(String(__userId))
  }
  const {
    firstName,
    lastName,
    email,
    phone,
    state,
    legalEntity,
    utmSource,
    utmCampaign,
    utmContent,
    conditionPrimary,
    dob,
    gender,
    bmi,
    height,
    weight,
    userAttribution,
    pcpNpi,
    pcpName,
    pcpFax,
    insurancePlan,
    insuranceCoverage,
    insuranceType,
    insuranceAddress,
    insuranceRelationship,
    insurancePolicyHolderName,
    healthieID,
  } = options
  if (email) {
    braze.getUser().setEmail(email)
  }
  if (firstName) {
    braze.getUser().setFirstName(firstName)
  }
  if (lastName) {
    braze.getUser().setLastName(lastName)
  }
  if (phone) {
    braze.getUser().setPhoneNumber(phone)
  }
  if (state) {
    braze.getUser().setCustomUserAttribute('User State', state)
  }
  if (legalEntity) {
    braze.getUser().setCustomUserAttribute('Legal Entity', legalEntity)
  }
  braze.getUser().setCustomUserAttribute('Legal Privacy', true)
  braze.getUser().setCustomUserAttribute('Legal Terms', true)
  braze.getUser().setCustomUserAttribute('Legal Telehealth Consent', true)
  if (utmSource) {
    braze.getUser().setCustomUserAttribute('utm_source', utmSource)
    braze.getUser().setCustomUserAttribute('Referral Source', utmSource)
  }
  if (utmCampaign) {
    braze.getUser().setCustomUserAttribute('utm_campaign', utmCampaign)
  }
  if (utmContent) {
    braze.getUser().setCustomUserAttribute('utm_content', utmContent)
  }
  if (conditionPrimary) {
    braze
      .getUser()
      .setCustomUserAttribute('Condition Primary', conditionPrimary)
  }
  if (dob) {
    const b = new Date(dob)
    const year = b.getFullYear()
    const month = b.getMonth() + 1
    const date = b.getDate()
    braze.getUser().setDateOfBirth(year, month, date)
  }
  if (gender) {
    braze.getUser().setGender(gender)
  }
  if (bmi) {
    braze.getUser().setCustomUserAttribute('User BMI', bmi)
  }
  if (height) {
    braze.getUser().setCustomUserAttribute('User Height', height)
  }
  if (weight) {
    braze.getUser().setCustomUserAttribute('User Weight', weight)
  }
  if (userAttribution) {
    braze.getUser().setCustomUserAttribute('User Attribution', userAttribution)
  }
  if (pcpNpi) {
    braze.getUser().setCustomUserAttribute('PCP NPI', pcpNpi)
  }
  if (pcpName) {
    braze.getUser().setCustomUserAttribute('PCP Name', pcpName)
  }
  if (pcpFax) {
    braze.getUser().setCustomUserAttribute('PCP Fax', pcpFax)
  }
  if (insurancePlan) {
    braze.getUser().setCustomUserAttribute('Insurance Plan', insurancePlan)
  }
  if (insuranceCoverage) {
    braze
      .getUser()
      .setCustomUserAttribute('Insurance Coverage', insuranceCoverage)
  }
  if (insuranceType) {
    braze.getUser().setCustomUserAttribute('Insurance Type', insuranceType)
  }
  if (insuranceAddress) {
    braze.getUser().setCustomUserAttribute('Insured Address', insuranceAddress)
  }
  if (insuranceRelationship) {
    braze
      .getUser()
      .setCustomUserAttribute('Insured Relationship', insuranceRelationship)
  }
  if (insurancePolicyHolderName) {
    braze
      .getUser()
      .setCustomUserAttribute(
        'Insured Policy Holder Name',
        insurancePolicyHolderName
      )
  }
  if (healthieID) {
    braze.getUser().setCustomUserAttribute('Healthie ID', healthieID)
  }
  brazeRequestImmediateDataFlush()
}
