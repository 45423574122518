import React, { useContext, useEffect } from 'react'
import { Button } from 'antd'
import { format } from 'date-fns'
import moment from 'moment-timezone'
import ContentHeader from './ContentHeader'
import { AuthContext, useI18n } from 'contexts/AuthContext'
import calendarIcon from './img/ic-calendar-event.svg'
import googleIcon from 'static/img/ic-google.svg'
import appleIcon from 'static/img/ic-apple.svg'
import { getDeviceType, toDate } from 'utils/basic'
import { clearUserInfo } from 'utils/authLocalStorage'
import { logAmplitudeEvent } from 'utils/amplitudeInit'
import { updateUserPromise } from 'apis/FitOnCareAPI'
import { getHealthieHost } from 'apis/formatUrl'
import { setBrazeUserAttribute } from 'utils/brazeInit'

const ConfirmedAppointment = (props) => {
  const { bookedAppointment, appointmentsCount } = props
  const { i18n } = useI18n()
  const { clearData } = useContext(AuthContext)

  useEffect(() => {
    const startDate = toDate(bookedAppointment.start)
    logAmplitudeEvent('Screen View: Onboarding - Schedule - Confirmation', {
      Practitioner: bookedAppointment.provider?.full_name,
      Date: format(startDate, `yyyy-MM-dd`),
      Time: format(startDate, `h:mm a`),
      'Day of Week': startDate.getDay() + 1,
    })
    logAmplitudeEvent('Signup Success')
    updateUserPromise({ userStatus: 2, activate: 1 })
    if (appointmentsCount === 0) {
      setBrazeUserAttribute(
        'First Appointment',
        moment(bookedAppointment.start).format()
      )
    }

    setTimeout(() => {
      clearUserInfo()
    }, 2000)
  }, [appointmentsCount, bookedAppointment])

  const appointmentDuration =
    (toDate(bookedAppointment.end) - toDate(bookedAppointment.start)) /
    1000 /
    60

  const deviceType = getDeviceType()
  const isIOS = deviceType === 'iOS'
  const isAndroid = deviceType === 'Android'

  return (
    <div className='info-sec'>
      <ContentHeader {...props} hideBack={true} />
      <div className='info-content'>
        <img
          src={calendarIcon}
          alt=''
          className='top-icon'
          style={{ marginTop: '63px' }}
        />
        <div className='title'>{i18n('Your appointment is confirmed!')}</div>
        <div
          className='subtitle'
          style={{ marginBottom: '10px', marginTop: '10px' }}
        >
          {i18n('A confirmation has also been sent to your email.')}
        </div>

        <div className='appointment-sec'>
          <div className='appointment-time'>
            {format(
              toDate(bookedAppointment.start),
              `EEE, MMM d, yyyy 'at' h:mm a`
            )}
          </div>
          <div className='appointment-session'>
            {i18n(
              `${
                bookedAppointment.appointment_type?.name ||
                'Introductory Session'
              } (${appointmentDuration} mins)`
            )}
          </div>
        </div>
        <Button
          className='add-calendar-btn full-width-btn'
          onClick={() => {
            const calExportId = btoa(
              `${bookedAppointment.id}:${bookedAppointment.appointment_type.id}:${bookedAppointment.patientEmail}`
            )
            window.open(
              `${getHealthieHost()}appointments/add_to_cal/${
                bookedAppointment.id
              }.ics?cal_type=ical&cal_export_id=${calExportId}`,
              '_blank'
            )
          }}
          hidden={isAndroid}
        >
          <img src={appleIcon} alt='' />
          {i18n('Add to your Apple calendar')}
        </Button>
        <Button
          className='add-calendar-btn full-width-btn m-t-16'
          onClick={() => {
            window.open(bookedAppointment.add_to_gcal_link, '_blank')
          }}
          hidden={isIOS}
        >
          <img src={googleIcon} alt='' />
          {i18n('Add to your Google calendar')}
        </Button>
        <button
          className='back-to-fiton-care'
          onClick={() => {
            clearUserInfo()
            clearData()
            window.location.href = 'https://fitoncare.com/'
          }}
        >
          {i18n('Back to FitOn Care')}
        </button>
      </div>
    </div>
  )
}

export default ConfirmedAppointment
