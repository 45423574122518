const localStorageKey = 'fiton:local:token'
const localStorageUserKey = 'fiton:local:userid'
const localStorageUserEmailKey = 'fiton:local:email'
const sessionStorageGeoCountry = 'fiton:local:geo:country'
const localStorageLanguageKey = 'fiton:local:language'
const localStorageHealthieKey = 'fiton:local:healthie:token'
const localStorageHealthieUserIdKey = 'fiton:local:healthie:userId'
const localStorageHealthieProviderIdKey = 'fiton:local:healthie:providerId'
const localStorageStateIdKey = 'fiton:local:healthie:stateId'
const localStorageFlowKey = 'fiton:local:healthie:flow'

export function getToken() {
  return localStorage.getItem(localStorageKey)
}
export function setToken(token) {
  return localStorage.setItem(localStorageKey, token)
}
export function getHealthieToken() {
  return localStorage.getItem(localStorageHealthieKey)
}
export function setHealthieToken(token) {
  return localStorage.setItem(localStorageHealthieKey, token)
}
export function getHealthieUserId() {
  return localStorage.getItem(localStorageHealthieUserIdKey)
}
export function setHealthieUserId(userId) {
  return localStorage.setItem(localStorageHealthieUserIdKey, userId)
}
export function getUserId() {
  return localStorage.getItem(localStorageUserKey)
}
export function setUserId(id) {
  return localStorage.setItem(localStorageUserKey, id)
}
export function getHealthieProviderId() {
  return localStorage.getItem(localStorageHealthieProviderIdKey)
}
export function setHealthieProviderId(id) {
  return localStorage.setItem(localStorageHealthieProviderIdKey, id)
}
export function getUserEmail() {
  return localStorage.getItem(localStorageUserEmailKey)
}
export function setUserEmail(email) {
  return localStorage.setItem(localStorageUserEmailKey, email)
}

export function getSessionGeoCountry() {
  return sessionStorage.getItem(sessionStorageGeoCountry) || 'en'
}

export function setSessionGeoCountry(value) {
  return sessionStorage.setItem(sessionStorageGeoCountry, value)
}

export function getLocalLanguage() {
  return localStorage.getItem(localStorageLanguageKey) || 'en_US'
}

export function setLocalLanguage(value) {
  return localStorage.setItem(localStorageLanguageKey, value)
}

export function getStateId() {
  return localStorage.getItem(localStorageStateIdKey)
}

export function setStateId(value) {
  return localStorage.setItem(localStorageStateIdKey, value)
}

export function getFlow() {
  let output = localStorage.getItem(localStorageFlowKey)
  try {
    output = JSON.parse(output)
  } catch (e) {
    output = []
  }
  return output
}

export function setFlow(list) {
  return localStorage.setItem(localStorageFlowKey, JSON.stringify(list))
}

export function clearUserInfo() {
  setToken('')
  setHealthieToken('')
  setUserId('')
  setUserEmail('')
  setHealthieUserId('')
  setHealthieProviderId('')
  setStateId('')
  setFlow('')
}
