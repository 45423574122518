import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useI18n, usePersonalInfo } from 'contexts/AuthContext'
import { Button, Input } from 'antd'
import classnames from 'classnames'
import { isMobile, showNotification } from 'utils/basic'
import ContentHeader from './ContentHeader'
import { getUserEmail } from 'utils/authLocalStorage'
import {
  emailMagicLinkPromise,
  verifyMagicLinkByDigitCodePromise,
} from 'apis/FitOnCareAPI'
import { logAmplitudeEvent } from 'utils/amplitudeInit'

const VerifyEmail = (props) => {
  const { i18n } = useI18n()
  const { personalInfo } = usePersonalInfo()
  const [code, setCode] = useState([])
  const [isSubmit, setIsSubmit] = useState(false)
  const [placeholder0, setPlaceholder0] = useState('-')
  const [placeholder1, setPlaceholder1] = useState('-')
  const [placeholder2, setPlaceholder2] = useState('-')
  const [placeholder3, setPlaceholder3] = useState('-')
  const placeholders = [placeholder0, placeholder1, placeholder2, placeholder3]
  const setPlaceholders = [
    setPlaceholder0,
    setPlaceholder1,
    setPlaceholder2,
    setPlaceholder3,
  ]
  const codeRef0 = useRef()
  const codeRef1 = useRef()
  const codeRef2 = useRef()
  const codeRef3 = useRef()
  const refs = [codeRef0, codeRef1, codeRef2, codeRef3]

  useEffect(() => {
    logAmplitudeEvent('Screen View: Magic Link Email Sent', {
      Source: 'Onboarding',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getCode = useMemo(() => {
    let codeString = ''
    code.forEach((item) => {
      codeString += item
    })
    return codeString
  }, [code])

  const onCodeChange = (index, value) => {
    const _code = [...code]
    _code[index] = value
    setCode(_code)
    if (value) {
      switch (index) {
        case 0:
          codeRef1.current.focus()
          break
        case 1:
          codeRef2.current.focus()
          break
        case 2:
          codeRef3.current.focus()
          break
        default:
          break
      }
    }
  }

  const submitCode = useCallback(
    async (e) => {
      e && e.preventDefault()
      const codeStr = getCode
      if (codeStr.length < 4) {
        return
      }
      setIsSubmit(true)
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 30)
      const response = await verifyMagicLinkByDigitCodePromise({
        digitCode: codeStr,
        email: personalInfo.email || getUserEmail(),
      })
      if (response && response.code === 200) {
        const user = response.data
        setCode([])
        setIsSubmit(false)
        props.verifyEmailSuccess(user)
      } else {
        setIsSubmit(false)
        showNotification({
          type: 'error',
          title: (response && response.msg) || i18n('Verify failed'),
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getCode, personalInfo.email]
  )

  const sendEmail = async (resend) => {
    const resp = await emailMagicLinkPromise({
      email: personalInfo.email || getUserEmail(),
    })
    if (resp.code !== 200)
      return showNotification({
        type: 'error',
        title: 'Error',
        message: resp.msg,
      })
    if (resend) {
      showNotification({
        type: 'success',
        title: i18n('Resend success'),
      })
    }
  }

  useEffect(() => {
    submitCode()
  }, [submitCode])

  return (
    <div className='info-sec'>
      <ContentHeader {...props} />
      <div className='info-content'>
        <div className='title'>{i18n('Check your email')}</div>
        <p
          className='subtitle-1'
          style={isMobile() ? { marginBottom: '32px' } : {}}
        >
          {i18n(
            'We sent an email to {0}. Please check your inbox and click on the link to validate',
            <span className='gradient-font-1'>
              {personalInfo.email || getUserEmail()}
            </span>
          )}
        </p>
        <p className='subtitle-1'>
          {i18n(
            `You can also enter the 4 digit code you've received in your email to validate your email.`
          )}
        </p>
        <form onSubmit={submitCode}>
          <div className='code-sec'>
            {new Array(4).fill(0).map((n, index) => (
              <Input
                key={index}
                className={classnames('code-value', { hasValue: code[index] })}
                maxLength={1}
                value={code[index]}
                ref={(input) => {
                  refs[index].current = input
                }}
                onChange={(e) => {
                  let value = e.target.value ? e.target.value.toString() : ''
                  if (value.length > 1) {
                    setCode(value?.slice(0, 4).split(''))
                  } else {
                    onCodeChange(index, value)
                  }
                }}
                placeholder={placeholders[index]}
                onFocus={() => {
                  setPlaceholders[index]('')
                }}
                onBlur={() => {
                  setPlaceholders[index]('-')
                }}
                type='number'
              />
            ))}
          </div>
          <Button
            className='btn-primary full-width-btn'
            style={isMobile() ? { width: '100%' } : {}}
            onClick={submitCode}
            disabled={getCode.length < 4 || isSubmit}
          >
            {i18n('Verify')}
          </Button>
        </form>
        <p className='resend-sec'>
          {i18n(`Didn't get your email?`)}{' '}
          <span onClick={() => sendEmail(true)}>{i18n(`Resend email`)}</span>
        </p>
      </div>
    </div>
  )
}

export default VerifyEmail
