import React, { useEffect } from 'react'
import ContentHeader from './ContentHeader'
import {
  useHormonalAutoimmune,
  useI18n,
  usePersonalInfo,
  useSurvey,
} from 'contexts/AuthContext'
import { Button } from 'antd'
import topIcon from './img/ic-limited-availability.svg'
import ButtonMailTo from 'components/ButtonMailTo/index'
import { useUpdateClient } from 'hooks/careHooks'
import { getHealthieUserId } from 'utils/authLocalStorage'
import { logAmplitudeEvent } from 'utils/amplitudeInit'
import { updateUserPromise } from 'apis/FitOnCareAPI'

const LimitedAvailability = (props) => {
  const { i18n } = useI18n()
  const { updateClient } = useUpdateClient()
  const { survey } = useSurvey()
  const { hormonalAutoimmune } = useHormonalAutoimmune()
  const { personalInfo } = usePersonalInfo()

  const getDetail = () => {
    if (survey.name === 'Other') {
      return `${survey.name} - ${survey.otherText}`
    } else if (hormonalAutoimmune.name) {
      if (hormonalAutoimmune.name === 'Other') {
        return `${survey.name} - ${hormonalAutoimmune.name} - ${hormonalAutoimmune.otherText}`
      } else {
        return `${survey.name} - ${hormonalAutoimmune.name}`
      }
    } else {
      return survey.name
    }
  }

  useEffect(() => {
    updateUserPromise({ activate: 1 })
  }, [])

  useEffect(() => {
    const { state } = personalInfo
    logAmplitudeEvent('Screen View: Onboarding - Not Available', {
      Reason: 'Condition',
      Detail: getDetail(),
      State: state?.name,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hormonalAutoimmune.name, survey.name, personalInfo])

  const onNext = () => {
    updateClient({
      variables: {
        id: getHealthieUserId(),
        active: false,
      },
      onSuccess: () => (window.location.href = 'https://www.fitoncare.com'),
    })
    // applyHealthieTags({
    //   variables: {
    //     ids: [state.legalEntityId],
    //     taggable_user_id: getHealthieUserId(),
    //   },
    // })
  }

  const onBack = (e) => {
    updateUserPromise({ activate: 0 })
    props.onBack(e)
  }

  return (
    <div className='info-sec'>
      <ContentHeader {...props} onBack={onBack} />
      <div className='info-content'>
        <img src={topIcon} alt='' className='top-icon' />
        <div className='title'>{i18n('Limited availability.')}</div>
        <div
          className='subtitle'
          style={{ marginBottom: '10px', marginTop: '17px' }}
        >
          {i18n(
            "Unfortunately, all our specialists in that particular area are fully booked but we'll email you once they add more availability."
          )}
          <br />
          <br />
          {i18n(
            'For any questions, please reach out anytime to: {0}',
            <ButtonMailTo
              label='care@fitoncare.com'
              email='care@fitoncare.com'
              className='gradient-font-1'
            />
          )}
        </div>
        <Button
          className='btn-primary small-btn m-t-32 m-b-40'
          onClick={onNext}
        >
          {i18n('ok')}
        </Button>
      </div>
    </div>
  )
}

export default LimitedAvailability
