import _ from 'lodash'

const formatUrl = (path, version) => {
  const adjustedPath = path[0] !== '/' ? '/' + path : path
  if (_.startsWith(path, 'http') || _.startsWith(path, '/api')) return path
  const _v = version || 'v1'
  if (process.env.NODE_ENV === 'production') {
    if (process.env.REACT_APP_ENV === 'staging')
      return `https://api-staging.fitoncare.com/api/${_v}` + adjustedPath
    return `https://api.fitoncare.com/api/${_v}` + adjustedPath
  } else {
    return `https://api-staging.fitoncare.com/api/${_v}` + adjustedPath
  }
}
export default formatUrl

export const getHealthieUrl = () => {
  if (process.env.NODE_ENV === 'production') {
    if (process.env.REACT_APP_ENV === 'staging')
      return 'https://staging-api.gethealthie.com/graphql'
    return 'https://api.gethealthie.com/graphql'
  } else {
    return 'https://staging-api.gethealthie.com/graphql'
  }
}

export const getHealthieHost = () => {
  if (process.env.NODE_ENV === 'production') {
    if (process.env.REACT_APP_ENV === 'staging')
      return 'https://staging-api.gethealthie.com/'
    return 'https://api.gethealthie.com/'
  } else {
    return 'https://staging-api.gethealthie.com/'
  }
}

export const isProduction = () => {
  if (process.env.NODE_ENV === 'production') {
    if (process.env.REACT_APP_ENV === 'staging') return false
    return true
  } else {
    return false
  }
}
