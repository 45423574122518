import { useState, useMemo, useEffect, useCallback, useContext } from 'react'
import _ from 'lodash'
import { isValidPhoneNumber } from 'react-phone-number-input'
import validator from 'validator'
import moment from 'moment-timezone'
import { CardElement } from '@stripe/react-stripe-js'
import queryString from 'query-string'
import {
  AuthContext,
  useClearUserInfo,
  useI18n,
  useInsuranceCardEntry,
  usePersonalInfo,
  useProfile,
  useTalkAboutCoverage,
} from 'contexts/AuthContext'
import { showNotification } from 'utils/basic'
import {
  HEALTHIE_CREATE_ENTRY_STRING,
  HEALTHIE_CREATE_FORM_ANSWER_GROUP_STRING,
  HEALTHIE_GET_USER_STRING,
  HEALTHIE_UPDATE_CLIENT_STRING,
  useHealthieAPIs,
} from 'apis/HealthieApiClient'
import {
  getHealthieUserId,
  getStateId,
  getToken,
  getUserId,
  setHealthieProviderId,
  setHealthieToken,
  setHealthieUserId,
  setStateId,
  setToken,
  setUserEmail,
  setUserId,
} from 'utils/authLocalStorage'
import { isProduction } from 'apis/formatUrl'
import {
  addStripeInfoPromise,
  addUserPromise,
  checkUserExistsPromise,
  createReferringPhysicianPromise,
  forwardHealthiePromise,
  getMetadataOptionsPromise,
  getStateListPromise,
  payStripePromise,
  providerSupportPromise,
  updateUserPromise,
  updateUserTagPromise,
} from 'apis/FitOnCareAPI'
import { ScreenKey } from 'views/Care/util'
import { brazeLogin, setBrazeProfile } from 'utils/brazeInit'
import {
  amplitudeLogin,
  logAmplitudeEvent,
  setAmplitudeProfile,
} from 'utils/amplitudeInit'

export const showErrorMessage = (error, i18n) => {
  console.error('request error', error)
  let errorMessage = i18n('There is something wrong, please try again later.')
  if (error.message) {
    errorMessage = error.message
  } else if (!_.isEmpty(error.messages)) {
    errorMessage = error.messages[0].message
  } else if (error.errors && error.errors[0]) {
    errorMessage = error.errors[0].message
  } else if (error instanceof Array && !_.isEmpty(error) && error[0].message) {
    errorMessage = error[0].message
  } else if (error.msg) {
    errorMessage = error.msg
  }
  showNotification({
    type: 'error',
    title: errorMessage,
  })
}

export const generatePassword = (passwordProps) => {
  const { uppercase, lowercase, symbols, numbers, length } = passwordProps
  let characters = ''
  let passwordLength = 0

  const setUpperCase = (isUpperCase) => {
    if (isUpperCase) {
      characters += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    }
    return ''
  }

  const setLowerCase = (isLowerCase) => {
    if (isLowerCase) {
      characters += 'abcdefghijklmnopqrstuvwxyz'
    }
    return ''
  }

  const setSymbols = (isSymbol) => {
    if (isSymbol) {
      characters += '!@#$%^&*()<>,.?/[]{}-=_+|/'
    }
    return ''
  }

  const setNumber = (isNumeric) => {
    if (isNumeric) {
      characters += '0123456789'
    }
    return ''
  }

  const getRandomInteger = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min
  }

  const passwordCharacters = () => {
    let password = ''
    if (characters.length) {
      for (let i = 0; i < passwordLength; i++) {
        password += characters[getRandomInteger(0, characters.length - 1)]
      }
      characters = ''
      passwordLength = 0
      return password
    }
  }

  const setPasswordLength = (length) => {
    passwordLength = length
    return passwordLength
  }

  setUpperCase(uppercase)
  setLowerCase(lowercase)
  setSymbols(symbols)
  setNumber(numbers)
  setPasswordLength(length)

  return passwordCharacters()
}

export const useCarePersonInfo = ({
  utmSource,
  utmCampaign,
  utmContent,
} = {}) => {
  const { i18n } = useI18n()
  const { personalInfo, setPersonalInfo } = usePersonalInfo()
  const [firstName, setFirstName] = useState(personalInfo.firstName)
  const [lastName, setLastName] = useState(personalInfo.lastName)
  const [preferredFirstName, setPreferredFirstName] = useState(
    personalInfo.preferredFirstName
  )
  const [state, setState] = useState(personalInfo.state || {})
  const [phone, setPhone] = useState(personalInfo.phone)
  const [isPhoneValid, setIsPhoneValid] = useState()
  const [isEmailValid, setIsEmailValid] = useState()
  const [email, setEmail] = useState(personalInfo.email)
  const [isPolicyChecked, setIsPolicyChecked] = useState(true)
  const [isTelehealthConsent, setIsTelehealthConsent] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [stateOptions, setStateOptions] = useState([])
  const { getUser, createFormAnswerGroup } = useHealthieAPIs()
  const { updateClient } = useUpdateClient()
  const { clearAll } = useClearUserInfo()

  useEffect(() => {
    const phoneNumber = phone?.replace(/\D/g, '')
    const isValid = isValidPhoneNumber('+1' + phoneNumber)
    setIsPhoneValid(isValid)
  }, [phone])

  useEffect(() => {
    setIsEmailValid(validator.isEmail(email ?? ''))
  }, [email])

  const getStateOptions = async () => {
    const options = await getStateListPromise()
    setStateOptions(options?.stateList || [])
    return options?.stateList || []
  }

  const isNextButtonEnable = useMemo(() => {
    const isValid =
      !_.isEmpty(firstName) &&
      !_.isEmpty(lastName) &&
      !_.isEmpty(state) &&
      !_.isEmpty(phone) &&
      isPhoneValid &&
      !_.isEmpty(email) &&
      isEmailValid &&
      isPolicyChecked &&
      isTelehealthConsent &&
      !isLoading
    return isValid
  }, [
    firstName,
    lastName,
    state,
    phone,
    email,
    isPhoneValid,
    isEmailValid,
    isPolicyChecked,
    isTelehealthConsent,
    isLoading,
  ])

  const createFormAnswerGroupFun = async (state) => {
    try {
      const healthieUserId = getHealthieUserId()
      Promise.all([
        getHealthieRequest({
          healthieRequest: createFormAnswerGroup,
          queryString: HEALTHIE_CREATE_FORM_ANSWER_GROUP_STRING,
          variables: {
            finished: true,
            custom_module_form_id: isProduction()
              ? state.code === 'CA'
                ? '1886558'
                : '1886611'
              : state.code === 'CA'
              ? '976421'
              : '976420',
            user_id: healthieUserId,
            form_answers: [
              {
                custom_module_id: isProduction()
                  ? state.code === 'CA'
                    ? '24210631'
                    : '24211146'
                  : state.code === 'CA'
                  ? '8459034'
                  : '8459032',
                user_id: healthieUserId,
                answer: `I have read and agree to FitOn Care's Telehealth Consent.`,
              },
            ],
          },
          operationName: 'createFormAnswerGroup',
        }),
        getHealthieRequest({
          healthieRequest: createFormAnswerGroup,
          queryString: HEALTHIE_CREATE_FORM_ANSWER_GROUP_STRING,
          variables: {
            finished: true,
            custom_module_form_id: isProduction() ? '1886556' : '976417',
            user_id: healthieUserId,
            form_answers: [
              {
                custom_module_id: isProduction() ? '24210598' : '8459030',
                user_id: healthieUserId,
                answer: `I have read and agree to FitOn Care's Privacy Policy & Terms of Use`,
              },
            ],
          },
          operationName: 'createFormAnswerGroup',
        }),
      ])
    } catch (error) {
      console.log(error)
    }
  }

  const getUserInfo = async (variables) => {
    let options = stateOptions
    if (_.isEmpty(options)) {
      options = await getStateOptions()
    }

    const { error, data } = await getHealthieRequest({
      healthieRequest: getUser,
      queryString: HEALTHIE_GET_USER_STRING,
      variables,
      operationName: 'getUser',
    })
    checkNeedToRelogin({ data, operationName: 'getUser', clearAll })
    if (data && !_.isEmpty(data.user)) {
      const user = data.user
      const localStateId = getStateId()
      const _state = _.find(
        options,
        localStateId
          ? { id: +localStateId }
          : { legalEntityId: user.dietitian_id }
      )
      setPersonalInfo({
        firstName: user.legal_name ? user.legal_name : user.first_name,
        lastName: user.last_name,
        preferredFirstName: user.legal_name ? user.first_name : user.legal_name,
        phone: user.phone_number,
        email: user.email,
        state: _state && _state.type !== 3 ? _state : state,
      })
      setFirstName(user.legal_name ? user.legal_name : user.first_name)
      setLastName(user.last_name)
      setPhone(user.phone_number)
      setEmail(user.email)
      setState(_state && _state.type !== 3 ? _state : state)
      setPreferredFirstName(user.legal_name ? user.first_name : user.legal_name)
    }
    return { error, data }
  }

  const uploadPersonalInfo = async ({
    onSuccess = () => {},
    onVerifyEmail = () => {},
  } = {}) => {
    setPersonalInfo({
      firstName,
      lastName,
      preferredFirstName,
      phone,
      email,
      state,
    })
    setHealthieProviderId(state.legalEntityId)
    setIsLoading(true)
    try {
      const token = getToken()
      const userId = getHealthieUserId()
      if (token && userId) {
        const { data } = await getHealthieRequest({
          healthieRequest: getUser,
          queryString: HEALTHIE_GET_USER_STRING,
          variables: { id: userId, include_tags: true },
          operationName: 'getUser',
        })
        checkNeedToRelogin({ data, operationName: 'getUser', clearAll })
        if (data?.user?.email === email) {
          const _firstName = preferredFirstName ? preferredFirstName : firstName
          const _legalName = preferredFirstName ? firstName : preferredFirstName
          const variables = {
            id: userId,
            first_name: _firstName,
            last_name: lastName,
            phone_number: phone,
            legal_name: _legalName,
          }
          updateClient({
            variables,
          })
          const updateUserBody = {
            firstName: firstName,
            lastName: lastName,
            preferredFirstName: preferredFirstName,
            phoneNumber: phone,
          }
          if (utmSource && utmCampaign && utmContent) {
            updateUserBody.utm = {
              source: utmSource,
              campaign: utmCampaign,
              content: utmContent,
            }
          }
          updateUserPromise(updateUserBody)
          setBrazeProfile({
            firstName: firstName,
            lastName: lastName,
            email: email,
            phone: phone,
            utmSource,
            utmCampaign,
            utmContent,
          })
          setUserEmail(email)
          setAmplitudeProfile({ utmSource, utmCampaign, utmContent })
          updateUserPromise({ userStatus: 1 })
          onSuccess?.()
          return
        }
      }

      const resp = await checkUserExistsPromise(email)
      if (resp.exists) {
        setUserEmail(email)
        logAmplitudeEvent('Redirect: Healthi - Existing User')
        onVerifyEmail?.()
        return
      }
      const signUpResp = await addUserPromise({
        firstName: firstName,
        lastName: lastName,
        preferredFirstName: preferredFirstName,
        email: email,
        countryCode: '1',
        phoneNumber: phone,
        stateId: state.id,
        utmSource,
        utmCampaign,
        utmContent,
      })
      if (signUpResp.code !== 200) {
        showErrorMessage(signUpResp, i18n)
      } else if (signUpResp.data) {
        const userInfo = signUpResp.data
        setHealthieUserId(userInfo.healthieUserId)
        const { query } = queryString.parseUrl(userInfo?.setPasswordLink || '')
        setHealthieToken(query.signup_token || '')
        if (userInfo?.token) {
          setToken(userInfo.token)
        }
        await createFormAnswerGroupFun(state)
        if (userInfo?.id) {
          setUserId(userInfo.id)
          amplitudeLogin()
          brazeLogin()
          setUserEmail(email)
          setStateId(state.id)
          // addBrazeAlias({ label: 'fid', name: userInfo.id.toString() })
          setBrazeProfile({
            id: userInfo.id,
            healthieID: getHealthieUserId(),
            firstName: firstName,
            lastName: lastName,
            email: email,
            phone: phone,
            state: state.name,
            legalEntity:
              state.type === 1 ? 'CA' : state.type === 2 ? 'NY' : 'FL',
            utmSource,
            utmCampaign,
            utmContent,
          })
          setAmplitudeProfile({
            utmSource,
            utmCampaign,
            utmContent,
            state: state.name,
          })
        }
        updateUserPromise({ userStatus: 1 })
        onSuccess()
      }
      setIsLoading(false)
      // }
    } catch (error) {
      setIsLoading(false)
      showErrorMessage(error, i18n)
    }
  }

  return {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    preferredFirstName,
    setPreferredFirstName,
    state,
    setState,
    phone,
    setPhone,
    isPhoneValid,
    setIsPhoneValid,
    isEmailValid,
    setIsEmailValid,
    email,
    setEmail,
    isPolicyChecked,
    setIsPolicyChecked,
    isTelehealthConsent,
    setIsTelehealthConsent,
    isNextButtonEnable,
    uploadPersonalInfo,
    getUserInfo,
    stateOptions,
    getStateOptions,
  }
}

export const useCareProfile = () => {
  const { profile, setProfile } = useProfile()
  const [birthday, setBirthday] = useState(profile.birthday)
  const [gender, setGender] = useState(profile.gender || 'Female')
  const [heightFt, setHeightFt] = useState(profile.heightFt)
  const [heightIn, setHeightIn] = useState(profile.heightIn)
  const [weightLbs, setWeightLbs] = useState(profile.weightLbs)
  const { updateClient } = useUpdateClient()
  const { createHealtieEntry } = useCreateEntry()
  const healthieUserId = getHealthieUserId()

  const getInches = useCallback(() => {
    return Number(heightFt) * 12 + Number(heightIn)
  }, [heightFt, heightIn])

  const isNextButtonEnable = useMemo(() => {
    return (
      !_.isEmpty(birthday) &&
      !_.isEmpty(gender) &&
      heightFt &&
      +heightIn >= 0 &&
      weightLbs &&
      moment(birthday, 'MM/DD/YYYY', true).isValid() &&
      moment(birthday).isBefore(moment())
    )
  }, [birthday, gender, heightFt, heightIn, weightLbs])

  const uploadProfile = async (onSuccess) => {
    setProfile({
      birthday,
      gender,
      heightFt,
      heightIn,
      weightLbs,
    })

    updateClient({
      variables: {
        id: healthieUserId,
        dob: moment(birthday, 'MM/DD/YYYY').format('YYYY-MM-DD'),
        gender: gender,
        height: getInches().toString(),
      },
      onSuccess: () => {
        onSuccess?.()
      },
    })

    const _gender = gender === 'Male' ? 1 : gender === 'Female' ? 2 : 3
    updateUserPromise({ gender: _gender })

    createHealtieEntry({
      weight: weightLbs,
      onSuccess: () => {
        console.log('create entry success')
      },
    })
  }

  return {
    birthday,
    setBirthday,
    gender,
    setGender,
    heightFt,
    setHeightFt,
    heightIn,
    setHeightIn,
    weightLbs,
    setWeightLbs,
    isNextButtonEnable,
    uploadProfile,
  }
}

export const useCareInsurancePay = () => {
  const { i18n } = useI18n()
  const { insuranceCardEntry, setInsuranceCardEntry } = useInsuranceCardEntry()
  const { getInsurancePlans, createSentFax } = useHealthieAPIs()
  const { updateClient } = useUpdateClient()

  const [insurancePlan, setInsurancePlan] = useState(
    insuranceCardEntry.insurancePlan || {}
  )
  const [insurancePlanOptions, setInsurancePlanOptions] = useState([])
  const [coverage, setCoverage] = useState(insuranceCardEntry.coverage)
  const [coverageOptions] = useState([
    { value: 'HMO', label: 'HMO' },
    { value: 'PPO', label: 'PPO' },
  ])
  const [type, setType] = useState(insuranceCardEntry.type)
  const [typeOptions] = useState([
    { value: 'primary', label: 'Primary' },
    { value: 'secondary', label: 'Secondary' },
    { value: 'inactive', label: 'Inactive' },
  ])
  const [relationship, setRelationship] = useState(
    insuranceCardEntry.relationship
  )
  const [relationshipOptions] = useState([
    { value: 'Self', label: 'Self' },
    { value: 'Child', label: 'Child' },
    { value: 'Spouse', label: 'Spouse' },
    { value: 'None', label: 'None' },
    { value: 'Other', label: 'Other' },
  ])
  const [policyNumber, setPolicyNumber] = useState(
    insuranceCardEntry.policyNumber
  )
  const [groupNumber, setGroupNumber] = useState(insuranceCardEntry.groupNumber)
  const [companyPhone, setCompanyPhone] = useState(
    insuranceCardEntry.companyPhone
  )
  const [stateOptions, setStateOptions] = useState([])
  const [addressInfo, setAddressInfo] = useState(
    insuranceCardEntry.addressInfo || {}
  )
  const [policyHolderInfo, setPolicyHolderInfo] = useState(
    insuranceCardEntry.policyHolderInfo || {}
  )

  const { tags } = useMetadataOptions()

  const { applyHealthieTags, removeHealthieTags } = useApplyTags()

  const getStateOptions = useCallback(async () => {
    const options = await getStateListPromise()
    setStateOptions(options?.stateList || [])
  }, [])

  useEffect(() => {
    getStateOptions()
  }, [getStateOptions])

  const isCardInfoNextButtonEnable = useMemo(() => {
    return (
      !_.isEmpty(insurancePlan) &&
      !_.isEmpty(coverage) &&
      !_.isEmpty(type) &&
      !_.isEmpty(relationship) &&
      policyNumber
    )
  }, [insurancePlan, coverage, type, relationship, policyNumber])

  const isAddressNextButtonEnable = useMemo(() => {
    return !_.isEmpty(addressInfo)
  }, [addressInfo])

  const saveLocalData = () => {
    setInsuranceCardEntry({
      insurancePlan,
      coverage,
      type,
      relationship,
      policyNumber,
      groupNumber,
      companyPhone,
      addressInfo,
      policyHolderInfo,
    })
  }

  const createSentFaxRequest = async () => {
    try {
      const { error, data } = await createSentFax({
        variables: {
          dietitian: {
            id: getHealthieUserId(),
            location: {
              line1: addressInfo.line1,
              line2: addressInfo.line2,
              city: addressInfo.city,
              state: addressInfo.state?.code,
              zip: addressInfo.postcode,
              country: 'US',
              id: getHealthieUserId(),
            },
            phone_number: companyPhone,
          },
          patient_id: getHealthieUserId(),
        },
      })
    } catch (error) {
      console.log('createSentFax error', error)
    }
  }

  const updateClientInfo = ({ onSuccess } = {}) => {
    saveLocalData()
    const policy = {
      insurance_plan_id: insurancePlan.id,
      priority_type: type.toLowerCase(),
      num: policyNumber,
      group_num: groupNumber,
      policy_phone_number: companyPhone,
      holder_relationship: relationship,
    }
    if (relationship !== 'Self') {
      policy.holder_dob = moment(policyHolderInfo.dob, 'MM/DD/YYYY').format(
        'YYYY-MM-DD'
      )
      policy.holder_first = policyHolderInfo.firstName
      policy.holder_gender = policyHolderInfo.gender
      policy.holder_last = policyHolderInfo.lastName
      policy.holder_location = {
        line1: addressInfo.line1,
        line2: addressInfo.line2,
        city: addressInfo.city,
        state: addressInfo.state?.code,
        zip: addressInfo.postcode,
        country: 'US',
      }
    }
    updateClient({
      variables: {
        id: getHealthieUserId(),
        location: {
          line1: addressInfo.line1,
          line2: addressInfo.line2,
          city: addressInfo.city,
          state: addressInfo.state?.code,
          zip: addressInfo.postcode,
          country: 'US',
        },
        policies: [policy],
      },
      onSuccess: () => {
        onSuccess?.()
      },
    })

    const _insuranceAddress = addressInfo.formattedAddress
      ? addressInfo.formattedAddress
      : `${addressInfo.line1}, ${addressInfo.city} ${addressInfo.state?.code} ${addressInfo.postcode}`
    setBrazeProfile({
      insurancePlan: insurancePlan.payer_name,
      insuranceCoverage: coverage,
      insuranceType: type,
      insuranceAddress: _insuranceAddress,
      insuranceRelationship: relationship,
    })
    if (relationship !== 'Self') {
      setBrazeProfile({
        insurancePolicyHolderName:
          policyHolderInfo.firstName + ' ' + policyHolderInfo.lastName,
      })
    }
    // applyHealthieTags({
    //   variables: {
    //     ids: [],
    //     taggable_user_id: getHealthieUserId(),
    //   },
    // })
    if (coverage === 'HMO') {
      // createSentFaxRequest()
    }
  }

  const getInsurancePlansOptions = async () => {
    try {
      const { error, data } = await getInsurancePlans({
        variables: { order_by: 'PAYER_NAME_ASC' },
      })
      if (data) {
        if (data.insurancePlans?.messages) {
          showErrorMessage(data.insurancePlans?.messages, i18n)
        } else {
          setInsurancePlanOptions(data?.insurancePlans)
        }
      } else if (error) {
        showErrorMessage(error, i18n)
      }
    } catch (error) {
      showErrorMessage(error, i18n)
    }
  }

  return {
    insurancePlan,
    setInsurancePlan,
    insurancePlanOptions,
    getInsurancePlansOptions,
    coverage,
    setCoverage,
    coverageOptions,
    type,
    setType,
    typeOptions,
    relationship,
    setRelationship,
    relationshipOptions,
    policyNumber,
    setPolicyNumber,
    groupNumber,
    setGroupNumber,
    companyPhone,
    setCompanyPhone,
    addressInfo,
    setAddressInfo,
    policyHolderInfo,
    setPolicyHolderInfo,
    stateOptions,
    isCardInfoNextButtonEnable,
    isAddressNextButtonEnable,
    updateClientInfo,
    saveLocalData,
    applyHealthieTags,
    removeHealthieTags,
    tags,
  }
}

export const useUpdateClient = () => {
  const { i18n } = useI18n()
  const { healthieUpdateClient } = useHealthieAPIs()
  const { clearAll } = useClearUserInfo()

  const updateClient = async ({ variables, onSuccess = () => {} } = {}) => {
    try {
      const { error, data } = await getHealthieRequest({
        healthieRequest: healthieUpdateClient,
        queryString: HEALTHIE_UPDATE_CLIENT_STRING,
        variables: variables,
        operationName: 'updateClient',
      })
      if (data) {
        if (data.updateClient?.messages) {
          showErrorMessage(data.updateClient?.messages, i18n)
          checkNeedToRelogin({ data, operationName: 'updateClient', clearAll })
        } else {
          onSuccess()
        }
      } else if (error) {
        showErrorMessage(error, i18n)
      }
    } catch (error) {
      showErrorMessage(error, i18n)
    }
  }

  return { updateClient }
}

export const useApplyTags = () => {
  const applyHealthieTags = async ({ tagId, onSuccess = () => {} } = {}) => {
    try {
      const resp = await updateUserTagPromise({
        type: 'add',
        tagId,
      })
      if (resp.code === 200) {
        onSuccess()
      } else {
        // showErrorMessage(error, i18n)
      }
    } catch (error) {
      // showErrorMessage(error, i18n)
    }
  }

  const removeHealthieTags = async ({ tagId, onSuccess = () => {} } = {}) => {
    try {
      const resp = await updateUserTagPromise({
        type: 'remove',
        tagId,
      })
      if (resp.code === 200) {
        onSuccess()
      } else {
        // showErrorMessage(error, i18n)
      }
    } catch (error) {
      // showErrorMessage(error, i18n)
    }
  }

  return { applyHealthieTags, removeHealthieTags }
}

export const useCreateEntry = () => {
  const { i18n } = useI18n()
  const { createEntry } = useHealthieAPIs()
  const { clearAll } = useClearUserInfo()

  const createHealtieEntry = async ({ weight, onSuccess = () => {} } = {}) => {
    try {
      const { error, data } = await getHealthieRequest({
        healthieRequest: createEntry,
        queryString: HEALTHIE_CREATE_ENTRY_STRING,
        variables: {
          user_id: getHealthieUserId(),
          type: 'MetricEntry',
          category: 'Weight',
          metric_stat: weight.toString(),
          description: 'Source: Patient Signup',
        },
        operationName: 'createEntry',
      })
      if (data) {
        if (data.createEntry?.messages) {
          showErrorMessage(data.createEntry?.messages, i18n)
          checkNeedToRelogin({ data, operationName: 'createEntry', clearAll })
        } else {
          onSuccess()
        }
      } else if (error) {
        showErrorMessage(error, i18n)
      }
    } catch (error) {
      showErrorMessage(error, i18n)
    }
  }

  return { createHealtieEntry }
}

export const useCareCompleteBooking = () => {
  const { i18n } = useI18n()
  const { completeBooking } = useHealthieAPIs()
  const { personalInfo } = usePersonalInfo()
  const { applyHealthieTags } = useApplyTags()

  const completeBookingAppointment = async ({
    slot,
    selectedAppointmentType,
    selectedContactType,
    onSuccess = () => {},
    onFailure = () => {},
  }) => {
    const convertedValues = {
      appointment_id: slot.appointment_id,
      first_name: personalInfo.firstName,
      last_name: personalInfo.lastName,
      email: personalInfo.email,
      phone_number: personalInfo.phone,
      date: slot.date,
      provider_id: slot.user_id,
      appointment_type_id: (selectedAppointmentType || {}).id,
      contact_type: selectedContactType,
      timezone:
        Intl.DateTimeFormat().resolvedOptions().timeZone || 'America/New_York',
    }

    try {
      const {
        data: { completeCheckout: payload },
      } = await completeBooking({ variables: convertedValues })

      if (!payload.messages && payload.appointment) {
        onSuccess(payload.appointment)
      } else {
        onFailure()
        showErrorMessage(payload, i18n)
      }
    } catch (e) {
      onFailure()
      showErrorMessage({}, i18n)
    }
  }
  return { completeBookingAppointment, personalInfo, applyHealthieTags }
}

export const useMetadataOptions = () => {
  const authContext = useContext(AuthContext)

  const { metadataOptions, dispatch } = authContext
  const [helpMostOptions, setHelpMostOptions] = useState(
    metadataOptions?.helpMostOptions || []
  )
  const [diseaseHelpOptions, setDiseaseHelpOptions] = useState(
    metadataOptions?.diseaseHelpOptions || []
  )
  const [hearAboutOptions, setHearAboutOptions] = useState(
    metadataOptions?.hearAboutOptions || []
  )
  const [insuranceProviders, setInsuranceProviders] = useState(
    metadataOptions?.insuranceProviders || []
  )
  const [tags, setTags] = useState(metadataOptions?.tags || [])

  const getMetadataOptions = useCallback(async () => {
    const _options = await getMetadataOptionsPromise()
    if (!_.isEmpty(_options)) {
      setHelpMostOptions(_options.helpMostOptions || [])
      setDiseaseHelpOptions(_options.diseaseHelpOptions || [])
      setHearAboutOptions(_options.hearAboutOptions || [])
      setInsuranceProviders(_options.insuranceProviders || [])
      setTags(_options.tags || [])
      dispatch({
        type: 'METADATA_OPTIONS',
        payload: {
          metadataOptions: {
            ..._options,
          },
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getMetadataOptions()
  }, [getMetadataOptions])

  return {
    helpMostOptions,
    diseaseHelpOptions,
    hearAboutOptions,
    insuranceProviders,
    tags,
  }
}

export const useStep4Location = () => {
  const { updateClient } = useUpdateClient()
  const { personalInfo } = usePersonalInfo()
  const healthieUserId = getHealthieUserId()

  const getNextScreen = ({ survey, hormonalAutoimmune }) => {
    const state = personalInfo.state || {}
    updateClient({
      variables: {
        id: healthieUserId,
        quick_notes: hormonalAutoimmune
          ? `${survey.name}: ${
              hormonalAutoimmune.otherText
                ? `${hormonalAutoimmune.name} : ${hormonalAutoimmune.otherText}`
                : hormonalAutoimmune.name
            }`
          : `${
              survey.otherText
                ? `${survey.name} : ${survey.otherText}`
                : survey.name
            }`,
      },
    })

    if (!state.available) {
      return ScreenKey.COMING_SOON
    } else if (
      !survey.available ||
      (hormonalAutoimmune && !hormonalAutoimmune.available)
    ) {
      return ScreenKey.LIMITED_AVAILABILITY
    } else {
      return ScreenKey.COMPLETE_PROFILE
    }
  }

  const isOptionsValid = ({ state, helpMostOption, diseaseHelpOption }) => {
    if (!state.available) {
      return false
    } else if (
      !helpMostOption.available ||
      (diseaseHelpOption && !diseaseHelpOption.available)
    ) {
      return false
    } else {
      return true
    }
  }

  return { getNextScreen, isOptionsValid }
}

export const usePrimaryPhysician = () => {
  const authContext = useContext(AuthContext)

  const { primaryPhysician, dispatch } = authContext

  const { i18n } = useI18n()
  const { createReferral } = useHealthieAPIs()

  const createReferring = async ({
    variables,
    onSuccess = (id) => {},
  } = {}) => {
    try {
      const body = _.omitBy(variables, _.isEmpty)
      body.acceptsInsurance = true
      const data = await createReferringPhysicianPromise(body)
      if (data) {
        if (data.code !== 200) {
          showErrorMessage({ message: data.msg }, i18n)
        } else {
          onSuccess(data.data?.referringPhysician?.id)
        }
      }
    } catch (error) {
      showErrorMessage(error, i18n)
    }
  }

  const createReferralFun = async ({
    variables,
    onSuccess = (id) => {},
  } = {}) => {
    try {
      const { error, data } = await createReferral({
        variables: variables,
      })
      if (data) {
        if (data.createReferral?.messages) {
          showErrorMessage(data.createReferral?.messages, i18n)
        } else {
          onSuccess()
        }
      } else if (error) {
        showErrorMessage(error, i18n)
      }
    } catch (error) {
      showErrorMessage(error, i18n)
    }
  }

  const saveLocalPrimaryPhysician = (physician) => {
    dispatch({
      type: 'PRIMARY_PHYSICIAN',
      payload: {
        primaryPhysician: physician,
      },
    })
  }

  return {
    saveLocalPrimaryPhysician,
    primaryPhysician: primaryPhysician || [],
    createReferring,
    createReferralFun,
  }
}

export const useCardPay = ({
  setLoading,
  stripe,
  elements,
  product,
  onPurchaseFailure,
  onPurchaseSuccess,
}) => {
  const { i18n } = useI18n()
  const [isComplete, setIsComplete] = useState(false)

  const cardStyle = {
    style: {
      base: {
        color: '#323555',
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#757575',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  }
  const handleCardChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setIsComplete(event.complete)
  }

  const handleCardSubmitWithoutProduct = async (event) => {
    setLoading(true)
    event.stopPropagation()
    event.preventDefault()
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      showNotification({
        type: 'error',
        title: i18n('There is something wrong, please try again later.'),
      })
      setLoading(false)
      return
    }

    const onError = ({ message, code, status } = {}) => {
      showNotification({
        type: 'error',
        title: message || i18n('Save failed!'),
      })
      if (onPurchaseFailure) return onPurchaseFailure({ message, code, status })
    }

    let _paymentMethodId
    const cardElement = elements.getElement(CardElement)
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    })
    if (error) {
      onError({
        ...error,
        status: error.type,
      })
      setLoading(false)
      return
    } else {
      _paymentMethodId = paymentMethod?.id
    }

    const onSuccess = () => {
      if (onPurchaseSuccess) return onPurchaseSuccess()
    }

    const payResp = await addStripeInfoPromise({
      paymentMethodId: _paymentMethodId,
    })
    if (payResp?.code === 200) {
      onSuccess()
    } else {
      onError({ message: payResp?.msg })
    }

    setLoading(false)
  }

  const handleCardSubmit = async (event) => {
    setLoading(true)
    event.stopPropagation()
    event.preventDefault()
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      showNotification({
        type: 'error',
        title: i18n('There is something wrong, please try again later.'),
      })
      setLoading(false)
      return
    }

    const onError = ({ message, code, status } = {}) => {
      showNotification({
        type: 'error',
        title: message || i18n('Save failed!'),
      })
      if (onPurchaseFailure) return onPurchaseFailure({ message, code, status })
    }

    const cardElement = elements.getElement(CardElement)
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    })
    if (error) {
      onError({
        ...error,
        status: error.type,
      })
      setLoading(false)
      return
    }

    if (onPurchaseSuccess) return onPurchaseSuccess(paymentMethod)
    setLoading(false)
  }

  const onCardPay = async (paymentMethod) => {
    const onSuccess = () => {
      if (onPurchaseSuccess) return onPurchaseSuccess()
      // showNotification({ type: 'success', title: i18n('Payment success!') })
    }

    const onError = ({ message, code, status } = {}) => {
      showNotification({
        type: 'error',
        title: message || i18n('Payment failed!'),
      })
      if (onPurchaseFailure) return onPurchaseFailure({ message, code, status })
    }

    const payResp = await payStripePromise({
      userId: getUserId(),
      paymentMethodId: paymentMethod?.id,
      productId: product.id,
    })
    if (payResp && !_.isEmpty(payResp.data)) {
      if (
        payResp?.data?.requiresConfirmation === true ||
        payResp?.data?.requiresAction === true
      ) {
        let promise
        promise = stripe.confirmCardPayment(
          payResp?.data?.paymentIntentClientSecret
        )
        await promise.then(async (result) => {
          if (result?.paymentIntent?.status === 'succeeded') {
            onSuccess()
          } else {
            onError({ message: payResp.msg })
          }
        })
      } else {
        onSuccess()
      }
    } else {
      onError({ message: payResp?.msg })
    }
  }

  return {
    cardStyle,
    handleCardChange,
    handleCardSubmit,
    handleCardSubmitWithoutProduct,
    onCardPay,
    isComplete,
  }
}

export const useHealthieUserInfo = () => {
  const [user, setUser] = useState({})
  const { getUser } = useHealthieAPIs()
  const { clearAll } = useClearUserInfo()

  useEffect(() => {
    getHealthieUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getHealthieUser = async (variables) => {
    const userId = getHealthieUserId()

    if (userId) {
      const { data } = await getHealthieRequest({
        healthieRequest: getUser,
        queryString: HEALTHIE_GET_USER_STRING,
        variables: { id: userId, include_tags: true },
        operationName: 'getUser',
      })
      if (data && !_.isEmpty(data.user)) {
        setUser(data.user)
      }
      checkNeedToRelogin({ data, operationName: 'createEntry', clearAll })
    }
  }

  return { user }
}

export const getHealthieRequest = async ({
  healthieRequest,
  queryString,
  variables,
  operationName,
}) => {
  // const healthieToken = getHealthieToken()
  const fitonToken = getToken()

  let data = {}
  let error = {}
  // if (healthieToken) {
  //   const resp = await healthieRequest({
  //     variables,
  //   })
  //   error = resp.error
  //   data = resp.data
  // } else
  if (fitonToken) {
    data = await forwardHealthiePromise({
      query: queryString,
      operationName,
      variables,
    })
  } else {
    return {}
  }
  return { error, data }
}

const checkNeedToRelogin = ({ data, operationName, clearAll }) => {
  if (!_.isEmpty(data?.[operationName]?.messages)) {
    const message = data?.[operationName]?.messages[0]
    if (
      message?.message === 'You need to sign in or sign up before continuing'
    ) {
      clearAll()
      return true
    }
  }
  return false
}

export const useWelcomeNew = () => {
  const { talkAboutCoverage, setTalkAboutCoverage } = useTalkAboutCoverage()
  const { personalInfo, setPersonalInfo } = usePersonalInfo()
  const [state, setState] = useState(personalInfo.state || {})
  const [insuranceProvider, setInsuranceProvider] = useState(talkAboutCoverage)

  const { insuranceProviders } = useMetadataOptions()
  const { stateOptions } = useCareInsurancePay()

  useEffect(() => {
    const localStateId = getStateId()
    if (localStateId) {
      const _state = _.find(
        stateOptions,
        localStateId ? { id: +localStateId } : {}
      )
      setState(_state)
    }
  }, [stateOptions])

  const onCheck = async () => {
    setTalkAboutCoverage({ ...insuranceProvider })
    setPersonalInfo({ state })
    setStateId(state.id)
    const isValid = state.available
    const providerSupport = await providerSupportPromise({
      stateId: state.id,
      insuranceProviderId: insuranceProvider.id,
    })
    const isProviderSupport = providerSupport.accept
    return {
      isValid: isValid && isProviderSupport,
      providerSupport: isProviderSupport,
      isStateValid: isValid,
    }
  }

  return {
    state,
    setState,
    insuranceProviders,
    stateOptions,
    insuranceProvider,
    setInsuranceProvider,
    onCheck,
  }
}
