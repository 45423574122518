import React from 'react'
import classnames from 'classnames'
import { Input } from 'antd'
import classes from './index.module.scss'

const { style } = classes

const HeightInputNumber = ({
  value,
  setValue,
  placeholder,
  unit,
  max,
  min,
  className,
}) => {
  const onChange = (e) => {
    const regex = /^\d*$/
    const value = e.target.value
    if (regex.test(value)) {
      setValue(value)
    }
  }

  const onBlur = (e) => {
    const value = e.target.value
    if (value.length > 0) {
      const numberValue = Number(value)
      if (numberValue < min) {
        setValue(min)
      } else if (numberValue > max) {
        setValue(max)
      } else {
        setValue(value)
      }
    } else if (value.length === 0) {
      setValue('')
    }
  }

  return (
    <div className={classnames(style, className)}>
      <Input
        rootClassName='input-sec'
        value={value}
        suffix={unit}
        onChange={onChange}
        placeholder={placeholder}
        controls={false}
        maxLength={max.toString().length}
        onBlur={onBlur}
        type='text'
        pattern='\d*'
      />
    </div>
  )
}

export default HeightInputNumber
