import React from 'react'
import ContentHeader from './ContentHeader'
import { useI18n, useSurvey } from 'contexts/AuthContext'
import CareSelectButton from 'components/CareSelectButton/index'
import { ScreenKey, UploadSelectionOptionType } from './util'
import { useMetadataOptions, useStep4Location } from 'hooks/careHooks'
import { uploadSelectionPromise } from 'apis/FitOnCareAPI'
import { logAmplitudeEvent } from 'utils/amplitudeInit'
import { setBrazeProfile } from 'utils/brazeInit'
import _ from 'lodash'

const Survey = (props) => {
  const { i18n } = useI18n()
  const { survey, setSurvey } = useSurvey()
  const { helpMostOptions, diseaseHelpOptions } = useMetadataOptions()
  const { getNextScreen } = useStep4Location()

  const onSubmit = async (item) => {
    logAmplitudeEvent('Screen View: Onboarding - Condition Primary', {
      Condition:
        item.name === 'Other' ? `Other - ${item.otherText}` : item.name,
    })
    uploadSelectionPromise({
      optionType: UploadSelectionOptionType.HELP_MOST_OPTION,
      selectedOptionId: item.id,
      otherInputText: item.otherText,
    })
    setTimeout(() => {
      const hasDiseaseHelp = _.find(
        diseaseHelpOptions || [],
        (option) => option.primaryId === item.id
      )
      if (hasDiseaseHelp && item.available) {
        props.setCurrentKey(ScreenKey.HORMONAL_AUTOIMMUNE)
      } else {
        const nextScreenKey = getNextScreen({ survey: item })
        props.setCurrentKey(nextScreenKey)
        setBrazeProfile({
          conditionPrimary: item.otherText
            ? `${item.name} : ${item.otherText}`
            : item.name,
        })
      }
    }, 100)
  }

  return (
    <div className='info-sec'>
      <ContentHeader {...props} />
      <div className='info-content'>
        <div className='title'>
          {i18n('What can we help you with the most?')}
        </div>
        <div className='subtitle' style={{ marginBottom: '32px' }}>
          {i18n('Select one option from the list below')}
        </div>
        {helpMostOptions.map((item, index) => (
          <div key={index}>
            {item.name === 'Other' ? (
              <CareSelectButton
                key={index}
                {...{
                  selected: survey.id === item.id,
                  setSelected: () => {
                    setSurvey({ ...item, otherText: survey.otherText })
                  },
                  text: item.name,
                  className: 'left-align m-t-12',
                  showInput: survey.name === 'Other',
                  placeholder: i18n('Please specify'),
                  inputText: survey.otherText || '',
                  maxLength: 50,
                  setInputText: (value) => {
                    const regex = /^[a-zA-Z0-9\s]*$/
                    if (regex.test(value)) {
                      setSurvey({ ...survey, otherText: value })
                    }
                  },
                  onSubmit: () =>
                    onSubmit({ ...item, otherText: survey.otherText }),
                }}
              />
            ) : (
              <CareSelectButton
                key={index}
                {...{
                  selected: survey.id === item.id,
                  setSelected: () => {
                    setSurvey({ ...item })
                    onSubmit({ ...item })
                  },
                  text: item.name,
                  className: 'm-b-12 left-align',
                }}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default Survey
