import React, { useEffect, useMemo, useState } from 'react'
import { useI18n } from 'contexts/AuthContext'
import { Button, Select } from 'antd'
import _ from 'lodash'
import Lottie from 'lottie-react'
import classnames from 'classnames'
import { ScreenKey } from './util'
import { useWelcomeNew } from 'hooks/careHooks'
import DropdownSelect, {
  DropdownSelectWithOpen,
} from 'components/DropdownSelect/index'
import { isMobile } from 'utils/basic'
import logoIcon from 'static/img/fiton-care-logo.png'
import bgWelcome from 'static/img/bg-welcome-new-splash.png'
import checkMarkJson from 'static/json/red-checkmark.json'
import { logAmplitudeEvent } from 'utils/amplitudeInit'
import CareSelectButton from 'components/CareSelectButton/index'
const { Option } = Select

const WelcomeNew = (props) => {
  const { i18n } = useI18n()
  const [isQualified, setIsQualified] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [providerOptionOpen, setProviderOptionOpen] = useState(false)

  const {
    state,
    setState,
    insuranceProvider,
    setInsuranceProvider,
    insuranceProviders,
    stateOptions,
    onCheck,
  } = useWelcomeNew()

  const onCheckClick = async () => {
    setIsLoading(true)
    const { isValid, providerSupport } = await onCheck()
    setIsLoading(false)
    props.setSupportForNewWelcome({ isValid, providerSupport })
    if (isValid) {
      setIsQualified(true)
      setTimeout(() => {
        props.setCurrentKey(ScreenKey.PERSONAL_INFO)
      }, 3000)
    } else {
      props.setCurrentKey(ScreenKey.PERSONAL_INFO)
    }
    const params = {
      State: state.name,
      Provider: insuranceProvider.otherText
        ? `${insuranceProvider.name} - ${insuranceProvider.otherText}`
        : insuranceProvider.name,
    }
    logAmplitudeEvent('Click: Onboarding - Welcome', params)
  }

  const isCheckEnable = useMemo(() => {
    const isValidProvider =
      insuranceProvider?.name === 'Other'
        ? !_.isEmpty(insuranceProvider.otherText)
        : !_.isEmpty(insuranceProvider)

    return !_.isEmpty(state) && isValidProvider
  }, [insuranceProvider, state])

  const elementIntoView = (id, delay, block) => {
    setTimeout(() => {
      const element = document.getElementById(id)
      if (element)
        try {
          element.scrollIntoView({
            behavior: 'smooth',
            block: block || 'center',
          })
        } catch (e) {
          element.scrollIntoView(false)
        }
    }, delay || 1000)
  }

  const welcomeRender = () => {
    return (
      <>
        <img alt='' src={bgWelcome} className='bg-welcome' />
        <div className='content-sec' id='id-content-sec-welcome-new'>
          <div className='desktop-sec'>
            <img src={logoIcon} alt='' className='logo-icon' />
            <div className='title'>{i18n('Welcome to FitOn Care!')}</div>
            <div className='subtitle'>
              {i18n(
                "We're here to help you reach your health & wellness goals by pairing you with an experienced Registered Dietician for personalized nutrition counseling."
              )}
            </div>
          </div>
          <div className='mobile-sec'>
            <img src={logoIcon} alt='' className='logo-icon' />
            <img alt='' src={bgWelcome} className='bg-mobile-welcome' />
            <div className='title'>
              {i18n('Nutrition Counseling - Personalized just for you! ')}
            </div>
          </div>
          <div className='answer-title'>
            Answer the following to check eligibility
          </div>
          <div className='answer-sec'>
            <DropdownSelect
              {...{
                placeholder: i18n('Select state'),
                options: stateOptions,
                setValue: (value) => {
                  const currentOption = stateOptions.find(
                    (option) => option.id === value
                  )
                  setState(currentOption)
                },
                value: state && state.id,
                className: `${isMobile() ? 'm-t-8' : 'm-t-0'}`,
                render: (
                  <>
                    {stateOptions.map((option, index) => (
                      <Option
                        className='state-item'
                        value={option.id}
                        key={index}
                      >
                        {option.name}
                      </Option>
                    ))}
                  </>
                ),
              }}
            />
            {!_.isEmpty(state) && (
              <DropdownSelectWithOpen
                {...{
                  placeholder: i18n('Select your insurance provider'),
                  value:
                    insuranceProvider &&
                    (insuranceProvider.name === 'Other'
                      ? insuranceProvider.otherText || insuranceProvider.name
                      : insuranceProvider.name),
                  className: `${isMobile() ? 'm-t-8' : 'm-t-18'}`,
                  open: providerOptionOpen,
                  dropdownRender: () => (
                    <>
                      {insuranceProviders.map((item, index) => (
                        <div key={index}>
                          {item.name === 'Other' ? (
                            <CareSelectButton
                              {...{
                                selected: insuranceProvider?.id === item.id,
                                setSelected: () => {
                                  setInsuranceProvider({
                                    ...item,
                                    otherText: insuranceProvider?.otherText,
                                  })
                                  elementIntoView(
                                    'id-insurance-bottom',
                                    700,
                                    'start'
                                  )
                                },
                                text: insuranceProvider?.otherText || item.name,
                                className: classnames(
                                  'left-align custom-item',
                                  {
                                    active: insuranceProvider?.id === item.id,
                                  }
                                ),
                                showInput: insuranceProvider?.name === 'Other',
                                placeholder: i18n('Please specify'),
                                inputText: insuranceProvider?.otherText || '',
                                maxLength: 50,
                                setInputText: (value) => {
                                  const regex = /^[a-zA-Z0-9\s]*$/
                                  if (regex.test(value)) {
                                    setInsuranceProvider({
                                      ...insuranceProvider,
                                      otherText: value,
                                    })
                                  }
                                },
                                onSubmit: () => {
                                  setInsuranceProvider({
                                    ...item,
                                    otherText: insuranceProvider?.otherText,
                                  })
                                  setProviderOptionOpen(false)
                                },
                              }}
                            />
                          ) : (
                            <div
                              className={classnames('custom-item', {
                                active: insuranceProvider?.id === item.id,
                              })}
                              onClick={() => {
                                const currentOption = insuranceProviders.find(
                                  (option) => option.id === item.id
                                )
                                setInsuranceProvider(currentOption)
                                setProviderOptionOpen(false)
                              }}
                            >
                              <div className='normal-item'>{item.name}</div>
                            </div>
                          )}
                        </div>
                      ))}
                      <div
                        id='id-insurance-bottom'
                        className='bottom-div'
                      ></div>
                    </>
                  ),
                  onDropdownVisibleChange: (visible) => {
                    setProviderOptionOpen(visible)
                  },
                }}
              />
            )}
          </div>
          <Button
            className='btn-primary full-width-btn check-btn-desktop'
            onClick={onCheckClick}
            disabled={!isCheckEnable || isLoading}
          >
            {i18n('Check')}
          </Button>
        </div>
        <Button
          className='btn-primary full-width-btn check-btn-mobile'
          onClick={onCheckClick}
          disabled={!isCheckEnable || isLoading}
        >
          {i18n('Check')}
        </Button>
      </>
    )
  }

  const qualifiedRender = () => {
    return (
      <>
        <img alt='' src={bgWelcome} className='bg-welcome' />
        <div className='content-sec' style={{ minHeight: '518px' }}>
          <div className='desktop-sec'>
            <img src={logoIcon} alt='' className='logo-icon' />
            <div className='title'>
              {i18n(`Congrats! You're qualified for FitOn Care`)}
            </div>
          </div>
          <div className='mobile-sec'>
            <img src={logoIcon} alt='' className='logo-icon' />
            <img alt='' src={bgWelcome} className='bg-mobile-welcome' />
            <div className='title'>
              {i18n(`Congrats! You're qualified for FitOn Care`)}
            </div>
          </div>
          <Lottie
            animationData={checkMarkJson}
            loop={false}
            className='red-check-mark'
          />
          <div
            className='subtitle bottom-info'
            style={{ marginBottom: '40px' }}
          >
            {i18n(
              `Your personalized nutrition counseling is just a few more steps away. We'll need some more information to get you setup with an experienced Registered Dietician.`
            )}
          </div>
        </div>
      </>
    )
  }

  return (
    <div className='welcome-new'>
      {isQualified ? qualifiedRender() : welcomeRender()}
    </div>
  )
}

export default WelcomeNew
