import React, { useCallback, useEffect, useState } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import {
  useStripe,
  useElements,
  Elements,
  CardElement,
} from '@stripe/react-stripe-js'
import { Button } from 'antd'
import { __striprKey } from 'config'
import ContentHeader from './ContentHeader'
import { useI18n } from 'contexts/AuthContext'
import { useCardPay } from 'hooks/careHooks'
import { ScreenKey } from './util'
import { setBrazeUserAttribute } from 'utils/brazeInit'
import { logAmplitudeEvent } from 'utils/amplitudeInit'
import { getProductsPromise } from 'apis/FitOnCareAPI'
import _ from 'lodash'

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// const stripePromise = loadStripe(__striprKey, { apiVersion: '2020-08-27' })

const Payment = (props) => {
  const { i18n } = useI18n()
  const [loading, setLoading] = useState(false)
  const [product, setProduct] = useState({})

  const stripe = useStripe()
  const elements = useElements()

  const { cardStyle, handleCardChange, handleCardSubmit, isComplete } =
    useCardPay({
      setLoading,
      stripe,
      elements,
      product,
      onPurchaseFailure: () => {},
      onPurchaseSuccess: (paymentMethod) => {
        logAmplitudeEvent('Payment: Add Card - Success', { Type: 'Self Pay' })
        props.setPaymentMethod(paymentMethod)
        props.setProduct(product)
        // logAmplitudeEvent('Payment: Purchase - Success')
        props.setCurrentKey(ScreenKey.SCHEDULED_TIME)
      },
    })

  const getProduct = useCallback(async () => {
    const resp = await getProductsPromise()
    if (!_.isEmpty(resp?.data?.products)) {
      const _product = resp.data.products[0]
      setProduct(_product)
      logAmplitudeEvent('Screen View: Onboarding - Payment - Add Card', {
        Product: 'Introductory RD Session',
        Duration: 50,
        Amount: _product.price,
        Type: 'Self Pay',
        Error: props.cacheInfo?.creditCardError ? 'Credit Card' : '',
      })
    }
  }, [])

  useEffect(() => {
    getProduct()
  }, [getProduct])

  return (
    <>
      <div className='product-info'>
        <div className='off-tag'>{i18n('50% OFF')}</div>
        <div className='left-sec'>
          <div className='appointment-name'>{product.name}</div>
          <div className='appointment-time' hidden>
            (50 mins)
          </div>
        </div>
        <div className='right-sec'>
          <div className='origin-price'>{`$${
            Number(product.price || 0) * 2
          }`}</div>
          <div className='current-price'>{`$${product.price || 0}`}</div>
        </div>
      </div>
      <div className='card-info-sec'>
        <CardElement
          id='card-element'
          options={cardStyle}
          onChange={handleCardChange}
          onReady={(e) => {}}
        />
      </div>
      <Button
        className='btn-primary full-width-btn font-dark m-t-28'
        style={{ width: '100%' }}
        onClick={(e) => {
          handleCardSubmit(e)
          setBrazeUserAttribute('Credit Card', true)
        }}
        disabled={!isComplete || loading}
      >
        {i18n('Save')}
      </Button>
    </>
  )
}

const CashPay = (props) => {
  const { i18n } = useI18n()

  return (
    <div className='info-sec'>
      <ContentHeader {...props} />
      <div className='info-content'>
        <div className='title'>
          {i18n('Enter your payment details to schedule your session.')}
        </div>
        <div className='subtitle' style={{ marginBottom: '28px' }}>
          {i18n(
            `Your card won't be charged until you select & confirm your appointment.`
          )}
        </div>
        <Elements
          stripe={loadStripe(__striprKey, { apiVersion: '2020-08-27' })}
        >
          <Payment {...props} />
        </Elements>
      </div>
    </div>
  )
}

export default CashPay
