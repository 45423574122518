import React, { useEffect } from 'react'
import ContentHeader from './ContentHeader'
import {
  useI18n,
  usePersonalInfo,
  useTalkAboutCoverage,
} from 'contexts/AuthContext'
import { Button } from 'antd'
import hourGlassIcon from './img/ic-hourglass.svg'
import { ScreenKey } from './util'
import { logAmplitudeEvent } from 'utils/amplitudeInit'
import { isMobile } from 'utils/basic'
import { logBrazeEvent } from 'utils/brazeInit'

const InsuranceNotAccepted = (props) => {
  const { i18n } = useI18n()
  const { talkAboutCoverage } = useTalkAboutCoverage()
  const { personalInfo } = usePersonalInfo()

  useEffect(() => {
    const { state } = personalInfo
    logBrazeEvent('User Denied: Insurance')
    logAmplitudeEvent('Screen View: Onboarding - Not Available', {
      Reason: 'Insurance Provider',
      Detail: `${talkAboutCoverage.name}${
        talkAboutCoverage.name === 'Other'
          ? ' - ' + talkAboutCoverage.otherText
          : ''
      }${props.userStatus === 7 ? ' - No PCP' : ''}`,
      State: state?.name,
    })
  }, [talkAboutCoverage, personalInfo])

  return (
    <div className='info-sec'>
      <ContentHeader {...props} />
      <div className='info-content'>
        <img src={hourGlassIcon} alt='' className='top-icon' />
        <div className='title'>{i18n('Coming soon!')}</div>
        <div
          className='subtitle'
          style={{ marginBottom: '10px', marginTop: '17px' }}
        >
          {isMobile()
            ? i18n(
                "Unfortunately, we don't accept your plan... yet! We will email you as soon as it becomes available. {0}{1} In the meantime, we offer a limited time discount for our patients when you pay out-of-pocket with your credit card.",
                <br />,
                <br />
              )
            : i18n(
                "Unfortunately, we don't accept your plan... yet! We will email you as soon as it becomes available. In the meantime, we offer a limited time discount for our patients when you pay out-of-pocket with your credit card."
              )}
        </div>
        <Button
          className='btn-primary full-width-btn'
          onClick={() => {
            props.setCurrentKey(ScreenKey.CASH_PAY)
          }}
        >
          {i18n('Claim Offer - 50% OFF')}
        </Button>
        <Button
          className='btn-cancel full-width-btn font-dark m-t-24'
          onClick={() => {
            props.setCurrentKey(ScreenKey.INSURANCE_NOT_ACCEPTED_NO_CASH_PAY)
          }}
        >
          {i18n('No, thanks!')}
        </Button>
      </div>
    </div>
  )
}

export default InsuranceNotAccepted
