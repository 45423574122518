import { forwardRef, createElement } from 'react'
import clsx from 'classnames'

import { ReactComponent as female } from 'static/img/ic-female.svg'
import { ReactComponent as male } from 'static/img/ic-male.svg'
import { ReactComponent as other } from 'static/img/ic-other.svg'
import { ReactComponent as locationLine } from 'static/img/location-line.svg'

import classes from './index.module.scss'
const { styles, spin } = classes
const IconMap = {
  female,
  male,
  other,
  'location-line': locationLine,
}

const FitOnIcon = forwardRef((props, ref) => {
  if (!IconMap[props.type]) return null
  return createElement(IconMap[props.type], {
    ...props,
    ref,
    className: clsx(
      styles,
      'fiton-icon',
      { [spin]: props.spin },
      props.className
    ),
  })
})

export default FitOnIcon
