import React, { Suspense } from 'react'
import { Routes, Route } from 'react-router-dom'
import { lazyImport } from 'utils/basic.js'
import LoadingView from 'components/LoadingView'
const Care = lazyImport(() => import('views/Care'))

const AppRoutes = () => {
  return (
    <Suspense fallback={<LoadingView />}>
      <Routes>
        <Route path='/' element={<Care />} />
        <Route element={<Care />} />
      </Routes>
    </Suspense>
  )
}

export default AppRoutes
