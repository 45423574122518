import React, { useState } from 'react'
import classnames from 'classnames'
import _ from 'lodash'
import { useI18n } from 'contexts/AuthContext'
import './index.module.scss'
import FitOnIcon from 'components/FitOnIcon/index'

export const ThreeOptionSelect = ({ options, value, onChange, className }) => {
  if (options?.length !== 3) return null
  return (
    <div
      className={classnames(
        'three-options-section',
        className,
        `active-${_.findIndex(options, (o) => o.value === value) + 1}`
      )}
    >
      {options.map((item) => (
        <div
          className={classnames('gender-item', {
            active: value === item.value,
          })}
          onClick={() => onChange(item.value)}
          key={item.value}
        >
          {item.icon && <FitOnIcon type={item.icon} />}
          {item.title}
        </div>
      ))}
    </div>
  )
}

export const SwitchSelect = (props) => {
  const [loading, setLoading] = useState(false)
  const { value, onChange, className, options } = props
  const { i18n } = useI18n()

  if (_.isEmpty(options)) return null
  if (options[0].value !== value && options[1].value !== value) return null

  const asycChange = (value) => async () => {
    if (loading) return
    setLoading(true)
    await onChange(value)
    setLoading(false)
  }

  return (
    <div
      className={classnames(
        className,
        'gender-select-section',
        'normal',
        'switch-select',
        `active-${options[0].value === value ? 2 : 1}`,
        { loading }
      )}
    >
      {options.map((option) => (
        <div
          key={option.value}
          className={classnames('gender-item', {
            active: option.value === value,
          })}
          onClick={asycChange(option.value)}
        >
          {option.icon && <FitOnIcon type={option.icon} />}
          {i18n(option.title)}
        </div>
      ))}
    </div>
  )
}
