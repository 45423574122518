import React, { useCallback, useEffect, useState } from 'react'
import ContentHeader from './ContentHeader'
import { useI18n, useTalkAboutCoverage } from 'contexts/AuthContext'
import { Checkbox, Button, Select } from 'antd'
import DropdownSelect from 'components/DropdownSelect/index'
import CareInput from 'components/CareInput/index'
import { useCarePersonInfo } from 'hooks/careHooks'
import { ScreenKey } from './util'
import { getHealthieUserId } from 'utils/authLocalStorage'
import { logAmplitudeEvent } from 'utils/amplitudeInit'
import { formatPhoneNumber, isMobile, showNotification } from 'utils/basic'
import {
  emailMagicLinkPromise,
  providerSupportPromise,
} from 'apis/FitOnCareAPI'
import _ from 'lodash'
import { logBrazeEvent } from 'utils/brazeInit'
const { Option } = Select

const PersonalInfo = (props) => {
  const { i18n } = useI18n()
  const { supportForNewWelcome } = props

  const [emailOnFocus, setEmailOnFocus] = useState()
  const [phoneOnFocus, setPhoneOnFocus] = useState()
  const { talkAboutCoverage } = useTalkAboutCoverage()
  const {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    preferredFirstName,
    setPreferredFirstName,
    state,
    setState,
    phone,
    setPhone,
    isPhoneValid,
    isEmailValid,
    email,
    setEmail,
    isPolicyChecked,
    setIsPolicyChecked,
    isTelehealthConsent,
    setIsTelehealthConsent,
    isNextButtonEnable,
    uploadPersonalInfo,
    stateOptions,
    getUserInfo,
    getStateOptions,
  } = useCarePersonInfo({
    utmCampaign: props.utmCampaign,
    utmSource: props.utmSource,
    utmContent: props.utmContent,
  })

  useEffect(() => {
    if (props.email) {
      setEmail(props.email)
    }
  }, [props.email])

  useEffect(() => {
    const userId = getHealthieUserId()
    if (userId) {
      getUserInfo({ id: userId, include_tags: true })
    } else {
      getStateOptions()
    }
    const params = {}
    if (props.utmSource) {
      params.utm_source = props.utmSource
    }
    if (props.utmCampaign) {
      params.utm_campaign = props.utmCampaign
    }
    if (props.utmContent) {
      params.utm_content = props.utmContent
    }
    if (!_.isEmpty(supportForNewWelcome)) {
      params.Variant = 'Prescreen'
      params.Qualified = supportForNewWelcome.isValid
    }
    if (!_.isEmpty(state)) {
      params.State = state.name
    }
    logAmplitudeEvent('Screen View: Onboarding - About', params)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const sendEmail = async () => {
    const resp = await emailMagicLinkPromise({
      email: email,
    })
    if (resp.code !== 200) {
      showNotification({
        type: 'error',
        title: 'Error',
        message: resp.msg,
      })
    } else {
      props.setCurrentKey(ScreenKey.VERIFY_EMAIL)
    }
  }

  const checkProviderSupported = useCallback(async () => {
    const providerSupport = await providerSupportPromise({
      stateId: state.id,
      insuranceProviderId: talkAboutCoverage.id,
    })
    return { providerSupport: providerSupport.accept }
  }, [state, talkAboutCoverage])

  return (
    <div className='info-sec'>
      <ContentHeader {...props} />
      <div className='info-content'>
        <div className='title'>{i18n('Tell us a bit about yourself')}</div>
        <div className='subtitle'>
          {i18n('Provide your contact information')}
        </div>
        <div className='name-sec'>
          <CareInput
            {...{
              inputValue: firstName,
              setInputValue: setFirstName,
              placeholder: i18n(`Legal First Name`),
            }}
          />
          <CareInput
            {...{
              inputValue: lastName,
              setInputValue: setLastName,
              placeholder: i18n(`Legal Last Name`),
            }}
          />
        </div>
        <CareInput
          {...{
            inputValue: preferredFirstName,
            setInputValue: setPreferredFirstName,
            placeholder: i18n(`Preferred First Name (optional)`),
            className: `${isMobile() ? 'm-t-8' : 'm-t-18'}`,
          }}
        />
        <DropdownSelect
          {...{
            placeholder: i18n('State'),
            options: stateOptions,
            setValue: (value) => {
              const currentOption = stateOptions.find(
                (option) => option.id === value
              )
              setState(currentOption)
            },
            value: state && state.id,
            className: `${isMobile() ? 'm-t-8' : 'm-t-18'}`,
            render: (
              <>
                {stateOptions.map((option, index) => (
                  <Option className='state-item' value={option.id} key={index}>
                    {option.name}
                  </Option>
                ))}
              </>
            ),
          }}
        />
        <CareInput
          {...{
            inputValue: (phone || '').replace('+1 ', ''),
            setInputValue: (value) => {
              if (value) {
                const formattedPhoneNumber = formatPhoneNumber(value)
                setPhone('+1 ' + formattedPhoneNumber)
              } else {
                setPhone('')
              }
            },
            placeholder: i18n(`000 000 0000`),
            prefix: '+1',
            isValid: !phone || phoneOnFocus || isPhoneValid,
            errorMessage: i18n('Invalid Phone'),
            className: `${isMobile() ? 'm-t-8' : 'm-t-18'}`,
            type: 'text',
            pattern: '\\d*',
            onFocus: () => {
              setPhoneOnFocus(true)
            },
            onBlur: () => {
              setPhoneOnFocus(false)
            },
          }}
        />
        <CareInput
          {...{
            inputValue: email,
            setInputValue: (value) => {
              setEmail(value)
            },
            placeholder: i18n(`Email`),
            isValid: !email || emailOnFocus || isEmailValid,
            errorMessage: i18n('Invalid Email'),
            className: `${isMobile() ? 'm-t-8' : 'm-t-18'}`,
            type: 'email',
            onFocus: () => {
              setEmailOnFocus(true)
            },
            onBlur: () => {
              setEmailOnFocus(false)
            },
          }}
        />

        <div className='policy-tip'>
          {i18n(
            'By checking the boxes below, you agree to the following policies and agreements:'
          )}
        </div>
        <div className='policy-item'>
          <Checkbox
            checked={isPolicyChecked}
            onChange={(e) => setIsPolicyChecked(e.target.checked)}
          />
          <p className='terms'>
            {i18n(
              `I have read and agree to FitOn Care's {0} & {1}`,
              <a
                className='hover-link gradient-font-2 underline-link'
                target='_blank'
                rel='noopener noreferrer'
                href='https://fitoncare.com/privacy'
                key='privacy-policy'
              >
                {i18n(`Privacy Policy`)}
              </a>,
              <a
                className='hover-link gradient-font-2 underline-link'
                target='_blank'
                rel='noopener noreferrer'
                href='https://fitoncare.com/terms'
                key='terms-of-use'
              >
                {i18n(`Terms of Use`)}.
              </a>
            )}
          </p>
        </div>
        <div className='policy-item'>
          <Checkbox
            checked={isTelehealthConsent}
            onChange={(e) => setIsTelehealthConsent(e.target.checked)}
          />
          <p className='terms'>
            {i18n(
              `I have read and agree to FitOn Care's {0}`,
              <a
                className='hover-link gradient-font-2 underline-link'
                target='_blank'
                rel='noopener noreferrer'
                href={
                  state?.code === 'CA'
                    ? 'https://fitoncare.com/telehealth-consent-ca'
                    : 'https://fitoncare.com/telehealth-consent'
                }
                key='telehealth-consent'
              >
                {i18n(`Telehealth Consent`)}.
              </a>
            )}
          </p>
        </div>
        <Button
          className='btn-primary full-width-btn'
          style={isMobile() ? { width: '100%' } : {}}
          onClick={() => {
            logAmplitudeEvent('Click: Onboarding - About', {
              State: state.name,
            })
            uploadPersonalInfo({
              onSuccess: async () => {
                logBrazeEvent('Onboarding Start')
                if (!state.available) {
                  props.setCurrentKey(ScreenKey.COMING_SOON)
                } else if (!_.isEmpty(supportForNewWelcome)) {
                  const { providerSupport } = await checkProviderSupported()
                  if (!providerSupport) {
                    props.setCurrentKey(ScreenKey.INSURANCE_NOT_ACCEPTED)
                  } else {
                    logBrazeEvent('User Approved')
                    props.setCurrentKey(ScreenKey.SURVEY)
                  }
                } else {
                  if (
                    state.available &&
                    supportForNewWelcome?.providerSupport
                  ) {
                    logBrazeEvent('User Approved')
                  }
                  props.setCurrentKey(ScreenKey.SURVEY)
                }
              },
              onVerifyEmail: () => {
                sendEmail()
              },
            })
          }}
          disabled={!isNextButtonEnable}
        >
          {i18n('Next')}
        </Button>
      </div>
    </div>
  )
}

export default PersonalInfo
