import React from 'react'
import classnames from 'classnames'
import { Modal } from 'antd'

const FOModal = ({
  visible,
  setVisible,
  children,
  className,
  center,
  width,
  noAnimation,
}) => {
  return (
    <Modal
      title={null}
      footer={null}
      closable={false}
      open={visible}
      className={classnames('common-modal', className, {
        'no-animation-modal': noAnimation,
      })}
      width={width}
      centered={center}
      maskTransitionName=''
      transitionName=''
    >
      <div onClick={(e) => e.stopPropagation()}>{children}</div>
    </Modal>
  )
}

export default FOModal
