import React from 'react'
import backIcon from './img/back.svg'
import logoIcon from 'static/img/fiton-care-logo.png'

const ContentHeader = (props) => {
  const { onBack, hideBack = false } = props
  return (
    <div className='content-header'>
      <img
        src={backIcon}
        alt=''
        className='back-icon'
        onClick={onBack}
        hidden={hideBack}
      />
      <img src={logoIcon} alt='' className='logo-icon' />
    </div>
  )
}

export default ContentHeader
