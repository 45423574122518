import React, { useCallback, useEffect, useMemo, useState } from 'react'
import ContentHeader from './ContentHeader'
import {
  useI18n,
  usePersonalInfo,
  useTalkAboutCoverage,
} from 'contexts/AuthContext'
import { Button } from 'antd'
import emailIcon from './img/ic-email.svg'
import CareInput from 'components/CareInput/index'
import validator from 'validator'
import { logAmplitudeEvent } from 'utils/amplitudeInit'
import { uploadUnqualifiedPromise } from 'apis/FitOnCareAPI'
import { UploadSelectionOptionType } from './util'
import _ from 'lodash'

const WelcomeNewNotQualified = (props) => {
  const { i18n } = useI18n()
  const { supportForNewWelcome } = props
  const [emailOnFocus, setEmailOnFocus] = useState()
  const [email, setEmail] = useState(props.email || null)
  const [isLoading, setIsLoading] = useState(false)
  const { talkAboutCoverage } = useTalkAboutCoverage()
  const { personalInfo } = usePersonalInfo()

  const eventParams = useCallback(() => {
    const { state } = personalInfo
    let reason = ''
    let detail = ''
    let stateName = ''
    if (!state.available) {
      reason = 'State'
    } else if (!supportForNewWelcome.providerSupport) {
      reason = 'Insurance Provider'
      if (talkAboutCoverage.otherText) {
        detail = `Other - ${talkAboutCoverage.otherText}`
      } else {
        detail = talkAboutCoverage.name
      }
    }
    stateName = state?.name
    return { reason, detail, stateName }
  }, [personalInfo, supportForNewWelcome, talkAboutCoverage])

  useEffect(() => {
    const { reason, detail, stateName } = eventParams()
    logAmplitudeEvent('Screen View: Onboarding - Not Available', {
      Reason: reason,
      Detail: detail,
      State: stateName,
    })
  }, [eventParams])
  useEffect(() => {
    props.setRemoveEventListener(true)
  }, [props])

  const isEmailValid = useMemo(() => {
    return validator.isEmail(email ?? '')
  }, [email])

  const onSubmit = async () => {
    setIsLoading(true)
    const { reason, detail, stateName } = eventParams()
    const { state } = personalInfo
    logAmplitudeEvent('Click: Onboarding - Not Available - Email Entry', {
      Reason: reason,
      Detail: detail,
      Email: email,
      State: stateName,
    })
    const selectOptionArray = []
    if (!_.isEmpty(talkAboutCoverage)) {
      selectOptionArray.push({
        optionType: UploadSelectionOptionType.INSURANCE_PROVIDER_OPTION,
        selectedOptionId: talkAboutCoverage.id,
        otherInputText: talkAboutCoverage.otherText,
      })
    }
    await uploadUnqualifiedPromise({
      email,
      stateId: state.id,
      selectOptionArray,
    })
    window.location.href = 'http://fitoncare.com'
  }

  return (
    <div className='info-sec'>
      <ContentHeader {...props} />
      <div className='info-content'>
        <img src={emailIcon} alt='' className='top-icon' />
        <div className='title'>{i18n('Coming soon!')}</div>
        <div
          className='subtitle'
          style={{
            margin: '17px auto 40px',
            lineHeight: '24px',
          }}
        >
          {i18n(
            `Unfortunately, you do not currently qualify for FitOn Care, but we'll email you as soon as that changes. Enter your email to get notified.`
          )}
        </div>
        <CareInput
          {...{
            inputValue: email,
            setInputValue: (value) => {
              setEmail(value)
            },
            placeholder: i18n(`Email`),
            isValid: !email || emailOnFocus || isEmailValid,
            errorMessage: i18n('Invalid Email'),
            type: 'email',
            onFocus: () => {
              setEmailOnFocus(true)
            },
            onBlur: () => {
              setEmailOnFocus(false)
            },
          }}
        />
        <Button
          className='btn-primary full-width-btn m-t-24 bottom-btn'
          style={{ width: '100%' }}
          onClick={onSubmit}
          disabled={!isEmailValid || isLoading}
        >
          {i18n('ok')}
        </Button>
      </div>
    </div>
  )
}

export default WelcomeNewNotQualified
