import React from 'react'
import { Layout } from 'antd'
import classnames from 'classnames'
import AppRoutes from './routes'
import { AuthContextProvider } from 'contexts/AuthContext'
import usePagePathType from 'hooks/usePagePathType'
import './App.scss'
import withRouter from 'hooks/withRouter'
const { Content } = Layout

const App = (props) => {
  const headerFooterOption = usePagePathType(props.router.location.pathname)

  return (
    <Layout
      className={classnames('styles', {
        full: !headerFooterOption.header,
        'no-margin': !headerFooterOption.fullWidth,
      })}
    >
      <Content
        className={classnames('main', {
          'has-header': headerFooterOption.header,
        })}
      >
        <AppRoutes />
      </Content>
    </Layout>
  )
}

export default withRouter(AuthContextProvider(App))
