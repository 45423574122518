import React, { useCallback, useEffect, useState } from 'react'
import { useI18n } from 'contexts/AuthContext'
import { Button } from 'antd'
import ContentHeader from './ContentHeader'
import { useQuery } from '@apollo/client'
import {
  APPOINTMENTS_STRING,
  GET_ORGANIZATION,
  WIDGET_STEPS,
  useHealthieAPIs,
} from 'apis/HealthieApiClient'
import {
  getHealthieProviderId,
  getHealthieUserId,
} from 'utils/authLocalStorage'
import {
  ScheduleAppointmentTypes,
  ScheduleDateTimeSelector,
} from 'components/ScheduleDatePicker/index'
import {
  getHealthieRequest,
  useCardPay,
  useCareCompleteBooking,
} from 'hooks/careHooks'
import ConfirmedAppointment from './ConfirmedAppointment'
import { logAmplitudeEvent } from 'utils/amplitudeInit'
import { ScreenKey } from './util'
import _ from 'lodash'
import { addDays } from 'date-fns'
import { format } from 'date-fns'
import LoadingDialog from 'components/LoadingDialog/index'
import { updateUserPromise } from 'apis/FitOnCareAPI'
import { logBrazeEvent } from 'utils/brazeInit'
import { isProduction } from 'apis/formatUrl'

const ScheduledTime = (props) => {
  const { i18n } = useI18n()
  const providerId = getHealthieProviderId()
  const { completeBookingAppointment, personalInfo, applyHealthieTags } =
    useCareCompleteBooking()
  const { availableSlotsForRange } = useHealthieAPIs()

  const [selectedAppointmentType, setAppointmentType] = useState()
  const [selectedProviderIds] = useState([providerId])
  const [selectedContactType, setContactType] = useState()
  const [stepIndex, setStepIndex] = useState(0)
  const [selectedSlot, setSelectedSlot] = useState()
  const [bookedAppointment, setBookedAppointment] = useState()
  const [payLoading, setPayLoading] = useState(false)
  const [bookLoading, setBookPayLoading] = useState(false)
  const [availableInit, setAvailableInit] = useState(true)
  const [providerIds, setProviderIds] = useState(true)
  const [loadingVisible, setLoadingVisible] = useState(false)
  const [appointmentsCount, setAppointmentsCount] = useState(0)
  const [currentStep, setCurrentStep] = useState()
  const [steps, setSteps] = useState([])

  const {
    loading: orgLoading,
    data: orgData,
    error: orgError,
    refetch: orgFetch,
  } = useQuery(GET_ORGANIZATION, {
    variables: {
      provider_id: providerId,
      state_licenses: [],
    },
  })

  const getAppointmentCount = useCallback(async () => {
    const { data } = await getHealthieRequest({
      queryString: APPOINTMENTS_STRING,
      variables: {
        user_id: getHealthieUserId(),
        sort_by: 'date_asc',
        should_paginate: true,
        with_all_statuses: true,
      },
      operationName: 'appointments',
    })
    let appointmentsCount = 0
    if (data) {
      appointmentsCount = data.appointmentsCount || 0
      setAppointmentsCount(appointmentsCount)
    }
    if (appointmentsCount === 0) {
      logBrazeEvent('Schedule Appointment Start')
    }
  }, [])

  useEffect(() => {
    getAppointmentCount()
  }, [getAppointmentCount])

  useEffect(() => {
    if (!orgLoading && orgData) {
      let filteredProvider = _.filter(
        orgData.organization?.providers || [],
        (item) => {
          return (
            !_.isEmpty(item.state_licenses) &&
            _.find(item.state_licenses, {
              state: personalInfo.state?.code || '',
            }) &&
            item.has_future_availabilities
          )
        }
      )
      filteredProvider = _.map(filteredProvider || [], (item) => item.id)
      setProviderIds(filteredProvider)
    }
  }, [orgLoading, orgData])

  const { onCardPay } = useCardPay({
    setLoading: setPayLoading,
    product: props.product,
    onPurchaseFailure: () => {
      setPayLoading(false)
      props.setCacheInfo({ ...props.cacheInfo, creditCardError: true })
      props.setCurrentKey(ScreenKey.CASH_PAY)
    },
    onPurchaseSuccess: () => {
      logAmplitudeEvent('Payment: Purchase - Success', {
        Type: 'Self Pay',
      })
      setPayLoading(false)
      setBookPayLoading(true)
      completeBookingAppointment({
        slot: selectedSlot,
        selectedAppointmentType,
        selectedContactType,
        onSuccess: (appointment) => {
          setBookPayLoading(false)
          setBookedAppointment(appointment)
        },
        onFailure: () => {
          setBookPayLoading(false)
        },
      })
    },
  })

  useEffect(() => {
    if (payLoading || bookLoading) {
      setLoadingVisible(true)
    } else {
      setLoadingVisible(false)
    }
  }, [payLoading, bookLoading])

  const {
    loading,
    data,
    error: stepError,
    refetch: stepFetch,
  } = useQuery(WIDGET_STEPS, {
    variables: {
      locationString: `?dietitian_id=${providerId}`,
      is_for_web: true,
    },
  })

  useEffect(() => {
    if (!loading && data) {
      const steps = data?.embedWidgetSteps || []
      const index = _.findIndex(steps, (item) => item.id === 'select_appt_type')
      setSteps(steps)
      setStepIndex(index)
    }
  }, [data, loading])

  useEffect(() => {
    const _currentStep = steps[stepIndex]
    setCurrentStep(_currentStep)
  }, [steps, stepIndex])

  const setProviderEvents = useCallback(async () => {
    if (!orgLoading && orgData) {
      let filteredProvider = _.filter(
        orgData.organization?.providers || [],
        (item) => {
          return (
            !_.isEmpty(item.state_licenses) &&
            _.find(item.state_licenses, {
              state: personalInfo.state?.code || '',
            }) &&
            item.has_future_availabilities
          )
        }
      )
      filteredProvider = _.map(filteredProvider || [], (item) => item.id)
      const { data: slotData } = await availableSlotsForRange({
        variables: {
          org_level: true,
          timezone:
            Intl.DateTimeFormat().resolvedOptions().timeZone ||
            'America/New_York',
          appt_type_id: selectedAppointmentType.id,
          provider_id: providerId,
          provider_ids: filteredProvider,
          end_date: format(addDays(new Date(), 21), 'yyyy-MM-dd'),
          start_date: format(new Date(), 'yyyy-MM-dd'),
          clients_can_join_waitlist: true,
          licensed_in_state: '',
        },
      })

      const availableTimeSlots =
        (slotData && slotData.availableSlotsForRange?.length) || 0

      const rds = filteredProvider.length || 0
      logAmplitudeEvent('Screen View: Onboarding - Schedule', {
        'Available RDs': rds,
        'Available Time Slots': rds === 0 ? 0 : availableTimeSlots,
      })
      if (rds === 0 || availableTimeSlots === 0) {
        updateUserPromise({ userStatus: 8 })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAppointmentType, orgLoading, orgData])

  useEffect(() => {
    if (currentStep?.id === 'select_date_time' && availableInit) {
      setProviderEvents()
      setAvailableInit(false)
    }
  }, [currentStep, setProviderEvents, availableInit])

  const applyNewPatientTag = useCallback(() => {
    const { state } = personalInfo || {}
    if (state.code === 'CA') {
      applyHealthieTags({
        tagId: isProduction() ? '34025' : '3399',
      })
    } else if (state.code === 'NY') {
      applyHealthieTags({
        tagId: isProduction() ? '34026' : '3401',
      })
    } else {
      applyHealthieTags({
        tagId: isProduction() ? '34024' : '3400',
      })
    }
  }, [applyHealthieTags, personalInfo])

  useEffect(() => {
    if (appointmentsCount === 0 && bookedAppointment) {
      applyNewPatientTag()
      logBrazeEvent('Schedule Appointment End')
    }
  }, [bookedAppointment, appointmentsCount])

  if (loading || stepError) {
    return null
  }

  if (!currentStep) {
    return null
  }

  if (bookedAppointment) {
    props.setRemoveEventListener(true)
    return (
      <ConfirmedAppointment
        bookedAppointment={bookedAppointment}
        appointmentsCount={appointmentsCount}
        {...props}
      />
    )
  }

  const onBack = (e) => {
    if (stepIndex > 0) {
      setStepIndex(stepIndex - 1)
      if (stepIndex === 1) {
        setSelectedSlot('')
        setAvailableInit(true)
      }
    } else {
      props.onBack(e)
    }
  }

  return (
    <div className='info-sec'>
      <ContentHeader {...props} onBack={onBack} hideBack={true} />
      <div className='info-content'>
        <div className='title'>
          {i18n("Great! Now let's get you scheduled")}
        </div>
        <div className='subtitle' style={{ marginBottom: '32px' }}>
          {i18n(
            currentStep.id === 'select_appt_type' ||
              currentStep.id === 'select_date_time'
              ? 'Please choose a time for your first session'
              : 'Book an Appointment'
          )}
        </div>
        {currentStep.id === 'select_appt_type' ? (
          <ScheduleAppointmentTypes
            selectedAppointmentType={selectedAppointmentType}
            selectedContactType={selectedContactType}
            setContactType={setContactType}
            state={personalInfo.state?.code || ''}
            providerId={providerId}
            setAppointmentType={setAppointmentType}
            moveToNextStep={() => setStepIndex(stepIndex + 1)}
          />
        ) : currentStep.id === 'select_date_time' ? (
          <>
            <ScheduleDateTimeSelector
              selectedAppointmentType={selectedAppointmentType}
              providerId={!_.isEmpty(selectedProviderIds) ? providerId : ''}
              providerIds={providerIds}
              state={personalInfo.state?.code || ''}
              selectedSlot={selectedSlot}
              setSelectedSlot={setSelectedSlot}
              moveToNextStep={() => setStepIndex(stepIndex + 1)}
            />
            <Button
              className='btn-primary full-width-btn'
              onClick={() => {
                if (props.paymentMethod && props.product) {
                  setPayLoading(true)
                  onCardPay(props.paymentMethod)
                } else {
                  setBookPayLoading(true)
                  completeBookingAppointment({
                    slot: selectedSlot,
                    selectedAppointmentType,
                    selectedContactType,
                    onSuccess: (appointment) => {
                      setBookedAppointment(appointment)
                      setBookPayLoading(false)
                    },
                    onFailure: () => {
                      setBookPayLoading(false)
                    },
                  })
                }
              }}
              disabled={!selectedSlot || payLoading || bookLoading}
            >
              {i18n('Next')}
            </Button>
          </>
        ) : (
          <div></div>
        )}
      </div>
      <LoadingDialog
        visible={loadingVisible}
        setVisible={setLoadingVisible}
        center={true}
      />
    </div>
  )
}

export default ScheduledTime
