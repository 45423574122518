import React from 'react'
import { notification } from 'antd'
import moment from 'moment-timezone'
import UAParser from 'ua-parser-js'
import sha256 from 'crypto-js/sha256'
import { getUserEmail, getUserId } from 'utils/authLocalStorage'
import _ from 'lodash'
import { parse } from 'date-fns'

export const showNotification = ({ type, title, message }) => {
  notification[type]({
    message: title,
    description: message,
  })
}

export const elementIntoView = (id, delay, block) => {
  setTimeout(() => {
    const element = document.getElementById(id)
    if (element)
      try {
        element.scrollIntoView({ behavior: 'smooth', block: block || 'center' })
      } catch (e) {
        element.scrollIntoView(false)
      }
  }, delay || 1000)
}

export const formatFullName = (name) => {
  if (!name) return ''
  const [f, l] = name.split(' ')
  if (!l) return f
  return `${f} ${l[0]}.`
}

export const getFirstName = (name) => {
  if (!name) return ''
  const f = name.split(' ')[0]
  return f
}

export const numberToLocalString = (num = 0) => {
  return (num || 0).toLocaleString()
}

export const checkFBLoginStatus = () =>
  window.FB &&
  window.FB.getLoginStatus(function (response) {
    console.log('FB resp: ', response)
  })

const onGoogleSignIn = (cb) => (googleUser) => {
  if (!window.googleAuth.isSignedIn.get()) return
  try {
    var profile = {
      accessToken: googleUser.getAuthResponse().id_token,
      // accessToken: googleUser.getAuthResponse().access_token,
      userId: googleUser.getId(),
    }
    cb(profile)
  } catch (e) {
    console.warn(e)
  }
}

export const onClickGoogleLogin = (cb) => {
  const _refreshLogin = () => {
    // window.googleAuth.currentUser.listen(() => {})
    // window.googleAuth.signOut()
    // window.googleAuth.currentUser.listen(onGoogleSignIn(cb))
    window.googleAuth.signIn()
  }
  if (window.googleAuth) {
    _refreshLogin()
  } else {
    const gapi = window.gapi
    if (!gapi) return
    gapi.load('auth2', () => {
      gapi.auth2
        .init({
          client_id:
            '221214607499-sdron1v6lup784kf4te40tfk2nhopa5v.apps.googleusercontent.com',
          scope: 'profile email',
        })
        .then((googleAuth) => {
          window.googleAuth = googleAuth
          window.googleAuth.currentUser.listen(onGoogleSignIn(cb))
          _refreshLogin()
        })
    })
  }
}

export const formatAgeFromDOB = (dob) => {
  const _y = Math.floor(moment.duration(Date.now() - dob).years() / 10) * 10
  return _y ? _y + 's' : ''
}

export const lazyImport = (view) => {
  try {
    return React.lazy(() => retry(() => view()))
  } catch (e) {
    return null
  }
}

const retry = (fn, retriesLeft = 5, interval = 1000) => {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            reject(error)
          } else {
            retry(fn, retriesLeft - 1, interval).then(resolve, reject)
          }
        }, interval)
      })
  })
}

export const firstUpperCase = (str) => {
  return str.toLowerCase().replace(/( |^)[a-z]/g, (L) => L.toUpperCase())
}

export const isChrome = () => {
  return (
    !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime)
  )
}

export const generateAlphanumericPassword = () => {
  var result = ''
  result = Math.random().toString(36).slice(2)
  if (result.length < 10) {
    const length = 10 - result.length
    const chars =
      '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
    for (var i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)]
  } else if (result.length > 10) {
    result = result.slice(0, 10)
  }
  return result
}

export const getDeviceType = () => {
  const u = navigator.userAgent
  const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1
  if (isAndroid) {
    return 'Android'
  }
  const isIOS = /iPad|iPhone|iPod/.test(u) && !window.MSStream
  if (isIOS) {
    return 'iOS'
  }
  return 'Unknown'
}

export const isMobile = () => {
  const deviceType = getDeviceType()
  return deviceType === 'Android' || deviceType === 'iOS'
}

export const updateDataLayer = (source) => {
  if (window.dataLayer) {
    const userId = getUserId()
    const email = getUserEmail()
    let encryptedEmail = ''
    if (!_.isEmpty(email)) {
      encryptedEmail = sha256(email).toString()
    }
    window.dataLayer.push({
      FOuserId: userId || '',
      FOuserEmail: encryptedEmail || '',
    })
  }
}

export const toDate = (date) => {
  return parse(date, 'yyyy-MM-dd HH:mm:ss xx', new Date())
}

export const getBMI = (weight, height) => {
  return Math.round((weight / Math.pow(height, 2)) * 703 * 10) / 10
}

export const getPlaceDetailByGoogle = (placeId, cb) => {
  if (!window.google.maps) return
  const map = new window.google.maps.Map(document.getElementById('google-map'))
  const googleMapService =
    window.googleMapService ?? new window.google.maps.places.PlacesService(map)
  window.googleMapService = googleMapService
  var request = {
    placeId,
  }
  googleMapService.getDetails(request, cb)
}

export const getGymAddress = (gym = {}) => {
  const [studioAddress, city, stateAndPostalCode, country] =
    gym?.formatted_address?.split(', ') || []
  let [state, postalCode] = stateAndPostalCode?.split(' ') || []
  const getBackupAddress = (type, short = false) => {
    return (
      gym?.address_components?.find((c) => c?.types?.includes(type))?.[
        short ? 'short_name' : 'long_name'
      ] ?? ''
    )
  }

  return {
    formattedAddress: gym?.formatted_address?.includes(gym.name)
      ? gym?.formatted_address
      : `${gym?.formatted_address}`,
    studioName: gym.name,
    studioAddress: studioAddress ?? '',
    city: city ?? getBackupAddress('locality') ?? '',
    country: getBackupAddress('country', true) ?? country ?? '',
    state: state ?? getBackupAddress('administrative_area_level_1') ?? '',
    postalCode: postalCode ?? getBackupAddress('postal_code') ?? '',
  }
}

export const formatPhoneNumber = (input) => {
  // Remove all non-digit characters
  const phoneNumber = input.replace(/\D/g, '')
  // Format the phone number as (XXX) XXX-XXXX
  const formattedPhoneNumber = phoneNumber.replace(
    /(\d{3})(\d{3})(\d{4})/,
    '$1 $2 $3'
  )

  return formattedPhoneNumber
}
