import _ from 'lodash'
import ApiClient from './ApiClient'
const apiClient = new ApiClient()

export const getStateListPromise = () => {
  return apiClient.get('/state/all').then((resp) => Promise.resolve(resp?.data))
}

export const fetchPhysicianPromise = (searchText) => {
  return fetch(
    `https://clinicaltables.nlm.nih.gov/api/npi_org/v3/search?df=NPI,name.first,name.last,name.full,provider_type,addr_practice.line1,addr_practice.line2,addr_practice.city,addr_practice.state,addr_practice.zip,addr_practice.country,addr_practice.full,addr_practice.phone,addr_practice.fax&terms=${searchText}`,
    {
      method: 'GET',
    }
  )
    .then((response) => response.json())
    .then((data) => {
      return Promise.resolve(data)
    })
    .catch((error) => console.log(error))
}

export const getMetadataOptionsPromise = () => {
  return apiClient
    .get('/metadata/options')
    .then((resp) => Promise.resolve(resp?.data))
}

export const uploadSelectionPromise = ({
  optionType,
  selectedOptionId,
  otherInputText,
}) => {
  return apiClient
    .post('/user/upload-selection', {
      body: {
        optionType,
        selectedOptionId,
        otherInputText,
      },
    })
    .then((resp) => Promise.resolve(resp))
}

export const addUserPromise = ({
  firstName,
  lastName,
  preferredFirstName,
  email,
  countryCode,
  phoneNumber,
  stateId,
  healthieId,
  utmSource,
  utmCampaign,
  utmContent,
}) => {
  const newBody = {
    firstName,
    lastName,
    email,
    countryCode,
    phoneNumber,
    stateId,
    healthieId,
  }
  if (!_.isEmpty(preferredFirstName)) {
    newBody.preferredFirstName = preferredFirstName
  }
  if (
    !_.isEmpty(utmSource) &&
    !_.isEmpty(utmCampaign) &&
    !_.isEmpty(utmContent)
  ) {
    newBody.utm = {
      source: utmSource,
      campaign: utmCampaign,
      content: utmContent,
    }
  }
  return apiClient
    .post('/user', {
      body: newBody,
    })
    .then((resp) => Promise.resolve(resp))
}

export const providerSupportPromise = ({ stateId, insuranceProviderId }) => {
  return apiClient
    .post('/metadata/check/provider-support', {
      body: {
        stateId,
        insuranceProviderId,
      },
    })
    .then((resp) => Promise.resolve(resp?.data || resp))
}

export const checkUserExistsPromise = (email) => {
  return apiClient
    .post('/check/exists', {
      body: {
        email,
      },
    })
    .then((resp) => Promise.resolve(resp?.data || resp))
}

export function payStripePromise({
  userId,
  paymentIntentId,
  paymentMethodId,
  productId,
}) {
  return apiClient
    .post('/stripe/purchase', {
      body: _.omitBy(
        {
          userId,
          paymentIntentId,
          paymentMethodId,
          productId,
        },
        _.isNil
      ),
    })
    .then((resp) => {
      return Promise.resolve(resp)
    })
    .catch((e) => {
      return Promise.reject(e)
    })
}

export function getProductsPromise() {
  return apiClient
    .get('/products')
    .then((resp) => {
      return Promise.resolve(resp)
    })
    .catch((e) => {
      return Promise.reject(e)
    })
}

export function addStripeInfoPromise({ paymentMethodId }) {
  return apiClient
    .post('/stripe/attach/payment-method', {
      body: { paymentMethodId },
    })
    .then((resp) => {
      return Promise.resolve(resp)
    })
    .catch((e) => {
      return Promise.reject(e)
    })
}

export const updateUserPromise = (body) => {
  return apiClient
    .put('/user', {
      body,
    })
    .then((resp) => Promise.resolve(resp?.data || resp))
}

export const updateUserTagPromise = (body) => {
  return apiClient
    .post('/user/tags', {
      body,
    })
    .then((resp) => Promise.resolve(resp))
}

export const createReferringPhysicianPromise = (body) => {
  return apiClient
    .post('/user/referring/physician', {
      body,
    })
    .then((resp) => Promise.resolve(resp))
}

export const autoLoginPromise = (body) => {
  return apiClient
    .post('/user/auto-login', {
      body,
    })
    .then((resp) => Promise.resolve(resp))
}

export const forwardHealthiePromise = ({
  query,
  variables,
  operationName,
} = {}) => {
  return apiClient
    .post('/metadata/forward/healthie', {
      body: { query, variables, operationName },
    })
    .then((resp) => Promise.resolve(resp?.data || resp))
}

export function getUserProfilePromise() {
  return apiClient.get('/user/profile').then((resp) => {
    return Promise.resolve(resp)
  })
}

export const emailMagicLinkPromise = ({ email } = {}) => {
  return apiClient
    .post('/user/send/magic-link', {
      body: { email },
    })
    .then((resp) => Promise.resolve(resp))
}

export const verifyMagicLinkByDigitCodePromise = ({
  email,
  digitCode,
} = {}) => {
  return apiClient
    .post('/user/verify/magic-link-code', {
      body: { email, digitCode },
    })
    .then((resp) => Promise.resolve(resp))
}

export const uploadUnqualifiedPromise = ({
  email,
  stateId,
  selectOptionArray,
}) => {
  return apiClient
    .post('/user/upload-unqualified', {
      body: {
        email,
        stateId,
        selectOptionArray,
      },
    })
    .then((resp) => Promise.resolve(resp))
}

export const verifyMagicLinkPromise = (code) => {
  return apiClient
    .post('/user/verify/magic-link', {
      body: { code },
    })
    .then((resp) => Promise.resolve(resp))
}
