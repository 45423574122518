import React, { useEffect, useState } from 'react'
import ContentHeader from './ContentHeader'
import { useHormonalAutoimmune, useI18n, useSurvey } from 'contexts/AuthContext'
import CareSelectButton from 'components/CareSelectButton/index'
import { useMetadataOptions, useStep4Location } from 'hooks/careHooks'
import { uploadSelectionPromise } from 'apis/FitOnCareAPI'
import { UploadSelectionOptionType } from './util'
import { logAmplitudeEvent } from 'utils/amplitudeInit'
import { setBrazeProfile } from 'utils/brazeInit'
import _ from 'lodash'

const HormonalAutoImmune = (props) => {
  const { i18n } = useI18n()
  const { hormonalAutoimmune, setHormonalAutoimmune } = useHormonalAutoimmune()
  const { survey } = useSurvey()
  const { diseaseHelpOptions } = useMetadataOptions()
  const { getNextScreen } = useStep4Location()
  const [availableOptions, setAvailableOptions] = useState([])

  useEffect(() => {
    if (survey && diseaseHelpOptions) {
      const _options = _.filter(
        diseaseHelpOptions,
        (item) => item.primaryId === survey.id
      )
      setAvailableOptions(_options)
    }
  }, [survey, diseaseHelpOptions])

  const onSubmit = async (item) => {
    logAmplitudeEvent('Screen View: Onboarding - Condition Secondary', {
      Condition:
        item.name === 'Other' ? `Other - ${item.otherText}` : item.name,
    })
    uploadSelectionPromise({
      optionType: UploadSelectionOptionType.DISEASE_HELP_OPTION,
      selectedOptionId: item.id,
      otherInputText: item.otherText,
    })
    setTimeout(() => {
      setBrazeProfile({
        conditionPrimary: `${survey.name}: ${
          item.otherText ? `${item.name} - ${item.otherText}` : item.name
        }`,
      })
      const screenKey = getNextScreen({
        survey: survey,
        hormonalAutoimmune: item,
      })
      props.setCurrentKey(screenKey)
    }, 100)
  }

  return (
    <div className='info-sec'>
      <ContentHeader {...props} />
      <div className='info-content'>
        <div className='title'>
          {i18n(
            `Which ${survey?.name?.toLowerCase() || ''} could we help you with?`
          )}
        </div>
        <div className='subtitle' style={{ marginBottom: '32px' }}>
          {i18n('Select one option from the list below')}
        </div>
        {availableOptions.map((item, index) => (
          <div key={index}>
            {item.name === 'Other' ? (
              <CareSelectButton
                key={index}
                {...{
                  selected: hormonalAutoimmune.id === item.id,
                  setSelected: () => {
                    setHormonalAutoimmune({
                      ...item,
                      otherText: hormonalAutoimmune.otherText,
                    })
                  },
                  text: item.name,
                  className: 'left-align',
                  showInput: hormonalAutoimmune.name === 'Other',
                  placeholder: i18n('Please specify'),
                  inputText: hormonalAutoimmune.otherText || '',
                  maxLength: 50,
                  setInputText: (value) => {
                    const regex = /^[a-zA-Z0-9\s]*$/
                    if (regex.test(value)) {
                      setHormonalAutoimmune({
                        ...hormonalAutoimmune,
                        otherText: value,
                      })
                    }
                  },
                  onSubmit: () =>
                    onSubmit({
                      ...item,
                      otherText: hormonalAutoimmune.otherText,
                    }),
                }}
              />
            ) : (
              <CareSelectButton
                key={index}
                {...{
                  selected: hormonalAutoimmune.id === item.id,
                  setSelected: (value) => {
                    setHormonalAutoimmune({
                      ...item,
                      otherText: hormonalAutoimmune.otherText,
                    })
                    onSubmit(item)
                  },
                  text: item.name,
                  className: 'm-b-12 left-align',
                }}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default HormonalAutoImmune
