import React from 'react'
import ContentHeader from './ContentHeader'
import { useI18n, useTalkAboutCoverage } from 'contexts/AuthContext'
import CareSelectButton from 'components/CareSelectButton/index'
import { ScreenKey, UploadSelectionOptionType } from './util'
import { useMetadataOptions } from 'hooks/careHooks'
import {
  providerSupportPromise,
  uploadSelectionPromise,
} from 'apis/FitOnCareAPI'
import { logBrazeEvent, setBrazeUserAttribute } from 'utils/brazeInit'
import { logAmplitudeEvent, setAmplitudeOption } from 'utils/amplitudeInit'
import { isMobile } from 'utils/basic'
import { getStateId } from 'utils/authLocalStorage'
import _ from 'lodash'

const TalkAboutCoverage = (props) => {
  const { i18n } = useI18n()
  const { talkAboutCoverage, setTalkAboutCoverage } = useTalkAboutCoverage()
  const { insuranceProviders } = useMetadataOptions()
  const { supportForNewWelcome } = props

  const updateClient = async (item) => {
    uploadSelectionPromise({
      optionType: UploadSelectionOptionType.INSURANCE_PROVIDER_OPTION,
      selectedOptionId: item.id,
      otherInputText: item.otherText,
    })
    const providerSupport = await providerSupportPromise({
      stateId: getStateId(),
      insuranceProviderId: item.id,
    })
    if (providerSupport.accept) {
      if (_.isEmpty(supportForNewWelcome)) {
        logBrazeEvent('User Approved')
      }
      props.setCurrentKey(ScreenKey.INSURANCE_CARD_INFO)
    } else if (item.name === 'I will not be using insurance') {
      // Proceed to Step 11B “Cash Pay”
      props.setCurrentKey(ScreenKey.CASH_PAY)
    } else {
      // Proceed to Step 9A “Insurance Not Accepted”
      props.setUserStatus(6)
      props.setCurrentKey(ScreenKey.INSURANCE_NOT_ACCEPTED)
    }
    setBrazeUserAttribute(
      'Payment Primary',
      item.name === 'I will not be using insurance' ? 'Self Pay' : 'Insurance'
    )
    const provider = getInsuranceProvider(item)
    setBrazeUserAttribute('Insurance Provider', provider)
    logAmplitudeEvent('Screen View: Onboarding - Insurance - Provider', {
      Provider: provider,
    })
    setAmplitudeOption('Insurance - Provider', provider)
  }

  const getInsuranceProvider = (item) => {
    switch (item.id) {
      case 1:
        return 'Aetna'
      case 2:
        return 'BCBS'
      case 3:
        return 'Cigna'
      case 4:
        return 'United'
      case 5:
        return 'Self Pay'
      case 6:
        return `Other - ${item.otherText}`
      default:
        return ''
    }
  }

  return (
    <div className='info-sec'>
      <ContentHeader {...props} />
      <div className='info-content'>
        <div className='title'>{i18n("Let's talk about coverage")}</div>
        <div
          className='subtitle'
          style={isMobile() ? { marginBottom: '32px' } : {}}
        >
          {i18n('Who is your insurance provider?')}
        </div>
        {insuranceProviders.map((item, index) => (
          <div key={index}>
            {item.name === 'Other' ? (
              <CareSelectButton
                key={index}
                {...{
                  selected: talkAboutCoverage.id === item.id,
                  setSelected: () => {
                    setTalkAboutCoverage({
                      ...item,
                      otherText: talkAboutCoverage.otherText,
                    })
                  },
                  text: item.name,
                  className: `left-align ${isMobile() ? 'm-t-8' : 'm-t-18'}`,
                  showInput: talkAboutCoverage.name === 'Other',
                  placeholder: i18n('Please specify'),
                  inputText: talkAboutCoverage.otherText || '',
                  maxLength: 50,
                  setInputText: (value) => {
                    const regex = /^[a-zA-Z0-9\s]*$/
                    if (regex.test(value)) {
                      setTalkAboutCoverage({ ...item, otherText: value })
                    }
                  },
                  onSubmit: () =>
                    updateClient({
                      ...item,
                      otherText: talkAboutCoverage.otherText,
                    }),
                }}
              />
            ) : (
              <CareSelectButton
                key={index}
                {...{
                  selected: talkAboutCoverage.id === item.id,
                  setSelected: () => {
                    setTalkAboutCoverage({ ...item })
                    updateClient({ ...item })
                  },
                  text: item.name,
                  className: `left-align ${isMobile() ? 'm-t-8' : 'm-t-18'}`,
                }}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default TalkAboutCoverage
