import superagent from 'superagent'
import { getToken } from 'utils/authLocalStorage'
import formatUrl from 'apis/formatUrl'
import { api_version } from 'config'
import moment from 'moment-timezone'
import { logAmplitudeEvent } from 'utils/amplitudeInit'

const methods = ['get', 'post', 'put', 'patch', 'delete']

const ignoreHeaderUrls = ['/timestamp']

const timezone = moment.tz.guess()
export const ServerTimeCache = { gap: 0 }

export default class ApiClient {
  constructor(req) {
    methods.forEach((method) => {
      this[method] = (
        path,
        { params, body, header, version, toSplash } = {}
      ) => {
        const fullUrl = formatUrl(path, version)
        const request = superagent[method](fullUrl)

        request.set('app_version', api_version)
        request.set('platform', 'web')
        request.set('timezone', timezone)
        if (params) {
          request.query(params)
        }

        if (header) {
          for (let i in header) {
            request.set(i, header[i])
          }
        }

        const isIgnoreHeaderUrl = ignoreHeaderUrls.includes(path)
        if (!isIgnoreHeaderUrl) {
          const token = getToken()
          if (token) request.set('Authorization', `Bearer ${token}`)
        }

        if (body) {
          request.send(body)
        }

        let _promise = new Promise((resolve, reject) => {
          request.end((err, { body, text } = {}) => {
            if (err) {
              const _code = body?.code || body?.Code || err?.status
              if (![404, 401]?.includes(+_code)) {
                logAmplitudeEvent('Error: Network Error', {
                  'Request URL': fullUrl,
                  'Error Code': _code || '502',
                  'Error Message':
                    (body && (body.message || body.Message || body.msg)) ||
                    err.message ||
                    '',
                  'Error Description': body || err,
                  'Error Type': '',
                })
              }
            }
            resolve(body || text || err)
          })
        })

        return _promise
      }
    })
  }
  empty() {}
}
