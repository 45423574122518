import React, { useCallback, useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { parse, format, addDays } from 'date-fns'
import DatePicker from 'react-datepicker'
import {
  AVAILABLE_SLOTS_FOR_RANGE,
  APPOINTMENT_TYPES,
  useHealthieAPIs,
} from 'apis/HealthieApiClient'
import 'react-datepicker/dist/react-datepicker.css'
import classes from './index.module.scss'
import { BorderGradient } from 'components/BorderGradient/index'
import { useI18n } from 'contexts/AuthContext'
import moment from 'moment-timezone'
import _ from 'lodash'
import { isMobile } from 'utils/basic'
import { LoadingContent } from 'components/LoadingDialog/index'
import { Button } from 'antd'
const { style } = classes

export const ScheduleAppointmentTypes = (props) => {
  const { i18n } = useI18n()
  const { loading, error, data, refetch } = useQuery(APPOINTMENT_TYPES, {
    variables: {
      provider_id: props.providerId,
      clients_can_book: true,
      org_level: true,
    },
  })

  useEffect(() => {
    if (!loading && data) {
      props.setAppointmentType(data?.appointmentTypes?.[0] ?? {})
      props.moveToNextStep()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, data])

  return (
    <div className={style}>
      <div className='appointment-types-container'>
        <Button
          className='btn-primary small-btn'
          onClick={() => {
            refetch()
          }}
          hidden={!error}
        >
          {i18n('Refresh')}
        </Button>
      </div>
    </div>
  )
}

const ScheduleDatePicker = (props) => {
  const { daysAvailableForRange } = useHealthieAPIs()
  const [daysAvailable, setDaysAvailable] = useState()

  const updateDaysAvailable = useCallback(
    async (startDate) => {
      const { data } = await daysAvailableForRange({
        variables: {
          org_level: true,
          start_date: format(startDate || new Date(), 'yyyy-MM-dd'),
          timezone:
            Intl.DateTimeFormat().resolvedOptions().timeZone ||
            'America/New_York',
          appt_type_id: props.selectedAppointmentType.id,
          provider_id: props.providerId,
          clients_can_join_waitlist: true,
          provider_ids: null,
          licensed_in_state: props.state,
          end_date: format(addDays(new Date(), 21), 'yyyy-MM-dd'),
        },
      })
      setDaysAvailable(data)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.providerId, props.selectedAppointmentType.id, props.state]
  )

  useEffect(() => {
    updateDaysAvailable()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const highlightDates =
    daysAvailable && daysAvailable.daysAvailableForRange
      ? daysAvailable.daysAvailableForRange.map((day) =>
          // parse(day, 'yyyy-MM-dd HH:mm:ss xx', new Date())
          moment.parseZone(day).format('yyyy-MM-DD')
        )
      : []

  const isActiveDay = (date) => {
    const endDay = addDays(new Date(), 21)
    return _.find(highlightDates, (item) => {
      return (
        moment(date).isSame(item, 'day') && moment(date).isSameOrBefore(endDay)
      )
    })
  }

  return (
    <div className='embeddable-book-cal-container'>
      <DatePicker
        inline
        useWeekdaysShort={true}
        onChange={(e) => {
          props.setSelectedDay(e)
        }}
        selected={props.selectedDay}
        filterDate={isActiveDay}
        minDate={new Date()}
        maxDate={addDays(new Date(), 21)}
        onMonthChange={(e) => {
          const isCurrentMonth = moment().isSame(e, 'month')
          if (!isCurrentMonth) {
            if (!moment(props.selectedDay).isSame(e, 'month')) {
              e.setDate(1)
              props.setSelectedDay(e)
              updateDaysAvailable(e)
            }
          } else {
            const isNextAvailableSlotDay =
              props.nextAvailableSlotDay &&
              moment().isSame(props.nextAvailableSlotDay, 'month')
            if (isNextAvailableSlotDay) {
              props.setSelectedDay(props.nextAvailableSlotDay)
            } else {
              props.setSelectedDay(new Date())
            }
            updateDaysAvailable(new Date())
          }
        }}
      />
    </div>
  )
}

const TimeZoneMap = [
  { timeZone: 'America/New_York', timeString: 'Eastern Time' },
  { timeZone: 'America/Chicago', timeString: 'Central Time' },
  { timeZone: 'America/Denver', timeString: 'Mountain Time' },
  { timeZone: 'America/Los_Angeles', timeString: 'Pacific Time' },
  { timeZone: 'America/Anchorage', timeString: 'Alaska Time' },
  { timeZone: 'America/Adak', timeString: 'Hawaii-Aleutian Time' },
]

const ScheduleAvailableSlots = (props) => {
  const { i18n } = useI18n()
  const { loading, data } = useQuery(AVAILABLE_SLOTS_FOR_RANGE, {
    variables: {
      org_level: true,
      provider_id: props.providerId,
      provider_ids: null,
      appt_type_id: props.selectedAppointmentType.id,
      end_date: format(props.selectedDay, 'yyyy-MM-dd'),
      start_date: format(props.selectedDay, 'yyyy-MM-dd'),
      selected_day: {},
      timezone:
        Intl.DateTimeFormat().resolvedOptions().timeZone || 'America/New_York',
      clients_can_join_waitlist: true,
      licensed_in_state: props.state,
    },
  })

  const availableSlot = (slot, index, props) => {
    return (
      <BorderGradient
        key={index}
        onClick={() => props.setSelectedSlot(slot)}
        className={`available-slot ${
          props.selectedSlot === slot ? 'active' : ''
        }`}
        showGradient={props.selectedSlot === slot}
      >
        <div className={props.selectedSlot === slot ? 'gradient-font-1' : ''}>
          {moment
            .parseZone(slot.date, 'YYYY-MM-DD HH:mm:ss ZZ')
            .format('h:mm A')}
        </div>
      </BorderGradient>
    )
  }

  const getTimeZoneString = () => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const _mapTimeZone = _.find(
      TimeZoneMap,
      (item) => item.timeZone === timeZone
    )
    return _mapTimeZone ? _mapTimeZone.timeString : `Timezone: ${timeZone}`
  }

  return (
    <div className='embeddable-availability-container'>
      {isMobile() ? (
        <>
          <p
            className='availability-on-header-timezone'
            style={{ color: '#7E818C' }}
          >
            {getTimeZoneString()}
          </p>
          <div className='availability-on-header'>
            <span className='availability-on-header-date'>
              {format(props.selectedDay, 'MMMM d, yyyy')}
            </span>
          </div>
        </>
      ) : (
        <>
          <div className='availability-on-header'>
            <span className='availability-on-header-date'>
              {format(props.selectedDay, 'MMMM d, yyyy')}
            </span>
          </div>
          <p className='availability-on-header-timezone'>
            {getTimeZoneString()}
          </p>
        </>
      )}

      <div
        className='areas-of-day-flexbox'
        style={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
        }}
        hidden={!loading}
      >
        <LoadingContent />
      </div>

      <div className='areas-of-day-flexbox' hidden={loading}>
        {((data && data.availableSlotsForRange) || []).map((slot, index) =>
          availableSlot(slot, index, props)
        )}
      </div>

      {!loading &&
      (!data || (data.availableSlotsForRange || []).length === 0) ? (
        <div className='embeddable-empty-state'>
          <p className='embeddable-empty-state__title'>
            {i18n('No available time slots')}
          </p>
          <p>{i18n('Please select a different date in the calendar.')}</p>
        </div>
      ) : null}
    </div>
  )
}

export const ScheduleDateTimeSelector = (props) => {
  const [selectedDay, setSelectedDay] = useState(new Date())
  const [nextAvailableSlotDay, setNextAvailableSlotDay] = useState(new Date())
  const { nextAvailableSlot } = useHealthieAPIs()

  const toDate = (date) => {
    return parse(date, 'yyyy-MM-dd', new Date())
  }

  const getNextAvailableSlot = useCallback(async () => {
    const { data } = await nextAvailableSlot({
      variables: {
        org_level: true,
        provider_id: props.providerId,
        provider_ids: null,
        appt_type_id: props.selectedAppointmentType.id,
        timezone:
          Intl.DateTimeFormat().resolvedOptions().timeZone ||
          'America/New_York',
        licensed_in_state: props.state,
      },
    })
    const nextAvailableSlotDay = data?.nextAvailableSlot
      ? toDate(data.nextAvailableSlot)
      : new Date()

    const endDay = addDays(new Date(), 21)
    const isValidNextDay = moment(nextAvailableSlotDay).isSameOrBefore(
      endDay,
      'day'
    )
    if (isValidNextDay) {
      setSelectedDay(nextAvailableSlotDay)
      setNextAvailableSlotDay(nextAvailableSlotDay)
    }
  }, [
    nextAvailableSlot,
    props.providerId,
    props.selectedAppointmentType.id,
    props.state,
  ])

  useEffect(() => {
    getNextAvailableSlot()
  }, [getNextAvailableSlot])

  return (
    <div className={style}>
      <div className='embedded-scheduler-container'>
        <ScheduleDatePicker
          selectedDay={selectedDay}
          setSelectedDay={setSelectedDay}
          selectedAppointmentType={props.selectedAppointmentType}
          providerId={props.providerId}
          providerIds={props.providerIds}
          state={props.state}
          nextAvailableSlotDay={nextAvailableSlotDay}
        />
        <ScheduleAvailableSlots
          selectedDay={selectedDay}
          setSelectedSlot={props.setSelectedSlot}
          selectedSlot={props.selectedSlot}
          selectedAppointmentType={props.selectedAppointmentType}
          providerId={props.providerId}
          providerIds={props.providerIds}
          state={props.state}
          moveToNextStep={props.moveToNextStep}
        />
      </div>
    </div>
  )
}
