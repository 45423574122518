import { useLazyQuery, useMutation, gql } from '@apollo/client'

const HEALTHIE_QUERY_USER = gql`
  query users($keywords: String) {
    users(keywords: $keywords) {
      id
      first_name
      last_name
      phone_number
      email
      dietitian_id
    }
  }
`

export const HEALTHIE_GET_USER_STRING = `query getUser($id: ID, $include_tags: Boolean = false) {
  user(id: $id) {
    ...ClientFragment
    active_tags @include(if: $include_tags) {
      id
      name
    }
  }
}

fragment ClientFragment on User {
  id
  last_conversation_id
  full_name
  first_name
  last_name
  legal_name
  full_legal_name
  full_legal_name_with_preferred
  email
  phone_number
  next_appointment
  skipped_email
  last_activity
  avatar_url
  active
  height_str
  timezone
  last_sign_in_at
  last_active
  created_at
  quick_notes
  dob
  gender
  gender_identity
  sex
  dietitian_id
  record_identifier
  additional_record_identifier
  user_group_id
  skipped_email
  height_percentile
  weight_percentile
  bmi_percentile
  height_percentile_standard
  weight_percentile_standard
  bmi_percentile_standard
  required_dosespot_fields
  default_stripe_customer_detail_id
  unseen_sent_notification_records_count
  user_group {
    name
    id
  }
  weight
  last_weighing_date
  height
  money_owed
  credit_balance
  additional_phone_number
  line_graph_metric_categories
  location {
    id
    to_oneline
  }
  locations {
    ...LocationFragment
  }
  client_source {
    id
    ref_type
    related_object {
      id
      full_name
    }
    ref_source_other
    ref_source_asyncdropdown: related_insurance {
      id
      label: name_and_id
      value: id
    }
  }
  policies: policiesOrderAscending {
    name
    id
    num
    group_num
    holder_dob
    holder_relationship
    user_id
    policy_phone_number
    priority_type
    insurance_plan_id
    type_string
    holder_first
    holder_last
    holder_mi
    holder_phone
    holder_gender
    insurance_card_back_id
    insurance_card_front_id
    same_address_as_client
    payer_location {
      ...LocationFragment
    }
    holder_location {
      ...LocationFragment
    }
    insurance_plan {
      id
      payer_name
      value: id
      label: name_and_id
    }
  }
  referrals {
    id
    referring_physician_id
    referral_reason
    referring_physician {
      id
      business_name
      speciality
      full_name
      provider {
        organization {
          id
        }
      }
    }
  }
}

fragment LocationFragment on Location {
  id
  name
  line1
  line2
  city
  state
  country
  zip
}
`

const HEALTHIE_GET_USER = gql`
  ${HEALTHIE_GET_USER_STRING}
`

const HEALTHIE_CREATE_CLIENT = gql`
  mutation createClient(
    $first_name: String
    $last_name: String
    $email: String
    $skipped_email: Boolean
    $phone_number: String
    $dietitian_id: String
    $dont_send_welcome: Boolean
  ) {
    createClient(
      input: {
        first_name: $first_name
        last_name: $last_name
        email: $email
        skipped_email: $skipped_email
        phone_number: $phone_number
        dietitian_id: $dietitian_id
        dont_send_welcome: $dont_send_welcome
      }
    ) {
      user {
        id
      }
      messages {
        field
        message
      }
    }
  }
`

const HEALTHIE_SIGN_UP = gql`
  mutation signUp(
    $timezone: String
    $first_name: String
    $last_name: String
    $email: String
    $password: String
    $phone_number: String
    $invite_code: String
    $provider_type: String
    $role: String
    $exploring_healthie_reason: String
    $exploring_healthie_reason_other: String
    $dietitian_id: String
    $legal_name: String
  ) {
    signUp(
      input: {
        first_name: $first_name
        last_name: $last_name
        email: $email
        password: $password
        phone_number: $phone_number
        invite_code: $invite_code
        provider_type: $provider_type
        role: $role
        dietitian_id: $dietitian_id
        timezone: $timezone
        exploring_healthie_reason: $exploring_healthie_reason
        exploring_healthie_reason_other: $exploring_healthie_reason_other
        legal_name: $legal_name
      }
    ) {
      whitelabelSetting {
        id
        branded_name
        branded_url
        branded_backend_url
        branded_logo_url
        branded_email
        branded_favicon_url
        ios_app_link
        android_app_link
        food_icon_url
        metric_icon_url
        workout_icon_url
        selfie_icon_url
        replacement_words_json
        mixpanel_project_token
        __typename
      }
      user {
        id
        shard_aware_id
        blast_seen
        last_conversation_id
        first_name
        last_name
        legal_name
        has_ach
        full_name
        email
        is_super_admin
        dosespot_notification_count
        created_at
        is_patient
        true_human_id
        default_currency
        next_required_step
        unseen_notifications_count
        unread_convo_count
        requested_form_count
        unconfirmed_appointment_count
        is_org
        has_user_switch_options
        has_ach
        sidebar_notification_count
        open_payments_count
        dietitian_id
        avatar_url
        intercom_hash
        skipped_bank_account
        is_first_time_provider
        seen_last_article
        latest_article_link
        latest_article_number
        cannot_access_org_member_settings
        dietitian {
          id
          brand_name
          full_name
          __typename
        }
        displayed_brand {
          id
          logo_url
          bg_color
          font_color
          selected_color
          tab_accent_color
          main_layout_css_url
          __typename
        }
        show_education_tab
        show_org_tab
        show_subscription_link
        show_chat_tab
        show_sent_fax_tab
        show_received_fax_tab
        show_appointments_tab
        show_documents_tab
        show_forms_tab
        show_billing_tab
        show_superbills_tab
        show_payments_tab
        show_billing_dropdown_item
        show_dashboard
        show_cms1500s_tab
        show_packages_tab
        show_brand_tab
        show_goals_area
        show_journal_entries_area
        show_care_plan_area
        show_integrations_dropdown_item
        show_payment_methods_dropdown_item
        can_archive_clients
        can_edit_appointment_types
        can_add_clients
        can_see_fullscript_tab
        can_see_transfers
        can_schedule_appointments
        can_edit_org_calendar
        can_edit_packages
        can_edit_credit
        can_edit_products
        can_merge_clients
        can_charge_clients
        has_increased_branding
        has_custom_color_schemes
        can_edit_settings
        can_edit_brand
        show_expanded_label
        timezone
        can_edit_programs
        knows_about_provider
        has_courses
        has_lab_orders
        has_fullscript
        dismissed_fullscript
        is_org_with_multiple_users
        dismissed_products_tab
        assign_tasks_seen
        additional_sidebar_items {
          icon
          label
          url
          id
          target
          svg_url
          open_in_iframe
        }
        additional_header_dropdown_items {
          label
          url
          id
        }
        fitbit {
          id
          last_sync_date
        }
        broken_synced_cal {
          id
          external_type
          calendar_list
          add_to_calendar
          has_refresh_token
          pull_from_calendar
          pulled_in_calendars
          pull_in_event_details
        }
        organization_membership {
          id
          is_admin
        }
      }
      nextRequiredStep
      token
      initialMessage
      messages {
        field
        message
      }
    }
  }
`

export const HEALTHIE_CREATE_FORM_ANSWER_GROUP_STRING = `
mutation createFormAnswerGroup(
  # Should almost always true
  $finished: Boolean
  # ID of the custom_module_form (e.g "100")
  $custom_module_form_id: String
  # ID of the patient (e.g "61")
  $user_id: String
  # e.g [{custom_module_id: "1", answer: "foo", user_id: "61"}, {custom_module_id: "2", answer: "bar", user_id: "61"}]
  $form_answers: [FormAnswerInput!]!
) {
  createFormAnswerGroup(
    input: {
      finished: $finished
      custom_module_form_id: $custom_module_form_id
      user_id: $user_id
      form_answers: $form_answers
    }
  ) {
    form_answer_group {
      id
    }
    messages {
      field
      message
    }
  }
}
`

const HEALTHIE_CREATE_FORM_ANSWER_GROUP = gql`
  ${HEALTHIE_CREATE_FORM_ANSWER_GROUP_STRING}
`

const HEALTHIE_CREATE_REQUESTED_FORM = gql`
  mutation createRequestedFormCompletion(
    $recipient_ids: String
    $form: String
    $is_recurring: Boolean
    $frequency: String
    $period: String
    $minute: String
    $hour: String
    $weekday: String
    $monthday: String
    $recurrence_ends: Boolean
    $ends_on: String
  ) {
    createRequestedFormCompletion(
      input: {
        recipient_ids: $recipient_ids
        form: $form
        is_recurring: $is_recurring
        frequency: $frequency
        period: $period
        minute: $minute
        hour: $hour
        weekday: $weekday
        monthday: $monthday
        recurrence_ends: $recurrence_ends
        ends_on: $ends_on
      }
    ) {
      newRequestedFormCompletion: requestedFormCompletionStatus {
        status
        __typename
      }
      __typename
    }
  }
`

export const HEALTHIE_UPDATE_CLIENT_STRING = `
mutation updateClient(
  $id: ID
  $quick_notes: String
  $active: Boolean
  $dob: String
  $gender: String
  $height: String
  $client_source: ClientSourceInput
  $location: ClientLocationInput
  $policies: [ClientPolicyInput]
  $referrals: [ReferralInput]
  $first_name: String
  $last_name: String
  $phone_number: String
  $dietitian_id: String
  $legal_name: String
  $email: String
) {
  updateClient(
    input: {
      id: $id
      quick_notes: $quick_notes
      active: $active
      dob: $dob
      gender: $gender
      height: $height
      client_source: $client_source
      location: $location
      policies: $policies
      referrals: $referrals
      first_name: $first_name
      last_name: $last_name
      phone_number: $phone_number
      dietitian_id: $dietitian_id
      legal_name: $legal_name
      email: $email
    }
  ) {
    user {
      id
      first_name
      last_name
      legal_name
      email
    }
    messages {
      field
      message
    }
  }
}
`
const HEALTHIE_UPDATE_CLIENT = gql`
  ${HEALTHIE_UPDATE_CLIENT_STRING}
`

const HEALTHIE_APPLY_TAGS = gql`
  mutation applyTags($ids: [ID], $taggable_user_id: ID) {
    bulkApply(input: { ids: $ids, taggable_user_id: $taggable_user_id }) {
      tags {
        id
        name
      }

      messages {
        field
        message
      }
    }
  }
`

const HEALTHIE_REMOVE_TAGS = gql`
  mutation removeAppliedTag($id: ID, $taggable_user_id: ID) {
    removeAppliedTag(input: { id: $id, taggable_user_id: $taggable_user_id }) {
      tag {
        id
        name
      }

      messages {
        field
        message
      }
    }
  }
`

export const HEALTHIE_CREATE_ENTRY_STRING = `
mutation createEntry(
  $type: String
  $user_id: String
  $category: String
  $metric_stat: String
  $description: String
) {
  createEntry(
    input: {
      type: $type
      user_id: $user_id
      category: $category
      metric_stat: $metric_stat
      description: $description
    }
  ) {
    entry {
      id
    }
    messages {
      field
      message
    }
  }
}
`

const HEALTHIE_CREATE_ENTRY = gql`
  ${HEALTHIE_CREATE_ENTRY_STRING}
`

export const HEALTHIE_QUERY_INSURANCE_PLANS_STRING = `query insurancePlans(
  $ids: String
  $keywords: String
  $is_accepted: Boolean
  $order_by: InsurancePlanOrderKeys
) {
  insurancePlans(
    ids: $ids
    keywords: $keywords
    is_accepted: $is_accepted
    order_by: $order_by
  ) {
    id
    payer_name
  }
}`

const HEALTHIE_QUERY_INSURANCE_PLANS = gql`
  ${HEALTHIE_QUERY_INSURANCE_PLANS_STRING}
`

export const APPOINTMENT_TYPES = gql`
  query appointmentTypes(
    $clients_can_book: Boolean
    $org_level: Boolean
    $provider_id: String
  ) {
    appointmentTypes(
      provider_id: $provider_id
      clients_can_book: $clients_can_book
      org_level: $org_level
    ) {
      id
      name
      length
      available_contact_types
      is_group
    }
  }
`

export const GET_ORGANIZATION = gql`
  query getOrganization(
    $provider_id: ID
    $provider_ids: [ID]
    $offering_ids: [ID]
    $appointment_type_ids: String
    $state_licenses: [String]
  ) {
    organization(
      provider_id: $provider_id
      provider_ids: $provider_ids
      state_licenses: $state_licenses
    ) {
      id
      providers: active_care_team_members(
        provider_ids: $provider_ids
        state_licenses: $state_licenses
      ) {
        id
        full_name
        qualifications
        avatar_url
        has_future_availabilities(offering_ids: $offering_ids)
        state_licenses {
          id
          state
          full_state_name
          __typename
        }
        appointment_types(
          require_client_bookable: true
          appointment_type_ids: $appointment_type_ids
        ) {
          id
          require_in_state_clients
          __typename
        }
        __typename
      }
      __typename
    }
  }
`

export const DAYS_AVAILABLE_FOR_RANGE = gql`
  query daysAvailableForRange(
    $provider_id: String
    $date_from_month: String
    $org_level: Boolean
    $contact_type: String
    $timezone: String
    $provider_ids: [String]
    $appt_type_id: String
    $appt_loc_id: String
    $clients_can_join_waitlist: Boolean
    $appointment_to_reschedule_id: ID
    $licensed_in_state: String
    $end_date: String
    $end_date_boundary: String
    $start_date_boundary: String
    $start_date: String
  ) {
    daysAvailableForRange(
      provider_id: $provider_id
      date_from_month: $date_from_month
      org_level: $org_level
      contact_type: $contact_type
      timezone: $timezone
      provider_ids: $provider_ids
      appt_type_id: $appt_type_id
      appt_loc_id: $appt_loc_id
      clients_can_join_waitlist: $clients_can_join_waitlist
      appointment_to_reschedule_id: $appointment_to_reschedule_id
      licensed_in_state: $licensed_in_state
      end_date: $end_date
      end_date_boundary: $end_date_boundary
      start_date_boundary: $start_date_boundary
      start_date: $start_date
    )
  }
`

export const AVAILABLE_SLOTS_FOR_RANGE = gql`
  query availableSlotsForRange(
    $provider_id: String
    $start_date: String
    $end_date: String
    $org_level: Boolean
    $contact_type: String
    $timezone: String
    $provider_ids: [String]
    $appt_type_id: String
    $appt_loc_id: String
    $clients_can_join_waitlist: Boolean
    $appointment_to_reschedule_id: ID
    $licensed_in_state: String
  ) {
    availableSlotsForRange(
      provider_id: $provider_id
      start_date: $start_date
      end_date: $end_date
      contact_type: $contact_type
      timezone: $timezone
      org_level: $org_level
      provider_ids: $provider_ids
      appt_type_id: $appt_type_id
      appt_loc_id: $appt_loc_id
      clients_can_join_waitlist: $clients_can_join_waitlist
      appointment_to_reschedule_id: $appointment_to_reschedule_id
      licensed_in_state: $licensed_in_state
    ) {
      user_id
      date
      appointment_id
      is_fully_booked
      has_waitlist_enabled
    }
  }
`

export const WIDGET_STEPS_STRING = `query embedWidgetSetup(
  $locationString: String
  $is_for_web: Boolean
  $offeringId: ID
) {
  embedWidgetSteps(locationString: $locationString, is_for_web: $is_for_web) {
    title
    id
    __typename
  }
  brand(locationString: $locationString) {
    id
    embeddable_layout_css_url
    gtm_id
    ga_id
    __typename
  }
  offering(id: $offeringId) {
    id
    archived
    __typename
  }
}`

export const WIDGET_STEPS = gql`
  ${WIDGET_STEPS_STRING}
`

export const COMPLETE_BOOKING_STRING = `
mutation completeCheckout(
  $appointment_location_id: String
  $appointment_type_id: String
  $appointment_id: String
  $contact_type: String
  $coupon_code: String
  $date: String
  $location: String
  $offering_id: String
  $price: String
  $reason: String
  $token: String
  $first_name: String
  $legal_name: String
  $last_name: String
  $email: String
  $phone_number: String
  $provider_id: String
  $form_answer_group: CheckoutFormAnswerGroupInput
  $requested_payment_id: String
  $stripe_idempotency_key: String
  $timezone: String
  $is_gift: Boolean
  $gift: GiftInput
  $is_joining_waitlist: Boolean
  $intake_flow_uuid: String
) {
  completeCheckout(
    input: {
      appointment_location_id: $appointment_location_id
      appointment_type_id: $appointment_type_id
      contact_type: $contact_type
      coupon_code: $coupon_code
      date: $date
      location: $location
      offering_id: $offering_id
      price: $price
      reason: $reason
      token: $token
      appointment_id: $appointment_id
      timezone: $timezone
      requested_payment_id: $requested_payment_id
      first_name: $first_name
      last_name: $last_name
      legal_name: $legal_name
      email: $email
      phone_number: $phone_number
      provider_id: $provider_id
      form_answer_group: $form_answer_group
      is_gift: $is_gift
      gift: $gift
      is_joining_waitlist: $is_joining_waitlist
      stripe_idempotency_key: $stripe_idempotency_key
      intake_flow_uuid: $intake_flow_uuid
    }
  ) {
    appointment {
      provider {
        id
        full_name
        __typename
      }
      id
      date
      start
      end
      location
      contact_type
      add_to_gcal_link
      appointment_type {
        id
        name
        length
        __typename
      }
      __typename
    }
    patientEmail
    formAnswerGroupSaved
    widgetCompletedSubheaderHtml
    billingItem {
      id
      amount_paid
      currency
      gifted_by
      gifted_to
      payment_medium
      sender {
        id
        open_payments_count
        stripe_customer_detail {
          id
          last_four
          __typename
        }
        __typename
      }
      recurring_payment {
        id
        __typename
      }
      offering {
        name
        id
        currency
        price
        first_time_payment
        frequency_times_string
        billing_frequency
        repeat_times
        offering_image_url
        __typename
      }
      requested_payment {
        id
        status
        paid_at
        __typename
      }
      recipient {
        id
        full_name
        __typename
      }
      __typename
    }
    userPackageSelection {
      id
      offering {
        name
        id
        offering_image_url
        __typename
      }
      __typename
    }
    messages {
      field
      message
      __typename
    }
    __typename
  }
}
`
const COMPLETE_BOOKING = gql`
  ${COMPLETE_BOOKING_STRING}
`

const HEALTHIE_CREATE_SENT_FAX = gql`
  mutation createSentFax(
    $dietitian: FaxDietitianInput
    $answer_ids: String
    $date: String
    $destination_number: String
    $document_id: String
    $document_ids: String
    $form_answer_group_ids: String
    $include_header_on_every_page: Boolean
    $include_hipaa_disclaimer: Boolean
    $patient_id: String
    $recipient_company: String
    $recipient_name: String
    $remarks: String
    $skip_cover_page: Boolean
    $subject: String
  ) {
    createSentFax(
      input: {
        dietitian: $dietitian
        answer_ids: $answer_ids
        date: $date
        destination_number: $destination_number
        document_id: $document_id
        document_ids: $document_ids
        form_answer_group_ids: $form_answer_group_ids
        include_header_on_every_page: $include_header_on_every_page
        include_hipaa_disclaimer: $include_hipaa_disclaimer
        patient_id: $patient_id
        recipient_company: $recipient_company
        recipient_name: $recipient_name
        remarks: $remarks
        skip_cover_page: $skip_cover_page
        subject: $subject
      }
    ) {
      sent_fax {
        id
      }
      messages {
        field
        message
      }
    }
  }
`

const CREATE_REFERRING_PHYSICIAN = gql`
  mutation createReferringPhysician(
    $accepts_insurance: Boolean
    $business_name: String
    $email: String
    $fax_number: String
    $first_name: String
    $last_name: String
    $notes: String
    $npi: String
    $other_id: String
    $other_id_qualifier: String
    $phone_number: String
    $speciality: String
    $website: String
    $location: PhysicianLocationInput
    $referrals: [PhysicianReferralInput!]
  ) {
    createReferringPhysician(
      input: {
        accepts_insurance: $accepts_insurance
        business_name: $business_name
        email: $email
        fax_number: $fax_number
        first_name: $first_name
        last_name: $last_name
        notes: $notes
        npi: $npi
        other_id: $other_id
        other_id_qualifier: $other_id_qualifier
        phone_number: $phone_number
        speciality: $speciality
        website: $website
        location: $location
        referrals: $referrals
      }
    ) {
      newReferringPhysician: referring_physician {
        id
        full_name
        npi
        __typename
      }
      duplicatedPhysician: duplicated_physician {
        ...ReferringPhysicianFragment
        __typename
      }
      messages {
        field
        message
        __typename
      }
      __typename
    }
  }

  fragment ReferringPhysicianFragment on ReferringPhysician {
    id
    first_name
    last_name
    full_name
    phone_number
    fax_number
    npi
    other_id
    other_id_qualifier
    email
    speciality
    business_name
    website
    notes
    accepts_insurance
    referrals {
      id
      user_id
      user {
        id
        full_name
        __typename
      }
      referral_reason
      __typename
    }
    location {
      id
      line1
      line2
      city
      name
      state
      zip
      country
      __typename
    }
    __typename
  }
`

const CREATE_REFERRAL = gql`
  mutation createReferral(
    $user_id: ID
    $referring_physician_id: ID
    $referral_reason: String
  ) {
    createReferral(
      input: {
        user_id: $user_id
        referring_physician_id: $referring_physician_id
        referral_reason: $referral_reason
      }
    ) {
      referral {
        id
        __typename
      }
      messages {
        field
        message
        __typename
      }
      __typename
    }
  }
`

const NEXT_AVAILABLE_SLOT = gql`
  query nextAvailableSlot(
    $timezone: String
    $provider_id: String
    $org_level: Boolean
    $provider_ids: [String]
    $appt_type_id: String
    $appt_loc_id: String
    $start_range_boundary: String
    $end_range_boundary: String
    $licensed_in_state: String
  ) {
    nextAvailableSlot(
      timezone: $timezone
      provider_id: $provider_id
      org_level: $org_level
      provider_ids: $provider_ids
      appt_type_id: $appt_type_id
      appt_loc_id: $appt_loc_id
      start_date_boundary: $start_range_boundary
      end_date_boundary: $end_range_boundary
      licensed_in_state: $licensed_in_state
    )
  }
`

export const APPOINTMENTS_STRING = `query appointments($user_id: ID, $filter: String, $sort_by: String, $should_paginate: Boolean, $offset: Int, $is_active: Boolean, $with_all_statuses: Boolean, $page_size: Int, $exclude_appointments_with_invoice: Boolean, $filter_by_invoices: Boolean) {
  appointmentsCount(
    user_id: $user_id
    filter: $filter
    is_org: true
    is_active: $is_active
    with_all_statuses: $with_all_statuses
    exclude_appointments_with_invoice: $exclude_appointments_with_invoice
    filter_by_invoices: $filter_by_invoices
  )
  appointments(
    is_active: $is_active
    user_id: $user_id
    filter: $filter
    is_org: true
    sort_by: $sort_by
    should_paginate: $should_paginate
    offset: $offset
    with_all_statuses: $with_all_statuses
    page_size: $page_size
    exclude_appointments_with_invoice: $exclude_appointments_with_invoice
    filter_by_invoices: $filter_by_invoices
  ) {
    ...ClientAppointmentFragment
    attended_clients {
      id
      user_id
      confirmed
      __typename
    }
    __typename
  }
}

fragment ClientAppointmentFragment on Appointment {
  id
  date
  start
  end
  is_group
  can_be_rescheduled
  contact_type
  generated_token
  length
  location
  appointment_category
  client_confirmed
  minimum_advance_cancel_time
  minimum_advance_reschedule_time
  pm_status(check_for_client_cancel: true)
  add_to_gcal_link
  confirmed
  connected_chart_note_string
  form_answer_group {
    id
    name
    created_at
    custom_module_form {
      id
      name
      __typename
    }
    __typename
  }
  user {
    id
    full_name
    avatar_url
    first_name
    phone_number
    __typename
  }
  provider {
    id
    first_name
    full_name
    first_name
    avatar_url
    phone_number
    appointment_setting {
      id
      ask_clients_to_confirm
      client_should_call_provider
      allow_clients_to_cancel_appt
      allow_clients_to_reschedule_appt
      cant_cancel_message
      cant_reschedule_message
      __typename
    }
    __typename
  }
  appointment_type {
    name
    id
    __typename
  }
  __typename
}`

export const useHealthieAPIs = () => {
  const [queryUsers] = useLazyQuery(HEALTHIE_QUERY_USER, {
    fetchPolicy: 'network-only',
  })
  const [getUser] = useLazyQuery(HEALTHIE_GET_USER, {
    fetchPolicy: 'network-only',
  })
  const [getInsurancePlans] = useLazyQuery(HEALTHIE_QUERY_INSURANCE_PLANS)
  const [createClient] = useMutation(HEALTHIE_CREATE_CLIENT)
  const [signUp] = useMutation(HEALTHIE_SIGN_UP)
  const [createFormAnswerGroup] = useMutation(HEALTHIE_CREATE_FORM_ANSWER_GROUP)
  const [createRequestedForm] = useMutation(HEALTHIE_CREATE_REQUESTED_FORM)
  const [healthieUpdateClient] = useMutation(HEALTHIE_UPDATE_CLIENT)
  const [applyTags] = useMutation(HEALTHIE_APPLY_TAGS)
  const [removeTags] = useMutation(HEALTHIE_REMOVE_TAGS)
  const [createEntry] = useMutation(HEALTHIE_CREATE_ENTRY)
  const [createSentFax] = useMutation(HEALTHIE_CREATE_SENT_FAX)
  const [createReferringPhysician] = useMutation(CREATE_REFERRING_PHYSICIAN)
  const [createReferral] = useMutation(CREATE_REFERRAL)
  const [completeBooking] = useMutation(COMPLETE_BOOKING, {
    context: {
      headers: { authorization: '', authorizationsource: '' },
    },
  })
  const [getOrganization] = useLazyQuery(GET_ORGANIZATION)
  const [daysAvailableForRange] = useLazyQuery(DAYS_AVAILABLE_FOR_RANGE)
  const [availableSlotsForRange] = useLazyQuery(AVAILABLE_SLOTS_FOR_RANGE)
  const [nextAvailableSlot] = useLazyQuery(NEXT_AVAILABLE_SLOT)
  const [widgetSteps] = useLazyQuery(WIDGET_STEPS)

  return {
    getUser,
    queryUsers,
    createClient,
    signUp,
    createFormAnswerGroup,
    healthieUpdateClient,
    applyTags,
    createEntry,
    getInsurancePlans,
    completeBooking,
    createSentFax,
    createReferringPhysician,
    createReferral,
    removeTags,
    getOrganization,
    daysAvailableForRange,
    availableSlotsForRange,
    createRequestedForm,
    nextAvailableSlot,
    widgetSteps,
  }
}
