import React, { useCallback, useEffect, useMemo, useState } from 'react'
import ContentHeader from './ContentHeader'
import { useI18n } from 'contexts/AuthContext'
import { Button, Select } from 'antd'
import _ from 'lodash'
import { isValidPhoneNumber } from 'react-phone-number-input'
import CareInput from 'components/CareInput/index'
import { usePrimaryPhysician, useUpdateClient } from 'hooks/careHooks'
import DropdownSelect from 'components/DropdownSelect/index'
import { ScreenKey } from './util'
import { getStateListPromise } from 'apis/FitOnCareAPI'
import { getHealthieUserId } from 'utils/authLocalStorage'
import { logAmplitudeEvent, setAmplitudeProfile } from 'utils/amplitudeInit'
import { setBrazeProfile } from 'utils/brazeInit'
const { Option } = Select

const PhysicianDetail = (props) => {
  const { i18n } = useI18n()
  const { saveLocalPrimaryPhysician, primaryPhysician, createReferring } =
    usePrimaryPhysician()
  const [stateOptions, setStateOptions] = useState([])
  const { updateClient } = useUpdateClient()
  const [name, setName] = useState(
    !_.isEmpty(primaryPhysician) ? primaryPhysician[3] : ''
  )
  const [address, setAddress] = useState(
    !_.isEmpty(primaryPhysician) ? primaryPhysician[5] : ''
  )
  const [city, setCity] = useState(
    !_.isEmpty(primaryPhysician) ? primaryPhysician[7] : ''
  )
  const [state, setState] = useState(
    !_.isEmpty(primaryPhysician) ? primaryPhysician[8] : null
  )
  const [zip, setZip] = useState(
    !_.isEmpty(primaryPhysician) ? primaryPhysician[9] : ''
  )

  const [phone, setPhone] = useState(
    !_.isEmpty(primaryPhysician) ? primaryPhysician[12] : ''
  )
  const [isPhoneValid, setIsPhoneValid] = useState()

  const getStateOptions = useCallback(async () => {
    const options = await getStateListPromise()
    setStateOptions(options?.stateList || [])
    return options?.stateList || []
  }, [])

  useEffect(() => {
    getStateOptions()
  }, [getStateOptions])

  const formatPhoneNumber = (input) => {
    // Remove all non-digit characters
    const phoneNumber = input.replace(/\D/g, '')
    // Format the phone number as (XXX) XXX-XXXX
    const formattedPhoneNumber = phoneNumber.replace(
      /(\d{3})(\d{3})(\d{4})/,
      '$1 $2 $3'
    )

    return formattedPhoneNumber
  }

  useEffect(() => {
    const phoneNumber = phone?.replace(/\D/g, '')
    const isValid = isValidPhoneNumber('+1' + phoneNumber)
    setIsPhoneValid(isValid)
  }, [phone])

  const updateClientInfo = () => {
    //0 - NPI,
    //1 - name.first,
    //2 - name.last,
    //3 - name.full,
    //4 - provider_type,
    //5 - addr_practice.line1,
    //6 - addr_practice.line2,
    //7 - addr_practice.city,
    //8 - addr_practice.state,
    //9 - addr_practice.zip,
    //10 - addr_practice.country,
    //11 - addr_practice.full,
    //12 - addr_practice.phone,
    //13 - addr_practice.fax
    const selectedPhysician = [
      '',
      '',
      '',
      name,
      '',
      address,
      '',
      city,
      state,
      zip,
      '',
      '',
      phone,
      '',
    ]
    saveLocalPrimaryPhysician(selectedPhysician)

    const names = selectedPhysician[3].split(' ')
    const firstName = selectedPhysician[1]
      ? selectedPhysician[1]
      : names.length > 0
      ? names[0]
      : selectedPhysician[3]
    const lastName = selectedPhysician[2]
      ? selectedPhysician[2]
      : names.length > 1
      ? selectedPhysician[3].replace(firstName, '')
      : selectedPhysician[3]
    createReferring({
      variables: {
        npi: selectedPhysician[0],
        legalname: selectedPhysician[3],
        firstName: firstName,
        lastName: lastName,
        phoneNumber: selectedPhysician[12],
        faxNumber: selectedPhysician[13],
        location: {
          line1: selectedPhysician[5],
          line2: selectedPhysician[6],
          city: selectedPhysician[7],
          state: selectedPhysician[8],
          zip: selectedPhysician[9],
          country: selectedPhysician[10] || 'US',
        },
        acceptsInsurance: true,
        referrals: [],
      },
      onSuccess: (id) => {
        updateClient({
          variables: {
            id: getHealthieUserId(),
            referrals: [
              {
                referring_physician_id: id,
              },
            ],
          },
          onSuccess: () => {
            props.setPCPDetail('Manual Entry')
            props.setCurrentKey(ScreenKey.PHYSICIAN_CONFIRMATION)
          },
        })
      },
    })

    setBrazeProfile({
      pcpNpi: selectedPhysician[0],
      pcpName: selectedPhysician[3],
      pcpAddress:
        selectedPhysician[11] ||
        `${selectedPhysician[5]}, ${selectedPhysician[7]} ${selectedPhysician[8]} ${selectedPhysician[9]}`,
      pcpPhone: selectedPhysician[12],
      pcpFax: selectedPhysician[13],
    })
    setAmplitudeProfile({
      pcpNpi: selectedPhysician[0],
    })
    logAmplitudeEvent('Screen View: Onboarding - Insurance - PCP Entry', {
      Name: name,
      Address: address,
      City: city,
      State: state,
      Zip: zip,
      Phone: phone,
    })
  }

  const isAddressNextButtonEnable = useMemo(() => {
    return (
      !_.isEmpty(name) &&
      !_.isEmpty(address) &&
      !_.isEmpty(city) &&
      !_.isEmpty(state) &&
      !_.isEmpty(zip) &&
      !_.isEmpty(phone)
    )
  }, [address, city, name, phone, state, zip])

  return (
    <div className='info-sec'>
      <ContentHeader {...props} />
      <div className='info-content'>
        <div className='title'>
          {i18n("Please provide your physician's details")}
        </div>
        <CareInput
          placeholder={i18n('Name')}
          inputValue={name}
          setInputValue={setName}
          className='m-t-32'
        />
        <CareInput
          placeholder={i18n('Address')}
          inputValue={address}
          setInputValue={setAddress}
          className='m-t-12'
        />
        <CareInput
          placeholder={i18n('City')}
          inputValue={city}
          setInputValue={setCity}
          className='m-t-12'
        />
        <div className='two-grid-sec m-t-12'>
          <DropdownSelect
            {...{
              placeholder: i18n('State'),
              options: stateOptions,
              setValue: (value) => {
                setState(value)
              },
              value: !_.isEmpty(state) ? state : null,
              render: (
                <>
                  {stateOptions.map((option, index) => (
                    <Option
                      className='state-item'
                      value={option.code}
                      key={index}
                    >
                      {option.name}
                    </Option>
                  ))}
                </>
              ),
            }}
          />
          <CareInput
            placeholder={i18n('ZIP')}
            inputValue={zip}
            setInputValue={setZip}
            type='text'
            pattern='\d*'
          />
        </div>
        <CareInput
          {...{
            inputValue: (phone || '').replace('+1', ''),
            setInputValue: (value) => {
              const formattedPhoneNumber = formatPhoneNumber(value)
              setPhone('+1' + formattedPhoneNumber)
            },
            placeholder: i18n(`000 000 0000`),
            prefix: '+1',
            isValid: !phone || isPhoneValid,
            errorMessage: i18n('Invalid Phone'),
            className: 'm-t-12',
            type: 'text',
            pattern: '\\d*',
          }}
        />
        <Button
          className='btn-primary full-width-btn'
          onClick={() => updateClientInfo()}
          disabled={!isAddressNextButtonEnable}
        >
          {i18n('Next')}
        </Button>
      </div>
    </div>
  )
}

export default PhysicianDetail
