import React, { useEffect } from 'react'
import ContentHeader from './ContentHeader'
import { useI18n } from 'contexts/AuthContext'
import { Button } from 'antd'
import callDoctorIcon from './img/ic-call-doctor.svg'
import { logAmplitudeEvent } from 'utils/amplitudeInit'

const PhysicianConfirmation = (props) => {
  const { i18n } = useI18n()

  useEffect(() => {
    logAmplitudeEvent(
      'Screen View: Onboarding - Insurance - PCP Confirmation',
      {
        Detail: props.pcpDetail,
      }
    )
  }, [])

  const onNext = () => {
    window.location.href = 'https://www.fitoncare.com'
  }

  return (
    <div className='info-sec'>
      <ContentHeader {...props} />
      <div className='info-content'>
        <img src={callDoctorIcon} alt='' className='top-icon' />
        <div className='title'>
          {i18n('Hang tight! We reached out to your primary physician.')}
        </div>
        <div
          className='subtitle'
          style={{ marginBottom: '10px', marginTop: '17px' }}
        >
          {i18n(
            'A referral from your physician is needed before we schedule you for an appointment. We will email you as soon as we hear back.'
          )}
        </div>
        <Button className='btn-primary full-width-btn m-t-28' onClick={onNext}>
          {i18n('Great, thanks!')}
        </Button>
      </div>
    </div>
  )
}

export default PhysicianConfirmation
