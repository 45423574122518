import amplitude from 'amplitude-js'
import { AMPLITUDE_KEY } from 'config'
import { getUserId } from 'utils/authLocalStorage'

export const amplitudeInit = ({ subscription } = {}) => {
  init({ subscription })
}

const init = () => {
  const ampl = amplitude.getInstance('FITON-CARE')
  ampl.init(
    AMPLITUDE_KEY,
    null,
    { includeReferrer: true, saveEvents: true },
    function () {}
  )
  ampl.setVersionName(process.env.REACT_APP_VERSION)
  window.ampl = ampl

  amplitudeLogin()
}

export const logAmplitudeEvent = (event, data) => {
  if (window.isDev) console.log(event, data)
  if (window.ampl) {
    window.ampl.logEvent(event, data)
  }
}

const setOption = (key, value) => {
  const identify = new amplitude.Identify().set(key, value)
  if (window.ampl) {
    window.ampl.identify(identify)
  }
}

const clearOption = (key) => {
  const identify = new amplitude.Identify().unset(key)
  if (window.ampl) {
    window.ampl.identify(identify)
  }
}

export const amplitudeLogin = () => {
  // debugger
  console.log('amplitudeLogin', getUserId())
  if (getUserId()) {
    if (window.ampl) {
      window.ampl.setUserId(getUserId())
    }
  } else {
    if (window.ampl) {
      window.ampl.setUserId(null)
      window.ampl.regenerateDeviceId()
    }
  }
}

export const amplitudeLogout = () => {
  if (window.ampl) {
    window.ampl.setUserId(null)
    window.ampl.regenerateDeviceId()
  }
}

export const amplitudeUserDelete = () => {
  setOption('User State', 'deleted')
}

export const setAmplitudeOption = (name, value) => {
  setOption(name, value)
}

export const setAmplitudeProfile = (options) => {
  const {
    age,
    birthday,
    gender,
    bmi,
    height,
    weight,
    pcpNpi,
    utmSource,
    utmCampaign,
    utmContent,
    state,
  } = options
  if (gender) setOption('Gender', gender)
  if (birthday) {
    setOption('Birthday', birthday)
  }
  if (age) {
    setOption('Age', age)
  }
  if (bmi) {
    setOption('BMI', bmi)
  }
  if (height) {
    setOption('Height', height)
  }
  if (weight) {
    setOption('Weight', weight)
  }
  if (pcpNpi) {
    setOption('PCP NPI', pcpNpi)
  }
  if (utmSource) {
    setOption('Referral Source', utmSource)
  }
  if (utmCampaign) {
    setOption('Referral Campaign', utmCampaign)
  }
  if (utmContent) {
    setOption('Referral Content', utmContent)
  }
  if (state) {
    setOption('State', state)
  }
}
