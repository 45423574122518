import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import ContentHeader from './ContentHeader'
import { useHearAbout, useI18n } from 'contexts/AuthContext'
import CareSelectButton from 'components/CareSelectButton/index'
import { ScreenKey, UploadSelectionOptionType } from './util'
import { useMetadataOptions, useUpdateClient } from 'hooks/careHooks'
import { getHealthieUserId } from 'utils/authLocalStorage'
import { uploadSelectionPromise } from 'apis/FitOnCareAPI'
import { setBrazeProfile } from 'utils/brazeInit'
import { logAmplitudeEvent, setAmplitudeOption } from 'utils/amplitudeInit'

const HearAbout = (props) => {
  const { i18n } = useI18n()
  const { hearAbout, setHearAbout } = useHearAbout()
  const [dataList, setDataList] = useState([])
  const { hearAboutOptions } = useMetadataOptions()
  const { updateClient } = useUpdateClient()

  useEffect(() => {
    if (!_.isEmpty(hearAboutOptions) && _.isEmpty(dataList)) {
      const otherOption = _.remove(hearAboutOptions, (option) => {
        return option.name === 'Other'
      })
      setDataList(_.shuffle(hearAboutOptions).concat(otherOption))
    }
  }, [dataList, hearAboutOptions])

  const getUpdateClientSource = (clientSource, otherText) => {
    if (clientSource === 'ZocDoc') {
      return { ref_type: 'ad', ref_source: 'ZocDoc' }
    } else if (clientSource === 'Advertisement') {
      return { ref_type: 'ad', ref_source: 'Other' }
    } else {
      return {
        ref_type: 'other',
        ref_source: clientSource === 'Other' ? otherText : clientSource,
      }
    }
  }

  const updateClientInfo = (item) => {
    uploadSelectionPromise({
      optionType: UploadSelectionOptionType.HEAR_ABOUT_OPTION,
      selectedOptionId: item.id,
      otherInputText: item.otherText,
    })
    updateClient({
      variables: {
        id: getHealthieUserId(),
        client_source: getUpdateClientSource(item.name, item.otherText),
      },
      onSuccess: () => {
        props.setCurrentKey(ScreenKey.TALK_ABOUT_COVERAGE)
      },
    })
    setBrazeProfile({ userAttribution: item.name })
    logAmplitudeEvent('Screen View: Onboarding - Referrer', {
      Referrer: item.name,
    })
    setAmplitudeOption('Referrer', item.name)
  }

  return (
    <div className='info-sec'>
      <ContentHeader {...props} />
      <div className='info-content'>
        <div className='title'>
          {i18n('How did you hear about FitOn Care?')}
        </div>
        <div className='two-grid-sec'>
          {dataList.map((item, index) => (
            <div key={index}>
              {item.name === 'Other' ? (
                <CareSelectButton
                  key={index}
                  {...{
                    selected: hearAbout.id === item.id,
                    setSelected: (value) => {
                      setHearAbout({
                        ...item,
                        otherText: hearAbout.otherText,
                      })
                    },
                    text: item.name,
                    className: 'left-align',
                    showInput: hearAbout.name === 'Other',
                    placeholder: i18n('Please specify'),
                    inputText: hearAbout.otherText || '',
                    maxLength: 50,
                    setInputText: (value) => {
                      const regex = /^[a-zA-Z0-9\s]*$/
                      if (regex.test(value)) {
                        setHearAbout({ ...item, otherText: value })
                      }
                    },
                    onSubmit: () =>
                      updateClientInfo({
                        ...item,
                        otherText: hearAbout.otherText,
                      }),
                  }}
                />
              ) : (
                <CareSelectButton
                  key={index}
                  {...{
                    selected: hearAbout.id === item.id,
                    setSelected: () => {
                      setHearAbout({ ...item })
                      updateClientInfo(item)
                    },
                    text: item.name,
                    className: 'left-align',
                  }}
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default HearAbout
