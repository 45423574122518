import React, { useEffect, useState } from 'react'
import ContentHeader from './ContentHeader'
import { useI18n } from 'contexts/AuthContext'
import { Button } from 'antd'
import _ from 'lodash'
import CareInput from 'components/CareInput/index'
import DropdownSelect from 'components/DropdownSelect/index'
import { useCareInsurancePay, useCarePersonInfo } from 'hooks/careHooks'
import { ScreenKey } from './util'
import { logAmplitudeEvent } from 'utils/amplitudeInit'
import { formatPhoneNumber, isMobile } from 'utils/basic'
import { isValidPhoneNumber } from 'react-phone-number-input'

const InsuranceCardInfo = (props) => {
  const { i18n } = useI18n()
  const [planKey, setPlanKey] = useState()
  const [showPlanDownList, setShowPlanDownList] = useState(false)
  const [phoneOnFocus, setPhoneOnFocus] = useState()
  const [isPhoneValid, setIsPhoneValid] = useState()
  const {
    insurancePlan,
    setInsurancePlan,
    insurancePlanOptions,
    coverage,
    setCoverage,
    coverageOptions,
    type,
    setType,
    typeOptions,
    relationship,
    setRelationship,
    relationshipOptions,
    policyNumber,
    setPolicyNumber,
    groupNumber,
    setGroupNumber,
    companyPhone,
    setCompanyPhone,
    isCardInfoNextButtonEnable,
    getInsurancePlansOptions,
    saveLocalData,
    applyHealthieTags,
    removeHealthieTags,
    tags,
  } = useCareInsurancePay()

  const { state } = useCarePersonInfo()

  useEffect(() => {
    getInsurancePlansOptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (companyPhone) {
      const phoneNumber = companyPhone?.replace(/\D/g, '')
      const isValid = isValidPhoneNumber('+1 ' + phoneNumber)
      setIsPhoneValid(isValid)
    }
  }, [companyPhone])

  const updateTags = () => {
    const stateType = state.type
    const planType = coverage
    const tag = _.find(
      tags,
      (tag) => tag.planType == planType && tag.stateType == stateType
    )

    const removeTag = _.find(
      tags,
      (tag) =>
        tag.planType == (planType === 'HMO' ? 'PPO' : 'HMO') &&
        tag.stateType == stateType
    )
    if (removeTag) {
      removeHealthieTags({
        tagId: removeTag.tagId.toString(),
      })
    }
    if (tag) {
      applyHealthieTags({
        tagId: tag.tagId.toString(),
      })
    }
  }

  const planOptions = () => {
    if (planKey) {
      const filterOptions = _.filter(
        insurancePlanOptions,
        (option) =>
          option.payer_name.toLowerCase().indexOf(planKey.toLowerCase()) > -1
      )
      return filterOptions.map((option, index) => {
        return (
          <div
            className='state-item'
            key={index}
            onClick={() => {
              setInsurancePlan(option)
              setPlanKey(option.payer_name)
              setShowPlanDownList(false)
            }}
          >
            {option.payer_name}
          </div>
        )
      })
    } else {
      return insurancePlanOptions.map((option, index) => {
        return (
          <div
            className='state-item'
            key={index}
            onClick={() => {
              setInsurancePlan(option)
              setPlanKey(option.payer_name)
              setShowPlanDownList(false)
            }}
          >
            {option.payer_name}
          </div>
        )
      })
    }
  }

  return (
    <div className='info-sec'>
      <ContentHeader {...props} />
      <div className='info-content'>
        <div className='title'>
          {i18n('Let us check your insurance coverage')}
        </div>
        <CareInput
          placeholder={i18n('Insurance Plan')}
          defaultValue={
            _.find(insurancePlanOptions, { id: insurancePlan?.id })?.payer_name
          }
          inputValue={
            planKey ??
            _.find(insurancePlanOptions, { id: insurancePlan?.id })?.payer_name
          }
          setInputValue={setPlanKey}
          className={`${isMobile() ? 'm-t-8' : 'm-t-12'}`}
          onFocus={(e) => {
            setShowPlanDownList(true)
          }}
          onBlur={(e) => {
            setTimeout(() => {
              setShowPlanDownList(false)
            }, 200)
          }}
        >
          <div className='plan-down-list' hidden={!showPlanDownList}>
            {planOptions()}
          </div>
        </CareInput>
        <div className={`two-grid-sec ${isMobile() ? 'm-t-8' : 'm-t-12'}`}>
          <DropdownSelect
            {...{
              placeholder: i18n('Coverage'),
              options: coverageOptions,
              setValue: setCoverage,
              value:
                coverage && _.find(coverageOptions, { value: coverage }).label,
            }}
          />
          <DropdownSelect
            {...{
              placeholder: i18n('Type'),
              options: typeOptions,
              setValue: setType,
              value: type,
            }}
          />
        </div>
        <DropdownSelect
          {...{
            placeholder: i18n('Relationship'),
            options: relationshipOptions,
            setValue: setRelationship,
            value:
              relationship &&
              _.find(relationshipOptions, { value: relationship }).label,
            className: `${isMobile() ? 'm-t-8' : 'm-t-12'}`,
          }}
        />
        <div className={`two-grid-sec ${isMobile() ? 'm-t-8' : 'm-t-12'}`}>
          <CareInput
            placeholder={i18n('Policy number')}
            inputValue={policyNumber}
            setInputValue={(value) => {
              setPolicyNumber(value)
            }}
            type='text'
          />
          <CareInput
            placeholder={i18n('Group number (optional)')}
            inputValue={groupNumber}
            setInputValue={(value) => {
              setGroupNumber(value)
            }}
            type='text'
          />
        </div>
        <CareInput
          placeholder={i18n('Insurance Plan Company Phone (optional)')}
          inputValue={companyPhone}
          setInputValue={(value) => {
            if (value) {
              const formattedPhoneNumber = formatPhoneNumber(value)
              setCompanyPhone(formattedPhoneNumber)
            } else {
              setCompanyPhone('')
            }
          }}
          type='text'
          pattern='\d*'
          className={`${isMobile() ? 'm-t-8' : 'm-t-12'}`}
          {...{
            isValid: !companyPhone || phoneOnFocus || isPhoneValid,
            errorMessage: i18n('Invalid Phone'),
            className: `${isMobile() ? 'm-t-8' : 'm-t-12'}`,
            onFocus: () => {
              setPhoneOnFocus(true)
            },
            onBlur: () => {
              setPhoneOnFocus(false)
            },
          }}
        />
        <Button
          className='btn-primary full-width-btn'
          style={isMobile() ? { width: '100%' } : {}}
          onClick={() => {
            logAmplitudeEvent(
              'Screen View: Onboarding - Insurance - Coverage',
              {
                Type: coverage,
              }
            )
            saveLocalData()
            updateTags()
            props.setCurrentKey(ScreenKey.INSURANCE_HOME_ADDRESS)
          }}
          disabled={!isCardInfoNextButtonEnable}
        >
          {i18n('Next')}
        </Button>
      </div>
    </div>
  )
}

export default InsuranceCardInfo
