export const languageCountryMapping = {
  AD: { languages: ['ca'] },
  AE: { languages: ['ar'] },
  AF: { languages: ['ps', 'uz', 'tk'] },
  AG: { languages: ['en'] },
  AI: { languages: ['en'] },
  AL: { languages: ['sq'] },
  AM: { languages: ['hy', 'ru'] },
  AO: { languages: ['pt'] },
  AQ: { languages: [] },
  AR: { languages: ['es', 'gn'] },
  AS: { languages: ['en', 'sm'] },
  AT: { languages: ['de'] },
  AU: { languages: ['en'] },
  AW: { languages: ['nl', 'pa'] },
  AX: { languages: ['sv'] },
  AZ: { languages: ['az'] },
  BA: { languages: ['bs', 'hr', 'sr'] },
  BB: { languages: ['en'] },
  BD: { languages: ['bn'] },
  BE: { languages: ['nl', 'fr', 'de'] },
  BF: { languages: ['fr', 'ff'] },
  BG: { languages: ['bg'] },
  BH: { languages: ['ar'] },
  BI: { languages: ['fr', 'rn'] },
  BJ: { languages: ['fr'] },
  BL: { languages: ['fr'] },
  BM: { languages: ['en'] },
  BN: { languages: ['ms'] },
  BO: { languages: ['es', 'ay', 'qu'] },
  BQ: { languages: ['nl'] },
  BR: { languages: ['pt'] },
  BS: { languages: ['en'] },
  BT: { languages: ['dz'] },
  BV: { languages: ['no', 'nb', 'nn'] },
  BW: { languages: ['en', 'tn'] },
  BY: { languages: ['be', 'ru'] },
  BZ: { languages: ['en', 'es'] },
  CA: { languages: ['en', 'fr'] },
  CC: { languages: ['en'] },
  CD: { languages: ['fr', 'ln', 'kg', 'sw', 'lu'] },
  CF: { languages: ['fr', 'sg'] },
  CG: { languages: ['fr', 'ln'] },
  CH: { languages: ['de', 'fr', 'it'] },
  CI: { languages: ['fr'] },
  CK: { languages: ['en'] },
  CL: { languages: ['es'] },
  CM: { languages: ['en', 'fr'] },
  CN: { languages: ['zh'] },
  CO: { languages: ['es'] },
  CR: { languages: ['es'] },
  CU: { languages: ['es'] },
  CV: { languages: ['pt'] },
  CW: { languages: ['nl', 'pa', 'en'] },
  CX: { languages: ['en'] },
  CY: { languages: ['el', 'tr', 'hy'] },
  CZ: { languages: ['cs', 'sk'] },
  DE: { languages: ['de'] },
  DJ: { languages: ['fr', 'ar'] },
  DK: { languages: ['da'] },
  DM: { languages: ['en'] },
  DO: { languages: ['es'] },
  DZ: { languages: ['ar'] },
  EC: { languages: ['es'] },
  EE: { languages: ['et'] },
  EG: { languages: ['ar'] },
  EH: { languages: ['es'] },
  ER: { languages: ['ti', 'ar', 'en'] },
  ES: { languages: ['es', 'eu', 'ca', 'gl', 'oc'] },
  ET: { languages: ['am'] },
  FI: { languages: ['fi', 'sv'] },
  FJ: { languages: ['en', 'fj', 'hi', 'ur'] },
  FK: { languages: ['en'] },
  FM: { languages: ['en'] },
  FO: { languages: ['fo'] },
  FR: { languages: ['fr'] },
  GA: { languages: ['fr'] },
  GB: { languages: ['en'] },
  GD: { languages: ['en'] },
  GE: { languages: ['ka'] },
  GF: { languages: ['fr'] },
  GG: { languages: ['en', 'fr'] },
  GH: { languages: ['en'] },
  GI: { languages: ['en'] },
  GL: { languages: ['kl'] },
  GM: { languages: ['en'] },
  GN: { languages: ['fr', 'ff'] },
  GP: { languages: ['fr'] },
  GQ: { languages: ['es', 'fr'] },
  GR: { languages: ['el'] },
  GS: { languages: ['en'] },
  GT: { languages: ['es'] },
  GU: { languages: ['en', 'ch', 'es'] },
  GW: { languages: ['pt'] },
  GY: { languages: ['en'] },
  HK: { languages: ['zh', 'en'] },
  HM: { languages: ['en'] },
  HN: { languages: ['es'] },
  HR: { languages: ['hr'] },
  HT: { languages: ['fr', 'ht'] },
  HU: { languages: ['hu'] },
  ID: { languages: ['id'] },
  IE: { languages: ['ga', 'en'] },
  IL: { languages: ['he', 'ar'] },
  IM: { languages: ['en', 'gv'] },
  IN: { languages: ['hi', 'en'] },
  IO: { languages: ['en'] },
  IQ: { languages: ['ar', 'ku'] },
  IR: { languages: ['fa'] },
  IS: { languages: ['is'] },
  IT: { languages: ['it'] },
  JE: { languages: ['en', 'fr'] },
  JM: { languages: ['en'] },
  JO: { languages: ['ar'] },
  JP: { languages: ['ja'] },
  KE: { languages: ['en', 'sw'] },
  KG: { languages: ['ky', 'ru'] },
  KH: { languages: ['km'] },
  KI: { languages: ['en'] },
  KM: { languages: ['ar', 'fr'] },
  KN: { languages: ['en'] },
  KP: { languages: ['ko'] },
  KR: { languages: ['ko'] },
  KW: { languages: ['ar'] },
  KY: { languages: ['en'] },
  KZ: { languages: ['kk', 'ru'] },
  LA: { languages: ['lo'] },
  LB: { languages: ['ar', 'fr'] },
  LC: { languages: ['en'] },
  LI: { languages: ['de'] },
  LK: { languages: ['si', 'ta'] },
  LR: { languages: ['en'] },
  LS: { languages: ['en', 'st'] },
  LT: { languages: ['lt'] },
  LU: { languages: ['fr', 'de', 'lb'] },
  LV: { languages: ['lv'] },
  LY: { languages: ['ar'] },
  MA: { languages: ['ar'] },
  MC: { languages: ['fr'] },
  MD: { languages: ['ro'] },
  ME: { languages: ['sr', 'bs', 'sq', 'hr'] },
  MF: { languages: ['en', 'fr', 'nl'] },
  MG: { languages: ['fr', 'mg'] },
  MH: { languages: ['en', 'mh'] },
  MK: { languages: ['mk'] },
  ML: { languages: ['fr'] },
  MM: { languages: ['my'] },
  MN: { languages: ['mn'] },
  MO: { languages: ['zh', 'pt'] },
  MP: { languages: ['en', 'ch'] },
  MQ: { languages: ['fr'] },
  MR: { languages: ['ar'] },
  MS: { languages: ['en'] },
  MT: { languages: ['mt', 'en'] },
  MU: { languages: ['en'] },
  MV: { languages: ['dv'] },
  MW: { languages: ['en', 'ny'] },
  MX: { languages: ['es'] },
  MY: { languages: ['ms'] },
  MZ: { languages: ['pt'] },
  NA: { languages: ['en', 'af'] },
  NC: { languages: ['fr'] },
  NE: { languages: ['fr'] },
  NF: { languages: ['en'] },
  NG: { languages: ['en'] },
  NI: { languages: ['es'] },
  NL: { languages: ['nl'] },
  NO: { languages: ['no', 'nb', 'nn'] },
  NP: { languages: ['ne'] },
  NR: { languages: ['en', 'na'] },
  NU: { languages: ['en'] },
  NZ: { languages: ['en', 'mi'] },
  OM: { languages: ['ar'] },
  PA: { languages: ['es'] },
  PE: { languages: ['es'] },
  PF: { languages: ['fr'] },
  PG: { languages: ['en'] },
  PH: { languages: ['en'] },
  PK: { languages: ['en', 'ur'] },
  PL: { languages: ['pl'] },
  PM: { languages: ['fr'] },
  PN: { languages: ['en'] },
  PR: { languages: ['es', 'en'] },
  PS: { languages: ['ar'] },
  PT: { languages: ['pt'] },
  PW: { languages: ['en'] },
  PY: { languages: ['es', 'gn'] },
  QA: { languages: ['ar'] },
  RE: { languages: ['fr'] },
  RO: { languages: ['ro'] },
  RS: { languages: ['sr'] },
  RU: { languages: ['ru'] },
  RW: { languages: ['rw', 'en', 'fr'] },
  SA: { languages: ['ar'] },
  SB: { languages: ['en'] },
  SC: { languages: ['fr', 'en'] },
  SD: { languages: ['ar', 'en'] },
  SE: { languages: ['sv'] },
  SG: { languages: ['en', 'ms', 'ta', 'zh'] },
  SH: { languages: ['en'] },
  SI: { languages: ['sl'] },
  SJ: { languages: ['no'] },
  SK: { languages: ['sk'] },
  SL: { languages: ['en'] },
  SM: { languages: ['it'] },
  SN: { languages: ['fr'] },
  SO: { languages: ['so', 'ar'] },
  SR: { languages: ['nl'] },
  SS: { languages: ['en'] },
  ST: { languages: ['pt'] },
  SV: { languages: ['es'] },
  SX: { languages: ['nl', 'en'] },
  SY: { languages: ['ar'] },
  SZ: { languages: ['en', 'ss'] },
  TC: { languages: ['en'] },
  TD: { languages: ['fr', 'ar'] },
  TF: { languages: ['fr'] },
  TG: { languages: ['fr'] },
  TH: { languages: ['th'] },
  TJ: { languages: ['tg', 'ru'] },
  TK: { languages: ['en'] },
  TL: { languages: ['pt'] },
  TM: { languages: ['tk', 'ru'] },
  TN: { languages: ['ar'] },
  TO: { languages: ['en', 'to'] },
  TR: { languages: ['tr'] },
  TT: { languages: ['en'] },
  TV: { languages: ['en'] },
  TW: { languages: ['zh'] },
  TZ: { languages: ['sw', 'en'] },
  UA: { languages: ['uk'] },
  UG: { languages: ['en', 'sw'] },
  UM: { languages: ['en'] },
  US: { languages: ['en'] },
  UY: { languages: ['es'] },
  UZ: { languages: ['uz', 'ru'] },
  VA: { languages: ['it', 'la'] },
  VC: { languages: ['en'] },
  VE: { languages: ['es'] },
  VG: { languages: ['en'] },
  VI: { languages: ['en'] },
  VN: { languages: ['vi'] },
  VU: { languages: ['bi', 'en', 'fr'] },
  WF: { languages: ['fr'] },
  WS: { languages: ['sm', 'en'] },
  XK: { languages: ['sq', 'sr'] },
  YE: { languages: ['ar'] },
  YT: { languages: ['fr'] },
  ZA: {
    languages: ['af', 'en', 'nr', 'st', 'ss', 'tn', 'ts', 've', 'xh', 'zu'],
  },
  ZM: { languages: ['en'] },
  ZW: { languages: ['en', 'sn', 'nd'] },
}
