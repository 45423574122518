import React from 'react'
import Welcome from './Welcome'
import PersonalInfo from './PersonalInfo'
import Survey from './Survey'
import HormonalAutoImmune from './HormonalAutoImmune'
import ComingSoon from './ComingSoon'
import LimitedAvailability from './LimitedAvailability'
import CompleteProfile from './CompleteProfile'
import HearAbout from './HearAbout'
import TalkAboutCoverage from './TalkAboutCoverage'
import InsuranceCardInfo from './InsuranceCardInfo'
import InsuranceHomeAddress from './InsuranceHomeAddress'
import ScheduledTime from './ScheduledTime'
import InsuranceNotAccepted from './InsuranceNotAccepted'
import InsuranceNotAcceptedNoCashPay from './InsuranceNotAcceptedNoCashPay'
import PrimaryCarePhysician from './PrimaryCarePhysician'
import PhysicianDetail from './PhysicianDetail'
import PhysicianConfirmation from './PhysicianConfirmation'
import CardOnFile from './CardOnFile'
import CashPay from './CashPay'
import VerifyEmail from './VerifyEmail'
import WelcomeNew from './WelcomeNew'
import WelcomeNewNotQualified from './WelcomeNewNotQualified'

export const ScreenKey = {
  WELCOME: 'welcome',
  PERSONAL_INFO: 'personalInfo',
  SURVEY: 'survey',
  HORMONAL_AUTOIMMUNE: 'hormonalAutoImmune',
  COMING_SOON: 'comingSoon',
  LIMITED_AVAILABILITY: 'limitedAvailability',
  COMPLETE_PROFILE: 'completeProfile',
  HEAR_ABOUT: 'hearAbout',
  TALK_ABOUT_COVERAGE: 'talkAboutCoverage',
  INSURANCE_CARD_INFO: 'insuranceCardInfo',
  INSURANCE_HOME_ADDRESS: 'insuranceHomeAddress',
  SCHEDULED_TIME: 'scheduledTime',
  INSURANCE_NOT_ACCEPTED: 'insuranceNotAccepted',
  INSURANCE_NOT_ACCEPTED_NO_CASH_PAY: 'insuranceNotAcceptedNoCashPay',
  PRIMARY_CARE_PHYSICIAN: 'primaryCarePhysician',
  PHYSICIAN_DETAIL: 'physicianDetail',
  PHYSICIAN_CONFIRMATION: 'physicianConfirmation',
  CARD_ON_FILE: 'cardOnFile',
  CASH_PAY: 'cashPay',
  APPOINTMENT_CONFIRM: 'appointmentConfirm',
  VERIFY_EMAIL: 'verifyEmail',
  WELCOME_NEW: 'welcomeNew',
  WELCOME_NEW_NOT_QUALIFIED: 'welcomeNewNotQualified',
}

export const openScreen = (key, props) => {
  switch (key) {
    case ScreenKey.WELCOME:
      return <Welcome {...props} />
    case ScreenKey.PERSONAL_INFO:
      return <PersonalInfo {...props} />
    case ScreenKey.SURVEY:
      return <Survey {...props} />
    case ScreenKey.HORMONAL_AUTOIMMUNE:
      return <HormonalAutoImmune {...props} />
    case ScreenKey.COMING_SOON:
      return <ComingSoon {...props} />
    case ScreenKey.LIMITED_AVAILABILITY:
      return <LimitedAvailability {...props} />
    case ScreenKey.COMPLETE_PROFILE:
      return <CompleteProfile {...props} />
    case ScreenKey.HEAR_ABOUT:
      return <HearAbout {...props} />
    case ScreenKey.TALK_ABOUT_COVERAGE:
      return <TalkAboutCoverage {...props} />
    case ScreenKey.INSURANCE_CARD_INFO:
      return <InsuranceCardInfo {...props} />
    case ScreenKey.INSURANCE_HOME_ADDRESS:
      return <InsuranceHomeAddress {...props} />
    case ScreenKey.SCHEDULED_TIME:
      return <ScheduledTime {...props} />
    case ScreenKey.INSURANCE_NOT_ACCEPTED:
      return <InsuranceNotAccepted {...props} />
    case ScreenKey.INSURANCE_NOT_ACCEPTED_NO_CASH_PAY:
      return <InsuranceNotAcceptedNoCashPay {...props} />
    case ScreenKey.PRIMARY_CARE_PHYSICIAN:
      return <PrimaryCarePhysician {...props} />
    case ScreenKey.PHYSICIAN_DETAIL:
      return <PhysicianDetail {...props} />
    case ScreenKey.PHYSICIAN_CONFIRMATION:
      return <PhysicianConfirmation {...props} />
    case ScreenKey.CARD_ON_FILE:
      return <CardOnFile {...props} />
    case ScreenKey.CASH_PAY:
      return <CashPay {...props} />
    case ScreenKey.VERIFY_EMAIL:
      return <VerifyEmail {...props} />
    case ScreenKey.WELCOME_NEW:
      return <WelcomeNew {...props} />
    case ScreenKey.WELCOME_NEW_NOT_QUALIFIED:
      return <WelcomeNewNotQualified {...props} />
    default:
      break
  }
}

export const UploadSelectionOptionType = {
  HELP_MOST_OPTION: 1,
  DISEASE_HELP_OPTION: 2,
  HEAR_ABOUT_OPTION: 3,
  INSURANCE_PROVIDER_OPTION: 4,
}

// added for reminder
// eslint-disable-next-line no-unused-vars
const PageIndex = {
  1: ScreenKey.WELCOME,
  2: ScreenKey.PERSONAL_INFO,
  3: ScreenKey.SURVEY,
  '3a': ScreenKey.HORMONAL_AUTOIMMUNE,
  '4a': ScreenKey.COMING_SOON,
  '4b': ScreenKey.LIMITED_AVAILABILITY,
  5: ScreenKey.COMPLETE_PROFILE,
  6: ScreenKey.HEAR_ABOUT,
  7: ScreenKey.TALK_ABOUT_COVERAGE,
  '8a': ScreenKey.INSURANCE_CARD_INFO,
  '8b': ScreenKey.INSURANCE_HOME_ADDRESS,
  '9a': ScreenKey.INSURANCE_NOT_ACCEPTED,
  '9b': ScreenKey.INSURANCE_NOT_ACCEPTED_NO_CASH_PAY,
  '10a': ScreenKey.PRIMARY_CARE_PHYSICIAN,
  '10b': ScreenKey.PHYSICIAN_DETAIL,
  '10c': ScreenKey.PHYSICIAN_CONFIRMATION,
  '11a': ScreenKey.CARD_ON_FILE,
  '11b': ScreenKey.CASH_PAY,
  12: ScreenKey.SCHEDULED_TIME,
  13: ScreenKey.APPOINTMENT_CONFIRM,
}
