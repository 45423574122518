import 'react-app-polyfill/stable'
import React, { useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import _ from 'lodash'
import moment from 'moment-timezone'
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  from,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import setMobileFullHeight from 'utils/setMobileFullHeight'
import { getHealthieToken, getUserId } from 'utils/authLocalStorage'
import { getDateStorage, setDateStorage } from 'utils/localTimerStorage'
import 'unorm'
import 'antd/dist/reset.css'
import 'animate.css/animate.css'
import App from './App'
import 'utils/polyfills'
import '@stripe/stripe-js'
import { amplitudeInit, logAmplitudeEvent } from 'utils/amplitudeInit'
import { getGeoInfo } from 'lokalise/lokalise'
import { brazeInit } from 'utils/brazeInit'
import withRouter from 'hooks/withRouter'
import './index.scss'
import { getHealthieUrl } from 'apis/formatUrl'

Bugsnag.start({
  apiKey: '169b4bd3c2ee314dc8182fffc2f67b56',
  plugins: [new BugsnagPluginReact()],
  appVersion: process.env.REACT_APP_VERSION,
  user: {
    id: getUserId(),
  },
  onError: function (event) {
    return shouldReportError(event)
  },
})

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

const ignoreErrorMessage = [
  'Failed to fetch',
  'The network connection was lost',
  'The Internet connection appears to be offline',
]

const includeIgrnoreErrorMessage = (errorMessage) => {
  for (let i = 0; i < ignoreErrorMessage.length; i++) {
    if (errorMessage && errorMessage.indexOf(ignoreErrorMessage[i]) > -1) {
      return true
    }
  }
  return false
}

const shouldReportError = (event) => {
  if (process.env.NODE_ENV === 'production') {
    if (
      !_.isEmpty(event.errors) &&
      event.errors[0].errorClass === 'TimeoutError' &&
      event.errors[0].errorMessage === 'Request timed out'
    ) {
      return false
    } else if (
      !_.isEmpty(event.errors) &&
      event.errors[0].errorClass === 'TypeError' &&
      includeIgrnoreErrorMessage(event.errors[0].errorMessage)
    ) {
      return false
    } else if (
      !_.isEmpty(event.errors) &&
      event.errors[0].errorClass === 'Error' &&
      (event.errors[0].errorMessage.indexOf('Loading chunk') > -1 ||
        event.errors[0].errorMessage === 'ResizeObserver loop limit exceeded')
    ) {
      return false
    } else if (
      !_.isEmpty(event.errors) &&
      event.errors[0].errorClass === 'SecurityError' &&
      event.errors[0].errorMessage.indexOf(
        'MIME Type is not a JavaScript MIME type'
      ) > -1
    ) {
      return false
    } else if (
      !_.isEmpty(event.errors) &&
      event.errors[0].errorClass === 'SyntaxError' &&
      event.errors[0].errorMessage.indexOf(`Unexpected token '<'`) > -1
    ) {
      const value = getDateStorage()
      const date = moment(new Date()).format('YYYY-MM-DD')
      if ((value.date === date && value.count < 3) || value.date !== date) {
        if (value.date !== date) {
          setDateStorage(date, 1)
        } else {
          setDateStorage(date, value.count + 1)
        }
        window.location.reload()
        return false
      } else {
        return true
      }
    }
    return true
  }
  return false
}

const ScrollToTop = withRouter((props) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [props.location])
  return props.children
})

const httpLink = createHttpLink({
  uri: getHealthieUrl(),
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getHealthieToken()
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : 'testToken',
      authorizationsource: 'Web',
    },
  }
})

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log(`[GraphQL error]: Message: ${message}, Path: ${path}`)
      logAmplitudeEvent('Error: Network Error', {
        'Request URL': path,
        'Error Message': message || '',
        Locations: JSON.stringify(locations),
        'Error Description': JSON.stringify(operation?.variables),
        'Error Type': '',
      })
    })
  if (networkError) {
    console.error(`[Network error]: ${networkError}`)
    logAmplitudeEvent('Error: Network Error', {
      'Request URL': operation?.operationName,
      'Error Message': JSON.stringify(networkError),
      'Error Description': JSON.stringify(operation?.variables),
      'Error Type': 'Network Error',
    })
  }
})

const client = new ApolloClient({
  link: from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache(),
})

amplitudeInit()
brazeInit()
setMobileFullHeight()

const initApp = async () => {
  await getGeoInfo()
  const container = document.getElementById('root')
  const root = createRoot(container)
  root.render(
    <ErrorBoundary>
      <Router>
        <ApolloProvider client={client}>
          <ScrollToTop>
            <App />
          </ScrollToTop>
        </ApolloProvider>
      </Router>
    </ErrorBoundary>
  )
}
initApp()
