import React, { useEffect } from 'react'
import ContentHeader from './ContentHeader'
import { useI18n } from 'contexts/AuthContext'
import { Button } from 'antd'
import _ from 'lodash'
import hourGlassIcon from './img/ic-hourglass.svg'
import { useCarePersonInfo, useUpdateClient } from 'hooks/careHooks'
import { getHealthieUserId } from 'utils/authLocalStorage'
import { logAmplitudeEvent } from 'utils/amplitudeInit'
import { updateUserPromise } from 'apis/FitOnCareAPI'
import { logBrazeEvent } from 'utils/brazeInit'

const ComingSoon = (props) => {
  const { i18n } = useI18n()
  const { updateClient } = useUpdateClient()
  const { state } = useCarePersonInfo()

  useEffect(() => {
    updateUserPromise({ activate: 1, userStatus: 4 })
    props.setRemoveEventListener(true)
  }, [])

  useEffect(() => {
    if (!_.isEmpty(state)) {
      logAmplitudeEvent('Screen View: Onboarding - Not Available', {
        Reason: 'State',
        Detail: state.code,
        State: state.name,
      })
      logBrazeEvent('User Denied: State')
    }
  }, [state])

  const onNext = () => {
    updateClient({
      variables: {
        id: getHealthieUserId(),
        active: false,
      },
      onSuccess: () => (window.location.href = 'https://www.fitoncare.com'),
    })
  }

  const onBack = (e) => {
    updateUserPromise({ activate: 0 })
    props.setRemoveEventListener(false)
    props.onBack(e)
  }

  return (
    <div className='info-sec'>
      <ContentHeader {...props} onBack={onBack} />
      <div className='info-content'>
        <img src={hourGlassIcon} alt='' className='top-icon' />
        <div className='title'>{i18n('Coming soon!')}</div>
        <div
          className='subtitle'
          style={{ marginBottom: '10px', marginTop: '17px' }}
        >
          {i18n(
            'Unfortunately, we aren’t available in your state yet but we’ll email you as soon as we are available in your area. Stay tuned.'
          )}
        </div>
        <Button className='btn-primary small-btn' onClick={onNext}>
          {i18n('ok')}
        </Button>
      </div>
    </div>
  )
}

export default ComingSoon
