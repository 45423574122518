const setMobileFullHeight = () => {
  function __set() {
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }
  __set()
  window.addEventListener('resize', __set)
  window.addEventListener('scroll', __set)
}

export default setMobileFullHeight