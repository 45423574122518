import React, {
  useReducer,
  useEffect,
  useContext,
  useCallback,
  useState,
} from 'react'
import moment from 'moment-timezone'
import { useJsApiLoader } from '@react-google-maps/api'
import 'moment/locale/fr'
import 'moment/locale/de'
import 'moment/locale/es'
import 'moment/locale/pt'
import { allowFitOnLanguage } from 'hooks/usePagePathType'
import useLokalise from 'lokalise/lokalise'
import { REACT_APP_GOOGLE_MAPS_API_KEY } from 'config'
import {
  clearUserInfo,
  getStateId,
  getToken,
  setHealthieProviderId,
  setStateId,
} from 'utils/authLocalStorage'
import _ from 'lodash'
import { useCareInsurancePay, useMetadataOptions } from 'hooks/careHooks'
import { getUserProfilePromise } from 'apis/FitOnCareAPI'

export const AuthContext = React.createContext({})
const libraries = ['places']
const initialState = {
  personalInfo: {},
  survey: {},
  hormonalAutoimmune: {},
  profile: {},
  hearAbout: {},
  talkAboutCoverage: {},
  insuranceCardEntry: {},
  primaryPhysician: [],
  metadataOptions: {},
  isClearAll: false,
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_PERSONAL_INFO':
      return {
        ...state,
        personalInfo: {
          ...state.personalInfo,
          ...(action.payload.personalInfo || {}),
        },
      }
    case 'SET_SURVEY':
      return {
        ...state,
        survey: action.payload.survey || {},
      }
    case 'SET_HORMONAL_AUTOIMMUNE':
      return {
        ...state,
        hormonalAutoimmune: action.payload.hormonalAutoimmune || {},
      }
    case 'SET_PROFILE':
      return {
        ...state,
        profile: action.payload.profile || {},
      }
    case 'SET_HEAR_ABOUT':
      return {
        ...state,
        hearAbout: action.payload.hearAbout || {},
      }
    case 'SET_TALK_ABOUT_COVERAGE':
      return {
        ...state,
        talkAboutCoverage: action.payload.talkAboutCoverage || {},
      }
    case 'SET_INSURANCE_CARD_ENTRY':
      return {
        ...state,
        insuranceCardEntry: action.payload.insuranceCardEntry || {},
      }
    case 'PRIMARY_PHYSICIAN':
      return {
        ...state,
        primaryPhysician: action.payload.primaryPhysician || [],
      }
    case 'METADATA_OPTIONS':
      return {
        ...state,
        metadataOptions: action.payload.metadataOptions || {},
      }
    case 'SET_CLEAR_DATA':
      return {
        ...state,
        isClearAll: action.payload.isClearAll || false,
      }
    case 'CLEAR_DATA':
      return { ...initialState, isClearAll: true }
    default:
      throw new Error()
  }
}

export const AuthContextProvider = (Children) => {
  const Provider = (props) => {
    const [state, dispatch] = useReducer(reducer, initialState)
    const { lokalise, localCode, localLang, langLoading } = useLokalise()

    useEffect(() => {
      if (localCode === 'en' || !allowFitOnLanguage()) {
        moment.locale('en')

        moment.updateLocale('en', {
          weekdaysMin: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
          week: {
            dow: 1, // Monday is the first day of the week.
          },
        })
      } else if (localCode) {
        moment.locale(localLang)

        moment.updateLocale(localCode)
      }
      // setLocalLanguage(localLang)
    }, [localCode, localLang])

    const clearData = () => {
      dispatch({
        type: 'CLEAR_DATA',
        payload: {},
      })
    }

    const { isLoaded: isGoogleMapLoaded } = useJsApiLoader({
      id: 'google-map-script',
      googleMapsApiKey: REACT_APP_GOOGLE_MAPS_API_KEY,
      libraries,
    })

    return (
      <AuthContext.Provider
        value={{
          ...state,
          dispatch,
          lokalise,
          localCode,
          localLang,
          langLoading,
          isGoogleMapLoaded,
          clearData,
        }}
      >
        <Children {...props} />
      </AuthContext.Provider>
    )
  }
  return Provider
}

export const useI18n = () => {
  const {
    lokalise: i18n,
    localCode,
    localLang,
    langLoading,
  } = useContext(AuthContext)
  return { i18n, localCode, localLang, langLoading }
}

export const usePersonalInfo = () => {
  const { personalInfo, dispatch } = useContext(AuthContext)
  const setPersonalInfo = ({
    firstName,
    lastName,
    preferredFirstName,
    phone,
    email,
    state,
  }) => {
    dispatch({
      type: 'SET_PERSONAL_INFO',
      payload: {
        personalInfo: {
          firstName,
          lastName,
          preferredFirstName,
          phone,
          email,
          state,
        },
      },
    })
  }
  return { personalInfo, setPersonalInfo }
}

export const useSurvey = () => {
  const { survey, dispatch } = useContext(AuthContext)
  const setSurvey = (survey) => {
    dispatch({
      type: 'SET_SURVEY',
      payload: {
        survey,
      },
    })
  }
  return { survey, setSurvey }
}

export const useHormonalAutoimmune = () => {
  const { hormonalAutoimmune, dispatch } = useContext(AuthContext)
  const setHormonalAutoimmune = (hormonalAutoimmune) => {
    dispatch({
      type: 'SET_HORMONAL_AUTOIMMUNE',
      payload: {
        hormonalAutoimmune,
      },
    })
  }
  return { hormonalAutoimmune, setHormonalAutoimmune }
}

export const useProfile = () => {
  const { profile, dispatch } = useContext(AuthContext)
  const setProfile = ({ birthday, gender, heightFt, heightIn, weightLbs }) => {
    dispatch({
      type: 'SET_PROFILE',
      payload: {
        profile: { birthday, gender, heightFt, heightIn, weightLbs },
      },
    })
  }
  return { profile, setProfile }
}

export const useHearAbout = () => {
  const { hearAbout, dispatch } = useContext(AuthContext)
  const setHearAbout = (hearAbout) => {
    dispatch({
      type: 'SET_HEAR_ABOUT',
      payload: {
        hearAbout,
      },
    })
  }
  return { hearAbout, setHearAbout }
}

export const useTalkAboutCoverage = () => {
  const { talkAboutCoverage, dispatch } = useContext(AuthContext)
  const setTalkAboutCoverage = (talkAboutCoverage) => {
    dispatch({
      type: 'SET_TALK_ABOUT_COVERAGE',
      payload: {
        talkAboutCoverage,
      },
    })
  }
  return { talkAboutCoverage, setTalkAboutCoverage }
}

export const useInsuranceCardEntry = () => {
  const { insuranceCardEntry, dispatch } = useContext(AuthContext)
  const setInsuranceCardEntry = ({
    insurancePlan,
    coverage,
    type,
    relationship,
    policyNumber,
    groupNumber,
    companyPhone,
    addressInfo,
    policyHolderInfo,
  }) => {
    dispatch({
      type: 'SET_INSURANCE_CARD_ENTRY',
      payload: {
        insuranceCardEntry: {
          insurancePlan,
          coverage,
          type,
          relationship,
          policyNumber,
          groupNumber,
          companyPhone,
          addressInfo,
          policyHolderInfo,
        },
      },
    })
  }
  return { insuranceCardEntry, setInsuranceCardEntry }
}

export const useLoadGoogleMap = () => {
  const { isGoogleMapLoaded } = useContext(AuthContext)
  return { isGoogleMapLoaded }
}

export const useHealthieInfo = ({ user = {} }) => {
  const { setPersonalInfo } = usePersonalInfo()
  const { setSurvey } = useSurvey()
  const { setHormonalAutoimmune } = useHormonalAutoimmune()
  const { setProfile } = useProfile()
  const { setHearAbout } = useHearAbout()
  const { setTalkAboutCoverage } = useTalkAboutCoverage()
  const { setInsuranceCardEntry } = useInsuranceCardEntry()
  const {
    helpMostOptions,
    diseaseHelpOptions,
    hearAboutOptions,
    insuranceProviders,
  } = useMetadataOptions()
  const { stateOptions } = useCareInsurancePay()
  const { userSelectOptions, getUserProfile } = useUserProfile()

  const updatePersonalInfo = useCallback(
    async () => {
      if (!_.isEmpty(user) && !_.isEmpty(stateOptions)) {
        const localStateId = getStateId()
        const _state = _.find(
          stateOptions,
          localStateId
            ? { id: +localStateId }
            : { legalEntityId: user.dietitian_id }
        )
        setPersonalInfo({
          firstName: user.legal_name ? user.legal_name : user.first_name,
          lastName: user.last_name,
          preferredFirstName: user.legal_name
            ? user.first_name
            : user.legal_name,
          phone: user.phone_number,
          email: user.email,
          state: _state,
        })
        setHealthieProviderId(_state?.legalEntityId)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user, stateOptions]
  )

  const updateSurvey = useCallback(
    () => {
      if (!_.isEmpty(helpMostOptions) && !_.isEmpty(userSelectOptions)) {
        const helpMostOptionId = userSelectOptions.helpMostOptionId
        const helpMostOptionText = userSelectOptions.helpMostOptionText
        const selectedOption = _.find(
          helpMostOptions,
          (option) => option.id === helpMostOptionId
        )
        if (selectedOption) {
          if (selectedOption.name === 'Other') {
            setSurvey({
              ...selectedOption,
              otherText: helpMostOptionText,
            })
          } else {
            setSurvey(selectedOption)
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [helpMostOptions, userSelectOptions]
  )

  const updateHormonalAutoimmune = useCallback(
    () => {
      if (!_.isEmpty(diseaseHelpOptions) && !_.isEmpty(userSelectOptions)) {
        const diseaseHelpOptionId = userSelectOptions.diseaseHelpOptionId
        const diseaseHelpOptionText = userSelectOptions.diseaseHelpOptionText
        const selectedOption = _.find(
          diseaseHelpOptions,
          (option) => option.id === diseaseHelpOptionId
        )
        if (selectedOption) {
          if (selectedOption.name === 'Other') {
            setHormonalAutoimmune({
              ...selectedOption,
              otherText: diseaseHelpOptionText,
            })
          } else {
            setHormonalAutoimmune(selectedOption)
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [diseaseHelpOptions, userSelectOptions]
  )

  const updateProfile = useCallback(
    () => {
      if (!_.isEmpty(user)) {
        setProfile({
          birthday: user.dob
            ? moment(user.dob, 'YYYY-MM-DD').format('MM/DD/YYYY')
            : '',
          gender: user.gender || 'Female',
          heightFt: user.height ? Math.floor(+user.height / 12) : '',
          heightIn: user.height ? +user.height % 12 : '',
          weightLbs: user.weight ? parseInt(user.weight) : '',
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user]
  )

  const updateHearAbout = useCallback(
    () => {
      if (!_.isEmpty(user) && hearAboutOptions) {
        const refType = user.client_source?.ref_type
        const refSource = user.client_source?.related_object?.full_name
        let hearItem = {}
        if (refType === 'ad') {
          if (refSource === 'ZocDoc') {
            hearItem = _.find(
              hearAboutOptions,
              (option) => option.name === 'ZocDoc'
            )
          } else if (refSource === 'Other') {
            hearItem = _.find(
              hearAboutOptions,
              (option) => option.name === 'Advertisement'
            )
          }
        } else if (refType === 'other') {
          hearItem = _.find(
            hearAboutOptions,
            (option) => option.name === refSource
          )
          if (_.isEmpty(hearItem)) {
            hearItem = _.find(
              hearAboutOptions,
              (option) => option.name === 'Other'
            )
            hearItem = { ...hearItem, otherText: refSource }
          }
        }
        setHearAbout(hearItem)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user, hearAboutOptions]
  )

  const updateTalkAbout = useCallback(
    () => {
      if (!_.isEmpty(insuranceProviders) && !_.isEmpty(userSelectOptions)) {
        const insuranceProviderOptionId =
          userSelectOptions.insuranceProviderOptionId
        const insuranceProviderOptionText =
          userSelectOptions.insuranceProviderOptionText
        const selectedProvider = _.find(
          insuranceProviders,
          (provider) => provider.id === insuranceProviderOptionId
        )
        if (selectedProvider) {
          if (selectedProvider.name === 'Other') {
            setTalkAboutCoverage({
              ...selectedProvider,
              otherText: insuranceProviderOptionText,
            })
          } else {
            setTalkAboutCoverage(selectedProvider)
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [insuranceProviders, userSelectOptions]
  )

  const updateInsuranceCardEntry = useCallback(
    () => {
      if (!_.isEmpty(user)) {
        const policy = !_.isEmpty(user.policies)
          ? user.policies[user.policies.length - 1]
          : {}
        const location = !_.isEmpty(user.locations)
          ? user.locations[user.locations.length - 1]
          : {}
        const coverage = !_.isEmpty(user.active_tags)
          ? user.active_tags[0].name
          : null
        const insurancePlan = !_.isEmpty(policy) ? policy.insurance_plan : {}
        const type = !_.isEmpty(policy) ? policy.priority_type : null
        const relationship = !_.isEmpty(policy)
          ? policy.holder_relationship
          : null
        const policyNumber = !_.isEmpty(policy) ? policy.num : null
        const groupNumber = !_.isEmpty(policy) ? policy.group_num : null
        const companyPhone = !_.isEmpty(policy)
          ? policy.policy_phone_number
          : null
        const addressInfo = !_.isEmpty(location)
          ? {
              line1: location.line1,
              line2: location.line2,
              city: location.city,
              state: _.find(
                stateOptions || [],
                (option) => option.code === location.state
              ),
              postcode: location.zip,
            }
          : {}
        const policyHolderInfo = !_.isEmpty(policy)
          ? {
              firstName: policy.holder_first,
              gender: policy.holder_gender,
              lastName: policy.holder_last,
              dob: moment(policy.holder_dob, 'YYYY-MM-DD').format('MM/DD/YYYY'),
            }
          : {}
        setInsuranceCardEntry({
          insurancePlan,
          coverage,
          type,
          relationship,
          policyNumber,
          groupNumber,
          companyPhone,
          addressInfo,
          policyHolderInfo,
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user, stateOptions]
  )

  useEffect(() => {
    if (!_.isEmpty(user)) {
      getUserProfile()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    updatePersonalInfo()
  }, [updatePersonalInfo])

  useEffect(() => {
    updateSurvey()
  }, [updateSurvey])

  useEffect(() => {
    updateHormonalAutoimmune()
  }, [updateHormonalAutoimmune])

  useEffect(() => {
    updateProfile()
  }, [updateProfile])

  useEffect(() => {
    updateHearAbout()
  }, [updateHearAbout])

  useEffect(() => {
    updateTalkAbout()
  }, [updateTalkAbout])

  useEffect(() => {
    updateInsuranceCardEntry()
  }, [updateInsuranceCardEntry])
}

export const useUserProfile = () => {
  const [userSelectOptions, setUserSelectOptions] = useState({})
  const getUserProfile = useCallback(async () => {
    const token = getToken()
    if (token) {
      const resp = await getUserProfilePromise()
      if (!_.isEmpty(resp.data)) {
        setUserSelectOptions(resp.data.userSelectOptions || {})
        setStateId(resp.data.localeStateId)
      }
    }
  }, [])

  return { userSelectOptions, getUserProfile }
}

export const useClearUserInfo = () => {
  const { isClearAll, clearData, dispatch } = useContext(AuthContext)
  const setIsClearAll = (value) => {
    dispatch({
      type: 'SET_CLEAR_DATA',
      payload: {
        isClearAll: value,
      },
    })
  }

  const clearAll = () => {
    clearUserInfo()
    clearData()
  }
  return { clearAll, isClearAll, setIsClearAll }
}
