import React from 'react'
import { Spin } from 'antd'
import classnames from 'classnames'
import classes from './index.module.scss'
import FOModal from 'components/FOModal'
import { LoadingOutlined } from '@ant-design/icons'
const { styles } = classes
export const antLoadingIcon = <LoadingOutlined className='loading-icon' spin />

export const LoadingContent = (props) => {
  const { className } = props
  return (
    <div className={classnames('spin', className)} {...props}>
      <Spin indicator={antLoadingIcon} />
    </div>
  )
}

const LoadingDialog = ({ visible, setVisible, center, className } = {}) => {
  return (
    <FOModal
      {...{ visible, setVisible }}
      className={classnames(styles)}
      center={center}
    >
      <LoadingContent />
    </FOModal>
  )
}

export default LoadingDialog
