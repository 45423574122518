import React, {
  useState,
  useEffect,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from 'react'
import usePlacesAutoComplete from 'use-places-autocomplete'
import { Select } from 'antd'
import { useI18n } from 'contexts/AuthContext'
import {
  getGymAddress,
  getPlaceDetailByGoogle,
  showNotification,
} from 'utils/basic'
import CareInput from 'components/CareInput/index'
import FitOnIcon from 'components/FitOnIcon/index'
import DropdownSelect from 'components/DropdownSelect/index'
import _ from 'lodash'

const { Option } = Select

const Address = (
  { address, setAddress, isSelf, stateOptions, setIsShowManualAddress },
  ref
) => {
  const { i18n } = useI18n()
  const [clicked, setClicked] = useState(false) //to prevent menu showing by default
  const [showManualAddress, setShowManualAddress] = useState(false)
  const [city, setCity] = useState('')
  const [init, setInit] = useState(false)
  const [state, setState] = useState()
  const [zip, setZip] = useState('')
  const [hideSearchResult, setHideSearchResult] = useState(false)
  const [showError, setShowError] = useState(false)

  useEffect(() => {
    if (init) return
    setInit(true)
    if (address.isManual) {
      setCity(address.city)
      setState(address.state)
      setZip(address.postcode)
      setValue(address.line1)
    } else {
      setValue(address.line1)
      setCity(address.city)
      setState(address.state)
      setZip(address.postcode)
    }

    setShowManualAddress(!_.isEmpty(address.line1) || address.isManual)
  }, [address, init])
  const {
    ready,
    value: locationValue,
    suggestions: { data },
    setValue,
    // init,
  } = usePlacesAutoComplete({
    requestOptions: {
      location: { lat: () => 33.486513, lng: () => -111.917507 }, // bias to user location. else
      radius: 60 * 1000, // 60km
      // types: ['(regions)'],
      componentRestrictions: { country: ['us', 'ca'] },
      language: 'en',
    },
    debounce: 300,
    // initOnMount: false,
  })

  const locationResult = useMemo(() => {
    console.log({ data })
    return [...(data ?? [])]
  }, [data])

  const handleSelect = (location) => {
    setShowManualAddress(false)
    setHideSearchResult(true)
    getPlaceDetailByGoogle(location.place_id, async (detail) => {
      const gym = detail
      console.log({ gym })
      let {
        studioAddress,
        city,
        country,
        state,
        postalCode = '',
        formattedAddress,
      } = getGymAddress(gym)
      const line1 = `${studioAddress}`
      if (!postalCode) {
        postalCode = gym?.address_components?.find((c) =>
          c?.types?.includes('postal_code')
        )?.long_name
      }
      if (city && country && line1 && postalCode) {
        const currentOption = stateOptions.find(
          (option) => option.code === state || option.name === state
        )
        setAddress({
          city,
          country,
          line1,
          postcode: postalCode,
          state: currentOption,
          isManual: false,
          formattedAddress,
        })
        setValue(line1, false)
        setCity(city)
        setState(currentOption)
        setZip(postalCode)
      } else {
        showNotification({
          type: 'error',
          title: i18n('Invalid Address'),
        })
        setHideSearchResult(false)
      }
    })

    // clearSuggestions()
  }

  useEffect(() => {
    setAddress({
      city,
      country: 'US',
      line1: locationValue,
      postcode: zip,
      state,
      isManual: true,
    })
  }, [city, state, zip, locationValue, showManualAddress, setAddress])

  useImperativeHandle(ref, () => ({
    showManualAddress: () => {
      setHideSearchResult(true)
      setShowManualAddress(true)
    },
    showErrorInput: () => {
      setShowError(true)
    },
  }))

  useEffect(() => {
    if (showManualAddress || hideSearchResult) {
      setIsShowManualAddress(true)
    }
  }, [showManualAddress, hideSearchResult, setIsShowManualAddress])

  return (
    <>
      <div className='line'>
        <div className='location-input' onClick={() => setClicked(true)}>
          <CareInput
            placeholder={i18n(
              isSelf ? 'Home Address' : 'Policy Holder Home Address'
            )}
            inputValue={locationValue}
            setInputValue={setValue}
            className='m-t-12'
            disabled={!ready}
            type='text'
            isValid={showError ? !_.isEmpty(locationValue) : true}
            errorMessage='This field is required.'
          />

          {locationResult?.length > 1 &&
            !!locationValue &&
            clicked &&
            !hideSearchResult && (
              <div className='location-popover'>
                {locationResult?.filter(Boolean).map((location) => (
                  <div
                    className='location-item'
                    key={location?.place_id}
                    onClick={() => handleSelect(location)}
                  >
                    <FitOnIcon type='location-line' />
                    <div>{`${location?.structured_formatting?.main_text},${location?.structured_formatting?.secondary_text}`}</div>
                  </div>
                ))}
                <div
                  className='location-item '
                  onClick={() => {
                    setHideSearchResult(true)
                    setShowManualAddress(true)
                    // clearSuggestions()
                  }}
                >
                  <div>{`Can’t find your address?`}</div>
                </div>
              </div>
            )}
        </div>
      </div>
      {(showManualAddress || hideSearchResult) && (
        <div className='line'>
          <CareInput
            placeholder={i18n('City')}
            inputValue={city}
            setInputValue={setCity}
            className='m-t-12'
            isValid={showError ? !_.isEmpty(city) : true}
            errorMessage='This field is required.'
          />
          <div className='two-grid-sec m-t-12'>
            <DropdownSelect
              {...{
                placeholder: i18n('State'),
                options: stateOptions,
                setValue: (value) => {
                  const currentOption = stateOptions.find(
                    (option) => option.id === value
                  )
                  setState(currentOption)
                },
                value: !_.isEmpty(state) ? state.id : null,
                render: (
                  <>
                    {stateOptions.map((option, index) => (
                      <Option
                        className='state-item'
                        value={option.id}
                        key={index}
                      >
                        {option.name}
                      </Option>
                    ))}
                  </>
                ),
                isValid: showError ? !_.isEmpty(state) : true,
                errorMessage: 'This field is required.',
              }}
            />
            <CareInput
              placeholder={i18n('ZIP')}
              inputValue={zip}
              setInputValue={setZip}
              type='text'
              pattern='\d*'
              isValid={showError ? !_.isEmpty(zip) : true}
              errorMessage='This field is required.'
            />
          </div>
        </div>
      )}
    </>
  )
}

export default forwardRef(Address)
